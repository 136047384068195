<template>
  <div class="page-content">
    <ActiveTestViewCandidatesTour v-if="this.componentTour" :page="componentName" @tour-close="CloseComponentTour" />
    <div class="Searching_input_box_mobile_view mb-3 position-relative" v-if="this.Remainingdays >= 0">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="input" @keyup.enter="filteredList()" :disabled="searchDisable"
          placeholder="Search..." />
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
      </div>
    </div>
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown" v-if="this.testName !== '' || this.testName !== null">
              <router-link :to="{
                name: 'ActiveTests',
                query: {
                  id: this.routesearch ? encode(this.routesearch) : '',
                  offset: this.routeoffset ? encode(this.routeoffset) : '',
                  current: this.routecurrent ? encode(this.routecurrent) : '',
                  filter: true,
                  // filterList: this.routeFilterList ? encode(routeFilterList) : ''
                },
              }" class="p-0" type="button">
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="tour-test-name px-2 pt-2">
          <div>
            <h5 class="mb-1 font-size-MV" v-if="this.screenWidth <= 1441 &&
              this.screenWidth > 767 &&
              (this.testName !== '' || this.testName !== null)
            ">
              {{
                testName.substring(
                  0,
                  testName.lastIndexOf(testName.substring(35))
                ) + "..."
              }}
            </h5>
            <h5 class="mb-1 font-size-MV"
              v-else-if="this.screenWidth <= 767 && (this.testName !== '' || this.testName !== null)">
              {{
                testName.substring(
                  0,
                  testName.lastIndexOf(testName.substring(10))
                ) + "..."
              }}
            </h5>
            <h4 class="mb-1 font-size-MV" v-else>
              {{ testName }}
            </h4>
          </div>
          <div>
            <!-- class="test-cand-count" -->
            <h6 class="text-muted" v-if="this.candidateList.length != 0">
              Candidates ({{ filteredCandidates }} out of {{ totalCandidates }} candidates)
            </h6>
            <h6 class="text-muted" v-else>
              Candidates ({{ this.candidateList.length }})
            </h6>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center" v-if="this.Remainingdays >= 0">
        <!-- Search -->
        <div class="top_heading_CreatePartner_btn position-relative z-index-999 me-2" id="tour-search-web-icon"
          :class="this.componentTour == true ? 'disable-tour-selection' : ''">
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input type="text" v-model="input" :disabled="searchDisable" @keydown.enter="filteredList()"
                placeholder="Search..." />
              <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
              <img src="../../public/dashboard-assets/images/M2/Close_icon.png" alt="" class="close-icon"
                @click="closesearch" />
            </div>
          </div>
        </div>

        <div class="
              top_heading_CreatePartner_btn
              position-relative
              Searching_button_mobileView mx-2" id="tour-search-responsive-icon">
          <div class="Group_top_three_dot">
            <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn d-block me-2" v-if="superadmin()">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <router-link :to="{
                  name: 'InviteLogs',
                  query: {
                    testId: this.routetestId ? encode(this.routetestId) : '',
                    clientId: this.routeclientId ? encode(this.routeclientId) : '',
                    isParent: this.routeisParent ? encode(this.routeisParent) : '',
                    search: this.routesearch ? encode(this.routesearch) : '',
                    offset: this.routeoffset ? encode(this.routeoffset) : '',
                    current: this.routecurrent ? encode(this.routecurrent) : '',
                    name: this.testName ? encode(this.testName) : '',
                  },
                }" class="p-0" type="button">
                  <img src="../../public/dashboard-assets/images/M2/invite-logs.png" style="width:25px" />
                </router-link>
              </button>
            </div>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn d-block" id="tour-calender-web-icon"
          :class="this.componentTour == true ? 'disable-tour-selection' : ''">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <img src="../../public/dashboard-assets/images/M2/date-icon.png" @click="opencal"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Filter by Date" />
                <span class="cal-alert translate-middle" v-if="this.fromDate != 0">
                  <span class="visually-hidden"></span>
                </span>
                <input type="text" name="daterange" hidden="true" id="calendar_icon" />
                <input type="text" name="consolidated_sheet_daterange" hidden="true"
                  id="consolidated_sheet_calendar_icon" />
              </button>
            </div>
          </div>
        </div>
        <!-- Filter -->
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2"
          id="tour-filter-web-icon" :class="this.componentTour == true ? 'disable-tour-selection' : ''">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterDropdown"
                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Filter" />
                <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="filterDropdown">
                <div class="modal-header">
                  <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                    Filter
                  </h4>
                  <button type="button" class="btn-close" data-bs-toggle="dropdown" data-bs-auto-close="inside"
                    @click="filterclose"></button>
                </div>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li v-if="this.candidateResult === 'Completed'" class="nav-item w-50 text-center" role="presentation">
                    <button class="nav-link m-auto active" id="pills-home-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                      aria-selected="true" @click="
                        this.filterTab = 1;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Status
                    </button>
                  </li>
                  <li
                    :class="this.candidateResult === 'Completed' ? 'nav-item w-50 text-center' : 'nav-item w-100 text-center'"
                    role="presentation">
                    <button :class="this.candidateResult === 'Completed' ? 'nav-link m-auto' : 'nav-link m-auto active'"
                      id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button"
                      role="tab" aria-controls="pills-profile" aria-selected="false" @click="
                        this.filterTab = 2;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Region
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active p-0" id="pills-home" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" placeholder="Search" name="" class="form-control bg-white"
                          v-model="searchfilter" @keyup="Filterresult" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul v-if="this.candidateResult === 'Completed' && filterTab == 1">
                          <li v-for="item in completedStatusList" :key="item">
                            <input type="checkbox" :value="item.key" @change="addFilter(item.key, $event)"
                              :checked="this.filters.status.includes(item.key)" />
                            <span>{{ item.value }} </span>
                          </li>
                        </ul>
                        <ul v-else>
                          <li v-for="item in groups" :key="item">
                            <input type="checkbox" :value="item.regionId" :checked="this.filters.region.includes(item.regionId)
                              " @change="addFilter(item.regionId, $event)" />
                            <span>{{ item.regionName }} </span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                            @click="clearFilters(true)">
                            Clear Filter
                          </button>

                          <button type="button" class="modail_submit_button text-end" :class="{
                            active_form_button: this.applyfilter > 0,
                          }" :disabled="applyfilter == 0" @click="applyFilters()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!--update-->
        <!--  getUserPermissionData({ parent: 'Candidate Management', child: 'Candidates', permission: 'Can_Access_Consolidated_Sheet', }) -->
        <div
          v-if="candidateResult === 'Completed' && getUserPermissionData({ parent: 'Candidate Management', child: 'Candidates', permission: 'Can_Access_Consolidated_Sheet', })">
          <div class="top_heading_CreatePartner_btn d-block tour-web-consolidated-button" data-bs-toggle="modal"
            data-bs-target="#ConsolidatedSheet" @click="groupLists"
            :class="this.componentTour == true ? 'disable-tour-selection' : ''">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" aria-haspopup="true"
                  aria-expanded="false" style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Download Consolidated Sheet" />
                </button>
              </div>
            </div>
          </div>
          <!-- <div v-else class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal"
            data-bs-target="#MedusindModal">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--update-->
    <!-- <div class="modal fade CreatePartnerModal_hp" id="MedusindModal" data-bs-toggle="modal" tabindex="-1"
      data-bs-backdrop="modal" aria-labelledby="CreateGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-header" style="background-color: #ffff; border: none;">
              <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('MedusindModal')"></button>
            </div>
            <div class="row">
              <div class="col-6">
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
                  Consolidated Sheet
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#MedusindBulkPopUp">
                  Medusind Bulk Data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-9" v-if="this.candidateIds.length === 0">
        <ul class="
                nav nav-pills
                mb-3
                partners_top_menu_section_hp
                p-2
                mt-4
                user_tabing_nav_section
                tour-status-tabs
              " id="pills-tab" role="tablist" v-if="this.Remainingdays >= 0"
          :class="this.componentTour == true ? 'disable-tour-selection' : ''">
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3" :class="candidateResult === 'Completed' ? 'active' : ''"
              id="completed-candidates-tab" data-bs-toggle="pill" data-bs-target="#completed-candidates" type="button"
              role="tab" aria-controls="completed-candidates" aria-selected="true" @click="changeTab('Completed')">
              Completed ({{ this.completedCandidates }})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3" :class="candidateResult === 'Pending' ? 'active' : ''"
              id="pending-candidates-tab" data-bs-toggle="pill" data-bs-target="#pending-candidates" type="button"
              role="tab" aria-controls="pending-candidates" aria-selected="false" @click="changeTab('Pending')">
              Pending ({{ this.pendingCandidates }})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3" :class="candidateResult === 'InProgress' ? 'active' : ''"
              id="in-progress-candidates-tab" data-bs-toggle="pill" data-bs-target="#in-progress-candidates"
              type="button" role="tab" aria-controls="in-progress-candidates" aria-selected="false"
              @click="changeTab('InProgress')">
              In Progress ({{ this.inProgressCandidates }})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link btn px-3" :class="candidateResult === 'Review' ? 'active' : ''"
              id="review-candidates-tab" data-bs-toggle="pill" data-bs-target="#review-candidates" type="button"
              role="tab" aria-controls="review-candidates" aria-selected="false" @click="changeTab('Review')">
              Review ({{ this.reviewCandidates }})
            </button>
          </li>
        </ul>
      </div>
      <!-- <div v-if="candidateResult === 'Pending'" class="col-3 mt-4 mb-2">
        <button class="btn btn-warning" data-bs-toggle="modal"
          data-bs-target="#View-failed-invites" @click="fetchCandidateDump(routetestId)">
            Failed invites(test level)
        </button>
      </div> -->
      <div v-if="this.candidateResult !== 'Review' && this.candidateIds.length > 0">
        <div class="row mt-4 mb-3">
          <div class="col-12">
            <button type="button" class="btn btn-primary" @click="doBulkAction('Bulk resend')">Bulk Resend
              Invite</button>
            <button type="button" class="btn btn-danger mx-3" v-if="candidateResult === 'Pending'"
              @click="doBulkAction('Bulk delete')">Bulk Delete</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" v-if="this.showcandidateList == true && candidateList.length > 0"> -->
    <div class="row " :class="{ 'disable-text-selection': Remainingdays < 0 }"
      :style="{ filter: Remainingdays < 0 ? 'blur(8px)' : '' }"
      v-if="this.showcandidateList == true && candidateList.length > 0" id="showData">

      <div class="col-md-12">
        <div class="card p-0">
          <div class="table-responsive">
            <table class="table ActiveTestTable" :class="this.componentTour == true ? 'disable-tour-selection' : ''">
              <thead>
                <tr>
                  <th v-if="candidateResult !== 'Review'">
                    <input type="checkbox" class="cursor-pointer ms-2" v-model="isAllSelected"
                      @change="selectCandidate('generic')">
                  </th>
                  <th>
                    <span v-if="this.filterStatus == true" @click="SortBy('FirstName', 'desc')"> <i
                        style="cursor: pointer; color: #828282;" class="fa-solid fa-arrow-down-wide-short fa-sm"></i>
                    </span>
                    <span v-else @click="SortBy('FirstName', 'asc')"><i style="cursor: pointer; color: #828282;"
                        class="fa-solid fa-arrow-up-wide-short fa-sm"></i></span>
                    Name
                  </th>
                  <th>
                    <span v-if="this.filterStatus == true" @click="SortBy('CandidateUniqueId', 'desc')"> <i
                        style="cursor: pointer; color: #828282;" class="fa-solid fa-arrow-down-wide-short fa-sm"></i>
                    </span>
                    <span v-else @click="SortBy('CandidateUniqueId', 'asc')"><i style="cursor: pointer; color: #828282;"
                        class="fa-solid fa-arrow-up-wide-short fa-sm"></i></span>
                    Candidate Id
                  </th>
                  <th>
                    <span v-if="this.filterStatus == true" @click="SortBy('EmailAddress', 'desc')"> <i
                        style="cursor: pointer; color: #828282;" class="fa-solid fa-arrow-down-wide-short fa-sm"></i>
                    </span>
                    <span v-else @click="SortBy('EmailAddress', 'asc')"><i style="cursor: pointer; color: #828282;"
                        class="fa-solid fa-arrow-up-wide-short fa-sm"></i></span>
                    Email/Mobile Number
                  </th>
                  <th>
                    <span v-if="this.filterStatus == true" @click="SortBy('ExamEndTime', 'desc')"> <i
                        style="cursor: pointer; color: #828282;" class="fa-solid fa-arrow-down-wide-short fa-sm"></i>
                    </span>
                    <span v-else @click="SortBy('ExamEndTime', 'asc')"><i style="cursor: pointer; color: #828282;"
                        class="fa-solid fa-arrow-up-wide-short fa-sm"></i></span>
                    {{ candidateResult === 'Completed' || candidateResult === 'Review' ? 'Test End Time' :
                      candidateResult
                        === 'Pending' ? 'Created On' : 'Test Start Time' }}
                  </th>
                  <th>
                    <span v-if="this.filterStatus == true" @click="SortBy('CandidateStatus', 'desc')"> <i
                        style="cursor: pointer; color: #828282;" class="fa-solid fa-arrow-down-wide-short fa-sm"></i>
                    </span>
                    <span v-else @click="SortBy('CandidateStatus', 'asc')"><i style="cursor: pointer; color: #828282;"
                        class="fa-solid fa-arrow-up-wide-short fa-sm"></i></span>
                    Status
                  </th>
                  <th v-if="this.candidateIds.length === 0 && this.candidateResult !== 'Review'">Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- 1 -->
                <tr v-for="item in candidateList" :key="item" class="tour-candidate-details-table">
                  <td v-if="candidateResult !== 'Review'">
                    <input type="checkbox" class="cursor-pointer ms-2" v-model="candidateIds" :id="item.candidateId"
                      :value="item.candidateId" @change="selectCandidate('individual')">
                  </td>
                  <td>
                    <div class="d-flex align-items-center font-weight">
                      <div class="PartnerTeamMembersProfile-area-kj">
                        <div class="PartnerTeamMembersProfile-kj name-logo-1 position-relative">
                          {{ item.firstName?.charAt(0) }}
                          <span
                            v-if="item.attemptcount > 1 && (TestStatus[item.candidateStatus] == 'Pass' || TestStatus[item.candidateStatus] == 'Fail')"
                            class="translate-middle notialert-kj-view">
                          </span>
                        </div>
                      </div>
                      <span>
                        <span class="me-4">{{
                          (typeof item.firstName === "object"
                            ? ""
                            : item.firstName) +
                          " " +
                          (typeof item.lastName === "object"
                            ? ""
                            : item.lastName)
                        }}
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    {{ item.candidateUniqueId }}
                  </td>
                  <td>
                    <div v-if="item.mobileNo === null">
                      {{ item.emailAddress }}
                    </div>
                    <div v-else-if="item.emailAddress === null">
                      {{ item.mobileNo }}
                    </div>
                    <div v-else>
                      {{ item.emailAddress }} / {{ item.mobileNo }}
                    </div>
                  </td>
                  <td>
                    <div v-if="candidateResult === 'Completed' || candidateResult === 'Review'">
                      {{ dateConvert(item?.examEndTime) }}&nbsp;{{
                        timeConverter(item?.examEndTime)
                      }}
                    </div>
                    <div v-else-if="candidateResult === 'Pending'">
                      {{ dateConvert(item?.createdOn) }}&nbsp;{{
                        timeConverter(item?.createdOn)
                      }}
                    </div>
                    <div v-else-if="candidateResult === 'InProgress'">
                      {{ dateConvert(item?.examStartTime) }}&nbsp;{{
                        timeConverter(item?.examStartTime)
                      }}
                    </div>
                  </td>
                  <td
                    v-if="item.linkExpired && (TestStatus[item.candidateStatus] == 'Pending' || TestStatus[item.candidateStatus] == 'InProgress')">
                    <span class="View-Candidates-xmark">
                      <i class="fa-solid fa-exclamation me-2" style="color:red;"></i>
                      Link Expired
                    </span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Pending'">
                    <span class="View-Candidates-clock"><i class="fa-solid fa-clock-rotate-left me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}
                    </span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'InProgress'">
                    <span class="View-Candidates-progress"><i class="fa-solid fa-stopwatch me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Review'">
                    <span class="View-Candidates-review"><i class="fa-regular fa-clock me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Pass'">
                    <span class="View-Candidates-pass"><i class="fa-solid fa-check me-2"></i>{{
                      TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else-if="TestStatus[item.candidateStatus] == 'Fail'">
                    <span class="View-Candidates-xmark"><i class="fa-solid fa-xmark me-2"></i>
                      {{ TestStatus[item.candidateStatus] }}</span>
                  </td>
                  <td v-else>NA</td>
                  <td v-if="this.candidateIds.length === 0">
                    <div>
                      <div class="Group_top_three_dot"
                        v-if="this.Remainingdays >= 0 && TestStatus[item.candidateStatus] != 'Review'">
                        <div class="dropdown">
                          <button class="btn p-0 Group_top_three_dot_dropdown tour-candidate-details-dots" type="button"
                            id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="fa-solid fa-ellipsis"></i>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li v-if="(TestStatus[item.candidateStatus] == 'Fail' ||
                              TestStatus[item.candidateStatus] == 'Pass') &&
                              ('attemptcount' in item === false || item.attemptcount > 0) &&
                              getUserPermissionData({
                                parent: 'Candidate Management',
                                child: 'Candidates',
                                permission: 'Can_View_Candidate_Report',
                              })
                            ">
                              <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                data-bs-target="#View-report" @click="selectedCandidate(item)">
                                View report
                              </button>
                            </li>
                            <!-- <li v-if="getUserPermissionData({
                              parent: 'Candidate Management',
                              child: 'Candidates',
                              permission: 'Can_Reinvite_Candidate',
                            }) && (TestStatus[item.candidateStatus] == 'InProgress' || TestStatus[item.candidateStatus] == 'Pending'
                              || TestStatus[item.candidateStatus] == 'Pass' || TestStatus[item.candidateStatus] == 'Fail') && !item.linkExpired
                              ">
                              <button type="button" class="btn w-100 text-start"
                                @click="ResendInvite(item.candidateId, item.emailAddress, item.mobileNo)">
                                Resend invite
                              </button>
                            </li>  -->

                            <!-- <li v-if="getUserPermissionData({
                              parent: 'Candidate Management',
                              child: 'Candidates',
                              permission: 'Can_Reinvite_Candidate',
                            }) && (((TestStatus[item.candidateStatus] == 'InProgress' || TestStatus[item.candidateStatus] == 'Pending') && !item.linkExpired) || (TestStatus[item.candidateStatus] == 'Pass' || TestStatus[item.candidateStatus] == 'Fail'))
                            ">
                              <button type="button" class="btn" @click="RabbitMQInvite(item.candidateId)">
                                Resend invite <span style="color: red;">[New]</span>
                              </button>

                            </li> -->
                            <li v-if="getUserPermissionData({
                              parent: 'Candidate Management',
                              child: 'Candidates',
                              permission: 'Can_Reinvite_Candidate',
                            }) && (((TestStatus[item.candidateStatus] == 'InProgress' || TestStatus[item.candidateStatus] == 'Pending') && !item.linkExpired) || (TestStatus[item.candidateStatus] == 'Pass' || TestStatus[item.candidateStatus] == 'Fail'))
                            ">
                              <!-- update-->
                              <button type="button" class="btn w-100 text-start"
                                @click="ResendInviteV2(item.candidateId, item.emailAddress, item.mobileNo)">
                                Resend invite
                              </button>

                            </li>
                            <li
                              v-if="item.linkExpired && (TestStatus[item.candidateStatus] == 'Pending' || TestStatus[item.candidateStatus] == 'InProgress')">
                              <button type="button" class="btn w-100 text-start"
                                @click="showExtendLinkModal(item.candidateId)">
                                Extend expiry
                              </button>
                            </li>
                            <li v-if="TestStatus[item.candidateStatus] == 'Pending' &&
                              getUserPermissionData({
                                parent: 'Candidate Management',
                                child: 'Candidates',
                                permission: 'Can_Delete_Candidate',
                              })
                            ">
                              <button type="button" class="btn w-100 text-start"
                                @click="removeCandidate(item.candidateId)">
                                Remove Candidate
                              </button>
                            </li>
                            <li>
                              <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                data-bs-target="#View-invite-logs" @click="fetchInviteLogs(item)">
                                Invite logs
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Bulk action modal -->
      <div class="modal fade" id="BulkCandidateActions" tabindex="-1" aria-labelledby="BulkCandidateActions"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm" data-bs-backdrop="static"
          data-bs-keyboard="false">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="BulkActionModal">
                {{ bulkActionType }}
              </h5>
              <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <h5 class="my-4 request-color-token">
                Are you sure you want to {{ bulkActionType.toLowerCase() }} for {{ candidateIds.length }} candidates ?
              </h5>
              <form class="row">
                <div class="text-end mt-2">
                  <button type="button" class="mx-2" @click=RabbitMQBulkInvite()> Yes <span
                      style="color:red">[New]</span></button>

                  <button type="button" class="btn btn-primary mx-3"
                    @click="[bulkActionType === 'Bulk delete' ? deleteBulkCandidates() : resendBulkInvites()]">
                    Yes
                  </button>

                  <button type="button" class="btn btn-danger" @click="closeBulkActionModal()">
                    No
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Archive  -->
      <div class="modal fade" id="View-report" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Report</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="reportclose"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1">
                        {{ this.firstName?.charAt(0) }}
                      </div>
                    </div>
                    <div v-if="this.uniqueCandidateId">
                      {{
                        this.firstName +
                        " " +
                        this.lastName +
                        " - " +
                        this.uniqueCandidateId
                      }}
                    </div>
                    <div v-else>
                      {{ this.firstName + " " + this.lastName }}
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="form-group mb-4">
                    <label class="input-hero" for="PartnerCompany">
                      <input class="input_area" type="text" required="" aria-autocomplete="list"
                        v-model="this.dateData" />
                      <span class="input-name">Date & Time <span class="text-danger">*</span></span>
                      <span class="toggle-password" @click="toggle = !toggle">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <div v-if="this.selectedCandidateId != 0">
                    <div v-if="this.reportType == 1">
                      <div class="d-flex justify-content-between" v-if="superadmin()">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            Answer Sheet
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()"
                            @click="downlodedViewReports('AnswerSheet', this.routetestId, this.tokenId)">
                            View
                          </button>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between" v-for="item in report" :key="item">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            {{ item }}
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()"
                            @click="downlodedViewReports(item, this.routetestId, this.tokenId)">
                            View
                          </button>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between" v-for="item in behavioural" :key="item">
                        <div class="mb-2">
                          <label class="form-check-label" for="flexCheckChecked">
                            {{ item.name }}
                          </label>
                        </div>
                        <div class="text-end">
                          <button type="button" class="btn btn-primary btn-download btn-active-TestView" :class="{
                            active_inc_dec_button: !viewbuttondisabled(),
                          }" :disabled="!viewbuttondisabled()"
                            @click="downlodedViewReports('Psychometry', this.routetestId, this.tokenId, item.psySecId)">
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" v-if="this.reportType == 2">
                      <div class="mb-2">
                        <label class="form-check-label" for="flexCheckChecked">
                          Overall Report
                        </label>
                      </div>
                      <div class="text-end">
                        <router-link type="button" target="_blank"
                          class="btn btn-primary btn-download btn-active-TestView" :to="{
                            name: 'ActiveTestReportOverview', params: {

                              candidateId: encode(selectedCandidateId),
                              testId: encode(this.routetestId),
                            }, query: {
                              //name: encode(this.firstName + ' ' + this.lastName),
                              clientId: encode(this.routeclientId),
                              isParent: encode(this.routeisParent),
                              offset: this.routeoffset ? encode(this.routeoffset) : '',
                              id: this.routesearch ? encode(this.routesearch) : '',
                              current: this.routecurrent ? encode(this.routecurrent) : '',
                            }
                          }">View</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="extendLinkModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Extend Test Duration</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="reportclose"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    For how many days do you want to extend the duration of the Test?
                  </div>
                </div>
                <form class="row my-3">
                  <div class="tokenPrice token-model-kj d-flex">
                    <input type="number" class="w-100 input-number-token-kj" placeholder="0" id="number" min="1"
                      max="365" oninput="validity.valid||(value='');" v-model="this.extendByDays" />
                  </div>
                  <h5 class="my-3 request-color-token">
                    New Expiry Date: {{ this.computeExpiryDate(this.extendByDays) }}
                  </h5>
                  <div class="text-end mt-2">
                    <button type="button" class="btn btn-token-color-kj" :disabled="!requestbuttondisable()"
                      :class="{ actived_form_button: requestbuttondisable() }" @click="extendTestDuration()">
                      Extend
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="ConsolidatedSheet" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Consolidated Sheet (only for completed candidates)</span>
                <!-- <button type="button" @click="NewConsolidatedSheet()" v-if="superadmin()">NEW API Testing</button> -->
              </h5>
              <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('ConsolidatedSheet')"></button>
            </div>
            <div class="modal-body p-4">
              <!-- <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.grp" :options="this.groupList" mode="tags" :searchable="true" :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj"> From Group
                    <span class="text-danger"></span></span>
                </label>
              </div>
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.users" :options="this.fromuser" mode="tags" :searchable="true" :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj"> Invited By
                    <span class="text-danger"></span></span>
                </label>
              </div>
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <Multiselect v-model="this.statustest" :options="this.TestStatus" mode="tags" :searchable="true"
                    :classes="{
                      tag: 'multiselect-tag text-wrap',
                    }" :close-on-select="false">
                  </Multiselect>
                  <span class="input-name input-group-kj">Choose Status
                    <span class="text-danger"></span></span>
                </label>
              </div> -->
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <input type="text" class="input_area select_ca inputValue" name="consolidated_sheet_daterange"
                    v-model="this.consolidated_sheet_date" @click="opendatePicker" data-bs-dismiss="modal" />
                  <span class="input-name">Choose Date <span class="text-danger">*</span></span>
                  <span class="toggle-password">
                    <img src="../../public/dashboard-assets/images/M2/date-icon.png" class="date-icon-kj" />
                  </span>
                </label>

                <!-- <div class="form-group mb-4">
                    <label class="input-hero" for="PartnerCompany" @click="clickcalendarData">
                      <input class="input_area" type="text" required="" aria-autocomplete="list"
                        v-model="this.dateData" />
                      <span class="input-name">Date & Time <span class="text-danger">*</span></span>
                      <span class="toggle-password" @click="toggle = !toggle">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                    </label>
                  </div> -->
              </div>

              <div class="text-end mt-3">
                <button type="button" class="btn btn-primary btn-download"
                  :class="{ active_inc_dec_button: !DownloadDisabledButton() }" :disabled="!DownloadDisabledButton()"
                  @click="ConsolidatedSheet()">
                  Download
                </button>
                <div v-if="superadmin()">
                  <button type="button" class="btn btn-primary btn-download"
                    :class="{ active_inc_dec_button: !DownloadDisabledButton() }" :disabled="!DownloadDisabledButton()"
                    @click="NewConsolidatedSheet()">
                    New Format
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal fade" id="DemoModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                id="choosedatetimebtn"></button>
            </div>
            <div class="modal-body">
              <span class="center-elements">
                <h3 style="font-weight: 600;">Exciting News! <i class="fa-solid fa-rocket fa-beat"
                    style="color: #ffc801;"></i></h3>
              </span>
              <p class="center-elements" style="margin-top: 1%;">Revamped Candidate Bulk Download: Explore the Enhanced
                Workflow in Our Latest Video! </p>

              <span class="center-elements" style="margin-top: 3%;">
                <img src="../../public/assets/Images/platform-demo.gif" alt="" style="width: 100%;" />
              </span>
            </div>
          </div>
        </div>
      </div> -->
      <!--update-->
      <!-- to be optimized(Medusind bulk data) -->
      <div class="modal fade" id="MedusindBulkPopUp" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Medusind Bulk Data</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                @click="resetForm('MedusindData')"></button>
            </div>
            <div class="modal-body p-4">
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <input type="text" class="input_area select_ca" name="daterange"
                    v-model="this.consolidated_sheet_date" />
                  <span class="input-name">Choose Date <span class="text-danger">*</span></span>
                  <img src="../../public/dashboard-assets/images/M2/date-icon.png" class="date-icon-kj"
                    @click="opendatePicker" />
                </label>
              </div>

              <div class="text-end mt-3">
                <button type="button" class="btn btn-primary btn-download"
                  :class="{ active_inc_dec_button: !DownloadDisabledButton() }" :disabled="!DownloadDisabledButton()"
                  @click="MedusindBulkCandidatesDownload()">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="View-invite-logs" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj"> Invite Logs</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="reportclose"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1">
                        {{ this.firstName?.charAt(0) }}
                      </div>
                    </div>
                    <div v-if="this.uniqueCandidateId">
                      {{
                        this.firstName +
                        " " +
                        this.lastName +
                        " - " +
                        this.uniqueCandidateId
                      }}
                    </div>
                    <div v-else>
                      {{ this.firstName + " " + this.lastName }}
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div v-if="inviteLogsList.length === 0" class="mt-2">
                    <h4 style="text-align: center; color: red;">No records found</h4>
                  </div>
                  <div v-else>
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Sent By</th>
                          <th scope="col">Invite Type</th>
                          <th scope="col">Sent To</th>
                          <th scope="col">Sent Date</th>
                          <th scope="col">Delivery Status</th>
                          <th scope="col">Delivery Response</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="logDetails in inviteLogsList" :key="logDetails">
                          <td>{{ logDetails.typeOfInvite === 'Public Invite' ? '-' : logDetails.userName }}</td>
                          <td>{{ logDetails.typeOfInvite }}</td>
                          <td>{{ logDetails.emailAddress === null ? logDetails.mobileNo : logDetails.emailAddress }}
                          </td>
                          <td>{{ logDetails.sentDate === null ? '-' : displayLogDate(logDetails.sentDate) }}</td>
                          <td>
                            <div v-if="logDetails.typeOfInvite !== 'Public Invite'">
                              <p v-if="logDetails.deliveryStatus === null || logDetails.deliveryStatus === ''">
                                <span class="View-Candidates-progress"><i
                                    class="fa-solid fa-clock-rotate-left me-2"></i>
                                  {{ 'Pending' }}
                                </span>
                              </p>
                              <p v-else>
                                {{ logDetails.deliveryStatus }}
                              </p>
                            </div>
                            <div v-else>
                              {{ '-' }}
                            </div>
                          </td>
                          <td>
                            <div v-if="logDetails.typeOfInvite !== 'Public Invite'">
                              <p v-if="logDetails.deliveryResponse === null || logDetails.deliveryResponse === ''">
                                <span class="View-Candidates-progress"><i
                                    class="fa-solid fa-clock-rotate-left me-2"></i>
                                  {{ 'Pending' }}
                                </span>
                              </p>
                              <p v-else>
                                {{ logDetails.emailAddress === null ? displayLogDate(logDetails.deliveryResponse) :
                                  logDetails.deliveryResponse }}
                              </p>
                            </div>
                            <div v-else>
                              <!-- v-if, v-else implemented because there are multiple conditions occurring in the if block above -->
                              {{ '-' }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="View-failed-invites" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                <span class="font-size-20-kj">Failed invite Logs</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" id="reportclose"
                @click="this.clearbtn()"></button>
            </div>
            <div class="modal-body p-4">
              <div>
                <div class="mt-4">
                  <div v-if="inviteLogsList.length === 0" class="mt-2">
                    <h4 style="text-align: center; color: red;">No records found</h4>
                  </div>
                  <div v-else>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Log Id</th>
                          <th scope="col">Client Id</th>
                          <th scope="col">Test Id</th>
                          <th scope="col">Type Of Event</th>
                          <th scope="col">Sent By</th>
                          <th scope="col">Sent To</th>
                          <th scope="col">Communication Type</th>
                          <th scope="col">Invite Initiated Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Timezone</th>
                          <th scope="col">Sent Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="logDetails in inviteLogsList" :key="logDetails">
                          <td>{{ logDetails.candidateUniqueId }}</td>
                          <td>{{ logDetails.userName }}</td>
                          <td>{{ logDetails.emailAddress === null ? logDetails.mobileNo : logDetails.emailAddress }}
                          </td>
                          <td>{{ logDetails.sentDate }}</td>
                          <td>{{ logDetails.deliveryStatus }}</td>
                          <td>{{ logDetails.deliveryDatetime }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="modal fade" id="ExcludedCandidateActions" tabindex="-1" aria-labelledby="ExcludedCandidateActions"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm" data-bs-backdrop="static"
          data-bs-keyboard="false">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="ExcludedActionsModal">
                {{ excludedActionType }}
              </h5>
              <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                aria-label="btn-close"></button>
            </div>
            <div class="modal-body">
              <h5 class="my-4 request-color-token">
                {{ excludedActionMessage }}
                Do you still want to proceed?
              </h5>
              <form class="row">
                <div class="text-end mt-2">
                  <!-- not to be removed -->
                  <!-- <button type="button" class="btn btn-primary mx-3"
                    @click="[excludedActionType === 'Cooling period active' ? ForceReinvite() : resendBulkInvites()]">
                    Yes
                  </button> -->
                  <button type="button" class="btn btn-primary mx-3" @click="ForceReinvite()">
                    Yes
                  </button>
                  <button type="button" class="btn btn-danger" @click="resetForm('ExcludedCandidate')">
                    No
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- scope ends here -->

      <!--update-->

      <div id="layer" v-if="Remainingdays < 0"></div>
    </div>
    <div class="text-center" v-if="!this.showcandidateList || candidateList.length === 0">
      <img src="../../public/dashboard-assets/images/M2/empty_record.png" style="width: 20%; padding-top: 7%; " />
      <h3 class="ms-5 mt-2">No Record Found</h3>
    </div>
    <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
      :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
      :page-class="'page-item'" v-if="this.showcandidateList == true && this.Remainingdays >= 0"
      id="tour-active-test-pagination" :class="this.componentTour == true ? 'disable-tour-selection' : ''">
    </paginate>


    <div class="modal fade" id="showTourModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-body">
            <div class="float-end" style="color: #333333;">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="row my-4 mx-1">
              <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6">
                <div style="font-weight: 600; font-size: 26px;">Ready to see how we can make your hiring process a
                  breeze?</div>
                <div style="color: #333333;" class="mt-2">
                  Explore our interactive tour and discover how to easily manage Test invites, Candidate Reports, and
                  many more!

                  Our platform is designed with you in mind, making everything smooth and straightforward.

                  Let's make your hiring process easier together!
                </div>
                <div class="mt-5">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button
                          class="tour-primary-button">Watch Video</button></div> -->
                    <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6 mb-3"><button class="tour-secondary-button"
                        @click="StartComponentTour()">Start Tour</button></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6" style="align-content:center">
                <img src="../../public/dashboard-assets/images/M2/TourImages/ActiveTestStart.svg" alt=""
                  style="width:100%;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Tour Complete Modal  -->
    <div class="modal fade" id="TourCompleteModal" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-body">
            <div class="my-6" style="text-align: center;">
              <h1 style="font-weight: 600; color: #333333;">Nice Work !</h1>
              <h2 style="font-weight: 600; color: #333333;" class="mt-2">Your view candidates summary is completed</h2>
              <div style="color: #111111; font-size: 18px;" class="mt-2">Time to hire best candidates through
                assessments </div>
              <div class="mt-5">
                <button type="button" class="tour-primary-button" data-bs-dismiss="modal" aria-label="btn-close"
                  style="max-width:200px">Tour Completed</button>
                <div class="mt-4" @click="this.OpenTourModal();" style="color: #7b68ee; cursor: pointer;"><i
                    class="fa-solid fa-arrow-rotate-left"></i><span class="mx-2">Restart Tour</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Multiselect from "@vueform/multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import { superadmin } from "../utils/helper";
import { getUserPermissionData } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import Paginate from "vuejs-paginate-next";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
// import { AccessPlanFeatures } from '../platformFeatures/featureUtils';
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
import * as XLSX from 'xlsx-js-style';
import eventBus from './TourComponents/eventBus.js'
import ActiveTestViewCandidatesTour from './TourComponents/ActiveTestViewCandidatesTour.vue'

const Status = Object.freeze({
  0: "Pending",
  1: "InProgress",
  2: "Review",
  3: "Pass",
  4: "Fail",
});

export default {
  name: "ActiveTestViewCandidates",
  components: {
    paginate: Paginate,
    ActiveTestViewCandidatesTour
    // Multiselect
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId
    })
  },
  data() {
    return {
      selectedCandidateId: "",
      uniqueCandidateId: "",
      grp: [],
      users: [],
      statustest: [],
      candidateList: [],
      candidateStatus: [],
      report: [],
      downloadinfo: [],
      offset: 1,
      limit: 10,
      search: "",
      fromDate: "",
      toDate: "",
      consolidated_sheet_fromDate: "",
      consolidated_sheet_toDate: "",
      currentPage: 1,
      Page_count: 1,
      filteredCandidates: 0,
      totalCandidates: 0,
      TestStatus: Status,   // re-use this in this page
      //filter
      applyfilter: 0,
      searchfilter: "",
      filterTab: 1,
      filters: {
        region: [],
        name: "",
      },
      searchDisable: false,
      //search
      input: "",
      result: [],
      name: [],
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      behavioural: [],
      reportType: "",
      dateData: "",
      date: "",
      consolidated_sheet_date: "",
      tokenId: "",
      showcandidateList: false,
      opencals: false,
      testName: "",
      groups: [],
      screenWidth: 0,
      routetestId: window.atob(this.$route.params.testId),
      routeclientId: window.atob(this.$route.params.clientId),
      routeisParent: window.atob(this.$route.params.isParent),
      routesearch: window.atob(this.$route.query.search),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      // routeFilterList: window.atob(this.$route.query.filterList),
      show: false,      // used for opening/closing search element
      candidateResult: 'Completed',
      completedCandidates: 0,
      inProgressCandidates: 0,
      pendingCandidates: 0,
      reviewCandidates: 0,
      statusList: [
        {
          key: 0,
          value: "Pending",
        },
        {
          key: 1,
          value: "InProgress",
        },
        {
          key: 2,
          value: "Review",
        },
        {
          key: 3,
          value: "Pass",
        },
        {
          key: 4,
          value: "Fail",
        }
      ],
      completedStatusList: [],
      cloneGroupList: [],
      cloneCompletedStatusList: [],
      selectCandidateStatus: [],
      billing: JSON.parse(localStorage.getItem('billingData')),
      Remainingdays: 0,
      sortCol: "",
      sortDir: "",
      filterStatus: false,
      extendByDays: 1,
      currentCandidateId: 0,
      currentExpiry: new Date(),
      bulkActionType: '',
      excludedActionType: '',
      excludedActionMessage: '',
      isAllSelected: false,
      // actionType: [
      //   'Bulk resend invite',
      //   'Bulk delete'
      // ],
      candidateIds: [],
      inviteLogsList: [],
      forceInviteStatus: false,
      forceInviteId: 0,
      forceInviteEmail: '',
      forceInviteMobile: '',
      componentTour: false,
      receivedData: false,
      tempCandidateData: false,

    };
  },
  async created() {
    this.componentName = this.$route.name;
    // this.actionType = this.actionType.filter((status) => status !== 'Bulk delete');
    if (this.billing != null) {
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
    }
    // await AccessPlanFeatures(this.user.extId, 49, 'Active Test Access');

    window.addEventListener("resize", this.myEventHandler);
    this.screenWidth = window.outerWidth;
    this.completedStatusList = this.statusList.filter(
      status => {
        return status.value.includes('Pass') || status.value.includes('Fail')
      });
    this.cloneCompletedStatusList = [...this.completedStatusList];

    await this.viewCandinateList(this.candidateResult);

    var testdetail = await this.activetestdetail({
      testId: this.routetestId,
    });
    this.testName = testdetail.object.data?.testName;

    await this.getTestReport();
    // var categories = await this.regionfilter({
    //   clientId: this.clientId,
    //   offset: 1,
    //   limit: -1,
    // });
    // if (categories.status) {
    //   this.regionCategories = categories.object.data.regions;
    // }


    if (this.$route.query.email != '' && this.$route.query.email != undefined) {
      const encryptedEmail = this.$route.query.email;
      const decryptedEmail = atob(encryptedEmail);
      this.input = decryptedEmail;
      await this.filteredList();
    }

  },
  async mounted() {
    // var dataToSend=false;
    eventBus.emit('data-from-child-component', true);

    eventBus.on('data-from-header-component', data => {
      this.receivedData = data;
      this.OpenTourModal();
    });

    this.screenWidth = window.outerWidth;


    window.$('input[name="daterange"]').daterangepicker(
      {
        maxDate: new Date(),
        locale: {
          cancelLabel: "Clear",
        },
        opens: "center",
        autoUpdateInput: false
      },
      async (start, end) => {
        this.fromDate = start.format("YYYY-MM-DD 00:00:00");
        this.toDate = end.format("YYYY-MM-DD 23:59:59");
      }
    );
    window
      .$('input[name="daterange"]')
      .on("cancel.daterangepicker", async (ev, picker) => {
        picker.startDate._d = new Date();
        picker.endDate._d = new Date();

        this.fromDate = "";
        this.toDate = "";

        this.filters.status = this.filterByCandidatesStatus(this.candidateResult);
        this.currentPage = 1;
        this.clickCallback(this.currentPage);

        var list = await this.viewCandidateV2({
          testId: this.routetestId,
          OffSet: 1,
          Limit: this.limit,
          Search: this.input,
          regionId: this.filters.region.toString(),
          status: this.filters.status.toString(),
          fromDate: this.fromDate,
          toDate: this.toDate,
          isParent: localStorage.getItem("isParent")
        });
        this.showcandidateList = true;
        this.candidateList = list.object.data.testCandidates;
        this.completedCandidates = list.object.data.completedCandidates;
        this.inProgressCandidates = list.object.data.inProgressCandidates;
        this.pendingCandidates = list.object.data.pendingCandidates;
        this.reviewCandidates = list.object.data.reviewCandidates;
        this.filteredCandidates = list.object.data.filteredCandidates;
        this.totalCandidates = list.object.data.totalCandidates;
        this.Page_count = list
          ? Math.ceil(list.object.data.filteredCandidates / this.limit)
          : 0;
        window.$('input[name="daterange"]').val("");
      });
    window
      .$('input[name="daterange"]')
      .on("apply.daterangepicker", async (ev, picker) => {
        var start = moment(String(picker.startDate._d)).format(
          "YYYY-MM-DD 00:00:00"
        );
        var end = moment(String(picker.endDate._d)).format(
          "YYYY-MM-DD 23:59:59"
        );
        if (
          Math.ceil(
            Math.abs(new Date(start) - new Date(end)) / (1000 * 60 * 60 * 24)
          ) <= 180
        ) {
          this.date = start + " - " + end;
          if (this.opencals == false) {
            this.filters.status = this.filterByCandidatesStatus(this.candidateResult);
            this.currentPage = 1;
            this.clickCallback(this.currentPage);

            var list = await this.viewCandidateV2({
              testId: this.routetestId,
              OffSet: 1,
              Limit: this.limit,
              fromDate: this.fromDate,
              Search: this.input,
              regionId: this.filters.region.toString(),
              toDate: this.toDate,
              status: this.filters.status,
              isParent: localStorage.getItem("isParent")
            });
            if (list.status) {
              this.showcandidateList = true;
              this.candidateList = list.object.data.testCandidates;
              this.completedCandidates = list.object.data.completedCandidates;
              this.inProgressCandidates = list.object.data.inProgressCandidates;
              this.pendingCandidates = list.object.data.pendingCandidates;
              this.reviewCandidates = list.object.data.reviewCandidates;
              this.filteredCandidates = list.object.data.filteredCandidates;
              this.totalCandidates = list.object.data.totalCandidates;
              this.Page_count = list
                ? Math.ceil(list.object.data.filteredCandidates / this.limit)
                : 0;
              this.currentPage = 1;
            } else {
              // this.candidateList = [];
              this.showcandidateList = false;
              this.candidateList = [];
            }
          }
        }
        else {
          this.fromDate = 0;
          this.toDate = 0;
          return this.ShowWarningMessage("Please select range within 180 days.");
        }
        //window.$('#ConsolidatedSheet').modal('show');
      });

    window.$('input[name="consolidated_sheet_daterange"]').daterangepicker(
      {
        maxDate: new Date(),
        locale: {
          cancelLabel: "Clear",
        },
        opens: "center",
        autoUpdateInput: false
      },
      async (start, end) => {
        this.consolidated_sheet_fromDate = start.format("YYYY-MM-DD 00:00:00");
        this.consolidated_sheet_toDate = end.format("YYYY-MM-DD 23:59:59");
      }
    );

    window
      .$('input[name="consolidated_sheet_daterange"]')
      .on("cancel.daterangepicker", async (ev, picker) => {
        picker.startDate._d = new Date();
        picker.endDate._d = new Date();

        this.consolidated_sheet_fromDate = "";
        this.consolidated_sheet_toDate = "";

        this.filters.status = this.filterByCandidatesStatus(this.candidateResult);
        this.currentPage = 1;
        this.clickCallback(this.currentPage);

        var list = await this.viewCandidateV2({
          testId: this.routetestId,
          OffSet: 1,
          Limit: this.limit,
          Search: this.input,
          regionId: this.filters.region.toString(),
          status: this.filters.status.toString(),
          fromDate: this.consolidated_sheet_fromDate,
          toDate: this.consolidated_sheet_toDate,
          isParent: localStorage.getItem("isParent")
        });
        this.showcandidateList = true;
        this.candidateList = list.object.data.testCandidates;
        this.completedCandidates = list.object.data.completedCandidates;
        this.inProgressCandidates = list.object.data.inProgressCandidates;
        this.pendingCandidates = list.object.data.pendingCandidates;
        this.reviewCandidates = list.object.data.reviewCandidates;
        this.filteredCandidates = list.object.data.filteredCandidates;
        this.totalCandidates = list.object.data.totalCandidates;
        this.Page_count = list
          ? Math.ceil(list.object.data.filteredCandidates / this.limit)
          : 0;
        window.$('input[name="consolidated_sheet_daterange"]').val("");
      });

    window
      .$('input[name="consolidated_sheet_daterange"]')
      .on("apply.daterangepicker", async (ev, picker) => {
        var start = moment(String(picker.startDate._d)).format(
          "YYYY-MM-DD 00:00:00"
        );
        var end = moment(String(picker.endDate._d)).format(
          "YYYY-MM-DD 23:59:59"
        );
        if (
          Math.ceil(
            Math.abs(new Date(start) - new Date(end)) / (1000 * 60 * 60 * 24)
          ) <= 365
        ) {
          this.consolidated_sheet_date = start + " - " + end;
          if (this.opencals == false) {
            this.filters.status = this.filterByCandidatesStatus(this.candidateResult);
            this.currentPage = 1;
            this.clickCallback(this.currentPage);

            var list = await this.viewCandidateV2({
              testId: this.routetestId,
              OffSet: 1,
              Limit: this.limit,
              fromDate: this.consolidated_sheet_fromDate,
              Search: this.input,
              regionId: this.filters.region.toString(),
              toDate: this.consolidated_sheet_toDate,
              status: this.filters.status,
              isParent: localStorage.getItem("isParent")
            });
            if (list.status) {
              this.showcandidateList = true;
              this.candidateList = list.object.data.testCandidates;
              this.completedCandidates = list.object.data.completedCandidates;
              this.inProgressCandidates = list.object.data.inProgressCandidates;
              this.pendingCandidates = list.object.data.pendingCandidates;
              this.reviewCandidates = list.object.data.reviewCandidates;
              this.filteredCandidates = list.object.data.filteredCandidates;
              this.totalCandidates = list.object.data.totalCandidates;
              this.Page_count = list
                ? Math.ceil(list.object.data.filteredCandidates / this.limit)
                : 0;
              this.currentPage = 1;
            } else {
              // this.candidateList = [];
              this.showcandidateList = false;
              this.candidateList = [];
            }
          }
        }
        else {
          this.consolidated_sheet_fromDate = 0;
          this.consolidated_sheet_toDate = 0;
          return this.ShowWarningMessage("Please select range within 365 days.");
        }
        window.$('#ConsolidatedSheet').modal('show');
      }

      );

    await this.groupLists();
    await this.fromEmail();

    this.candidateStatus = this.candidateList;

    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
  beforeUnmount() {
    eventBus.off('data-from-header-component');
    eventBus.emit('data-from-child-component', false);
  },
  methods: {
    getUserPermissionData,
    ...mapActions([
      "viewCandidateV2",
      "ShowWarningMessage",
      "ShowErrorMessage",
      "getRegionCategories",
      "getRegionCategoriesNewData",
      "resendInvite",
      "resendInviteEmailCandidate",
      "getviewReport",
      "downloadsheet",
      "UserRegionData",
      "viewReport",
      "invitefromemail",
      "AllActiveTest",
      "adminregionList",
      /*update*/
      "activetestdetail",
      "MedusindBulkData",
      "deleteCandidate",
      "getLastCandidateInviteMode",
      "getLastCandidateInviteModeV2",
      "sendSmsInvitation",
      "IsSmsInvitePreference",
      "patchExtendTestDuration",
      "bulkResendInvite",
      "bulkDeleteCandidate",
      "GetConsolidatedSheet",
      "getCandidateInviteLogs",
      "fetchCandidateInviteDump",
      "forceResendInvite",
      "PostBulkInviteCandidate"
      /*update*/
    ]),
    changeFormat: function (args) {
      this.dateFormat = args.value;
      this.separate = args.value === "yyyy/MM/dd HH:mm" ? "to" : "-";
    },

    ...mapMutations(["setLoading"]),
    dateConvert: (value) => {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    superadmin,
    displayLogDate(value) {
      // returns time in 24 hour format
      return moment(String(value)).format("YYYY-MM-DD HH:mm");
    },
    timeConverter(val) {
      const today = new Date(val);
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      var strTime = h + ":" + m + ":" + s + " ";
      return strTime;
    },
    timeConvert(val) {
      const today = new Date(val);
      let h = today.getHours();
      let m = today.getMinutes();
      var meridian = h >= 12 ? "PM" : "AM";
      h = h % 12;
      h = h ? h : 12;
      m = m < 10 ? "0" + m : m;
      var strTime = h + ":" + m + " " + meridian;
      return strTime;
    },
    encode(item) {
      return window.btoa(item);
    },
    myEventHandler(e) {
      this.screenWidth = window.outerWidth;
      e.preventDefault();
    },
    async OpenTourModal() {
      window.$("#TourCompleteModal").modal("hide");
      window.$("#showTourModal").modal("show");
    },
    async CloseComponentTour() {
      window.$("#TourCompleteModal").modal("show");
      if (this.tempCandidateData) {
        this.tempCandidateData = false;
        this.candidateList = [];

      }
      window.scrollTo(0, 0);
      this.componentTour = false;
    },
    async StartComponentTour() {
      window.$("#showTourModal").modal("hide");
      //  this.changeTab('Completed');
      // this.selectCandidateStatus = [];
      // this.candidateResult = 'Completed';
      // this.filterTab = this.candidateResult === 'Completed' ? 1 : 2;
      // this.applyfilter = this.selectCandidateStatus.length + this.filters.region.length;
      // this.currentPage = 1;


      if (this.candidateList.length <= 0) {
        this.candidateList = [
          {
            "candidateUniqueId": 1000001,
            "firstName": "Nisha",
            "lastName": "Kamble",
            "emailAddress": "nkamble@pmaps.in",
            "examEndTime": "2024-05-30T19:05:10.283",
            "candidateStatus": 3,
            "mobileNo": "9503937617",
          },
          {
            "candidateUniqueId": 1000002,
            "firstName": "Bansi",
            "lastName": "Mehta",
            "emailAddress": "bmehta@pmaps.in",
            "examEndTime": "2024-05-30T19:05:10.283",
            "candidateStatus": 3,
            "mobileNo": "9819993091",
          },
          {
            "candidateUniqueId": 1000003,
            "firstName": "Sonali",
            "lastName": "Sawant",
            "emailAddress": "ssawant@pmaps.in",
            "examEndTime": "2024-05-30T19:05:10.283",
            "candidateStatus": 3,
            "mobileNo": "8591320212",
          },
        ];
        this.tempCandidateData = true;
      }
      this.componentTour = true;
    },

    clickCallback(pageNum) {
      this.isAllSelected = false;
      this.candidateIds = [];
      this.offset = (pageNum - 1) * this.limit + 1;
      this.viewCandinateList(this.candidateResult);
    },

    clearbtn() {
      this.dateData = "";
      this.selectedCandidateId = ""
      document.getElementById("choosedatetimebtn").click();
    },
    showReport() {
      window.$("#View-report").modal("show");
      window.$('#choose-Date-time').modal('hide');
    },

    showExtendLinkModal(id) {
      this.currentCandidateId = id;
      window.$("#extendLinkModal").modal("show");

    },
    viewbuttondisabled() {
      if (this.dateData != "") {
        return true;
      } else {
        return false;
      }
    },

    filterDisabledButton() {
      if (this.applyfilter == 0) {
        return true;
      } else {
        return false;
      }
    },

    DownloadDisabledButton() {
      if (this.consolidated_sheet_date != "") {
        return true;
      } else {
        return false;
      }
    },

    computeExpiryDate(day) {
      var date = new Date();
      return moment(String(date)).add(day, 'days').format("ll");
    },

    downlodedViewReports(name, testid, TokenId, PsySecId) {
      var url = "";
      switch (name) {
        case "Invigilation":
          url = `${window.app_base_url}/ManageCandidates/GetWebVideoProctoringResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "DestopProctoring":
          url = `${window.app_base_url}/ManageCandidates/GetProctoringResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "Psychometry":
          url = `${window.app_base_url}/ManageCandidates/GetPsychometryDetailedResult?TestId=${testid}&TokenId=${TokenId}&PsySecId=${PsySecId}&isPrint=2`;
          this.url = url;
          break;
        case "Language":
          url = `${window.app_base_url}/ManageCandidates/GetLanguageResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "AnswerSheet":
          url = `${window.app_base_url}/ManageCandidates/GetAnswerSheet?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "ChatBot":
          url = `${window.app_base_url}/ManageCandidates/GetChatBotResult?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        case "CandidateDetail":
          url = `${window.app_base_url}/ManageCandidates/ViewCandidateDetails?TestId=${testid}&TokenId=${TokenId}&isPrint=2`;
          this.url = url;
          break;
        //Added by Pmaps Team
        case "Overall Report":
          url = `${window.app_base_url}/ManageCandidates/GetAptitudeResult?TestId=${testid}&TokenId=${TokenId}&ClientId=${this.user.clientId}&UserId=${this.user.userId}&isPrint=2`;
          this.url = url;
          break; //Added by Pmaps Team
        default:
          name;
      }
      // window.$('#iframe').modal('show');
      window.open(url);
    },

    changeTab(value) {
      if (value != 'Completed') {
        eventBus.emit('data-from-child-component', false);
      }
      if (value == 'Completed') {
        eventBus.emit('data-from-child-component', true);
      }
      this.selectCandidateStatus = [];
      this.candidateResult = value;
      // if(this.candidateResult !== 'Pending' && 
      // getUserPermissionData({
      //   parent: 'Candidate Management',
      //   child: 'Candidates',
      //   permission: 'Can_Reinvite_Candidate',
      // })) {
      //   this.actionType = this.actionType.filter((status) => status !== 'Bulk delete');
      // } else {
      //   if(getUserPermissionData({
      //       parent: 'Candidate Management',
      //       child: 'Candidates',
      //       permission: 'Can_Delete_Candidate',
      //     })) {
      //       if(!this.actionType.includes('Bulk delete')) {
      //         this.actionType.push('Bulk delete');
      //       }
      //     }
      // }
      this.filterTab = this.candidateResult === 'Completed' ? 1 : 2;
      this.applyfilter = this.selectCandidateStatus.length + this.filters.region.length;
      this.currentPage = 1;
      this.clickCallback(this.currentPage);
    },

    async extendTestDuration() {
      await this.patchExtendTestDuration({
        candidateId: this.currentCandidateId,
        days: this.extendByDays
      })
      await this.viewCandinateList(this.candidateResult);
      window.$("#extendLinkModal").modal("hide");
    },

    filterByCandidatesStatus(candidateStatus) {
      this.filters.status = [];

      this.filters.status.push(candidateStatus === 'Completed' ?
        this.statusList.filter(status => {
          return status.value.includes('Pass') || status.value.includes('Fail');
        }).map(status => status.key)
        :
        this.statusList.filter(status => {
          return status.value.includes(candidateStatus);
        }).map(status => status.key));

      return this.filters.status;
    },

    async viewCandinateList(value) {
      this.candidateResult = value;
      this.filters.status = this.selectCandidateStatus.length == 0 ?
        this.filterByCandidatesStatus(this.candidateResult) : this.filters.status;

      var model = {
        testId: this.routetestId,
        OffSet: this.offset,
        Limit: this.limit,
        Search: this.input,
        // Search: this.filters.name,
        regionId: this.filters.region.toString(),
        status: this.filters.status.toString(),
        fromDate: this.fromDate,
        toDate: this.toDate,
        isParent: localStorage.getItem("isParent"), //update
        SortCol: this.sortCol,
        SortDir: this.sortDir,
      };
      var x = [];
      var viewList = await this.viewCandidateV2(model);

      if (viewList.status) {
        this.showcandidateList = true;
        this.candidateList = viewList.object.data.testCandidates;
        this.candidateList.forEach((element) => {
          x.push(element.testId);
        });

        this.testCandidates = viewList.object.data.testCandidates;
        this.completedCandidates = viewList.object.data.completedCandidates;
        this.inProgressCandidates = viewList.object.data.inProgressCandidates;
        this.pendingCandidates = viewList.object.data.pendingCandidates;
        this.reviewCandidates = viewList.object.data.reviewCandidates;
        this.filteredCandidates = viewList.object.data.filteredCandidates;
        this.totalCandidates = viewList.object.data.totalCandidates;

        this.Page_count = viewList
          ? Math.ceil(viewList.object.data.filteredCandidates / this.limit)
          : 0;
      } else {
        this.candidateList = [];
        this.showcandidateList = false;
      }
    },

    async getTestReport() {
      var datas = await this.getviewReport({
        testId: this.routetestId,
      });
      this.report = datas.object.data.reports;
      this.behavioural = datas.object.data.behavioural;
      this.reportType = datas.object.data.reportType;
    },

    selectedCandidate(candidate) {
      this.selectedCandidateId = candidate.candidateId;
      this.dateData = this.dateConvert(candidate.examEndTime) + " - " + this.timeConvert(candidate.examEndTime);
      this.uniqueCandidateId = candidate.candidateUniqueId;
      this.firstName = candidate.firstName;
      this.lastName = candidate.lastName;
      this.email = candidate.emailAddress;
      this.mobileNo = candidate.mobileNo;
      this.tokenId = candidate.tokenId;
    },
    async fetchInviteLogs(candidateObj) {
      this.firstName = candidateObj.firstName;
      this.lastName = candidateObj.lastName;
      this.uniqueCandidateId = candidateObj.candidateUniqueId;
      var inviteLogs = await this.getCandidateInviteLogs(candidateObj.candidateId);
      this.inviteLogsList = inviteLogs.object.data;
    },
    async fetchCandidateDump(testId) {
      await this.fetchCandidateInviteDump(testId);
    },
    async DemoModal() {
      window.$('#DemoModal').modal('show');
    },
    //updated by Pmaps team
    async ResendInvite(candidateId, emailAddress, mobileNo) {
      var lastInviteMode = await this.getLastCandidateInviteMode({
        candidateId: candidateId
      });

      var isSmsSettingEnabled = await this.IsSmsInvitePreference({
        clientId: this.routeclientId
      });

      let isSmsMode = true;
      let contactDetailsList = [];

      if (lastInviteMode.status) {
        this.email = emailAddress;
        this.mobileNo = mobileNo;
        var isSMSModeChosen = lastInviteMode.object.data;
        isSmsMode = isSMSModeChosen === false ? 1 : 2;
        if (isSmsMode === 2 && isSmsSettingEnabled === undefined) {
          return this.ShowErrorMessage("Sms invite is disabled. Kindly contact PMaps.");
        }
        contactDetailsList = isSMSModeChosen === false ? this.email : this.mobileNo;
      }

      var datas = await this.resendInvite({
        testId: this.routetestId,
        fromEmail: JSON.parse(localStorage.getItem("authUserData")).email,
        contactDetails: [contactDetailsList],
        candidateType: "Invite",
        inviteMode: isSmsMode
      });

      if (datas.status) {
        let candidates = [];
        var duplicateCandidates = datas.object.data.dupicateList.map(
          (x) => x.candidateId
        );
        if (duplicateCandidates.length) {
          candidates.push(duplicateCandidates[0]);
        } else {
          var newCandidates = datas.object.data.newList.map(
            (x) => x.candidateId
          );
          candidates.push(newCandidates[0]);
        }
        if (isSmsMode === 1) {
          await this.resendInviteEmailCandidate({
            testId: this.routetestId,
            senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
            candidates: candidates,
          });
        }
        else if (isSmsMode == 2) {
          await this.sendSmsInvitation({
            testId: this.routetestId,
            senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
            candidates: candidates
          });
        }
      }
    },

    async ForceReinvite() {
      this.forceInviteStatus = true;
      await this.ResendInviteV2(this.forceInviteId, this.forceInviteEmail, this.forceInviteMobile);
    },

    // async RabbitMQInvite(id) {
    //   this.PostBulkInviteCandidate({
    //     candidateIds: [id]
    //   });
    // },



    async ResendInviteV2(candidateId, emailAddress, mobileNo) {
      var lastInviteMode = await this.getLastCandidateInviteModeV2({
        candidateId: candidateId
      });

      var isSmsSettingEnabled = await this.IsSmsInvitePreference({
        clientId: this.routeclientId
      });

      let isSmsMode = true;
      let contactDetailsList = [];

      if (lastInviteMode.status) {
        this.email = emailAddress;
        this.mobileNo = mobileNo;
        var isSMSModeChosen = lastInviteMode.object.data;
        isSmsMode = isSMSModeChosen === false ? 1 : 2;
        if (isSmsMode === 2 && isSmsSettingEnabled === undefined) {
          return this.ShowErrorMessage("Sms invite is disabled. Kindly contact PMaps.");
        }
        contactDetailsList = isSMSModeChosen === false ? this.email : this.mobileNo;
      }

      var datas;
      if (this.forceInviteStatus) {
        datas = await this.forceResendInvite({
          testId: this.routetestId,
          fromEmail: JSON.parse(localStorage.getItem("authUserData")).email,
          contactDetails: [contactDetailsList],
          candidateType: "ForceInvite",
          inviteMode: isSmsMode,
          forceInvite: true,
          forceInviteType: this.excludedActionType
        });
        this.forceInviteStatus = false;
        this.forceInviteType = "";
      }
      else {
        datas = await this.resendInvite({
          testId: this.routetestId,
          fromEmail: JSON.parse(localStorage.getItem("authUserData")).email,
          contactDetails: [contactDetailsList],
          candidateType: "Invite",
          inviteMode: isSmsMode
        });
      }

      if (datas.status) {
        let candidates = [];
        var excludedCandidates = datas.object.data.excludedList.map(
          (x) => x.message
        );

        if (excludedCandidates.length && excludedCandidates[0] == "Cooling period is active.") {
          this.forceInviteId = candidateId;
          this.forceInviteEmail = emailAddress;
          this.forceInviteMobile = mobileNo;

          this.excludedActionType = "Cooling period active";
          this.excludedActionMessage = "Cooling period is active for the candidate.";
          window.$("#ExcludedCandidateActions").modal("show");
        }
        else {
          var duplicateCandidates = datas.object.data.dupicateList.map(
            (x) => x.candidateId
          );
          if (duplicateCandidates.length) {
            candidates.push(duplicateCandidates[0]);
          } else {
            var newCandidates = datas.object.data.newList.map(
              (x) => x.candidateId
            );
            candidates.push(newCandidates[0]);
          }
          if (isSmsMode === 1) {
            await this.resendInviteEmailCandidate({
              testId: this.routetestId,
              senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
              candidates: candidates,
            });
          }
          else if (isSmsMode == 2) {
            await this.sendSmsInvitation({
              testId: this.routetestId,
              senderEmail: JSON.parse(localStorage.getItem("authUserData")).email,
              candidates: candidates
            });
          }
        }
      }
      window.$("#ExcludedCandidateActions").modal("hide");
    },

    async NewConsolidatedSheet() {
      if (this.consolidated_sheet_fromDate != 0) {

        var value = await this.GetConsolidatedSheet({
          testId: this.routetestId,
          FromDate: this.consolidated_sheet_fromDate,
          ToDate: this.consolidated_sheet_toDate
        });

        var newData = value.object?.data;
        const sheetsData = newData;
        const workbook = XLSX.utils.book_new();

        for (const sheetName in sheetsData) {
          if (sheetsData.hasOwnProperty.call(sheetsData, sheetName)) {
            let sheetData = sheetsData[sheetName];
            if (sheetData == null || sheetData.length === 0) {
              continue;
            }

            const excelData = sheetData.map(data => {
              return { ...data };
            });
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            const range = XLSX.utils.decode_range(worksheet['!ref']);

            const desiredColumnWidths = 20; // Replace with your desired widths
            for (let C = range.s.c; C <= range.e.c; ++C) {
              worksheet['!cols'] = worksheet['!cols'] || [];
              worksheet['!cols'][C] = { wpx: desiredColumnWidths * 7.1 }; // Adjust multiplier if needed
            }

            for (let C = range.s.c; C <= range.e.c; ++C) {
              const address = XLSX.utils.encode_col(C) + "1";
              if (!worksheet[address]) continue;
              worksheet[address].v = worksheet[address].v.toUpperCase();
              if (!worksheet[address].s) worksheet[address].s = {};
              worksheet[address].s = { font: { bold: true } };
            }

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName.toUpperCase());
          }
        }

        XLSX.writeFile(workbook, `${this.testName}-Consolidated Sheet.xlsx`);
        this.resetForm("ConsolidatedSheet");
      }
      else {
        return this.ShowWarningMessage("Please Add Start and End Date in Filter for Downloading Consolidated Sheet");
      }
    },

    async ConsolidatedSheet() {
      if (this.consolidated_sheet_fromDate != 0) {
        var regionid = "";
        this.grp.forEach((element) => {
          this.groups.forEach((elm) => {
            if (elm.regionName == element) {
              regionid = elm.regionId;
            }
          });
        });
        var downloads = await this.downloadsheet({
          testId: this.routetestId,
          RegionId: regionid,
          UserId: this.user.userId,
          Status: this.statustest,
          formDate: this.consolidated_sheet_fromDate,
          toDate: this.consolidated_sheet_toDate,
        });
        this.downloadinfo = downloads.object.data.url;
        window.open(this.downloadinfo);
        this.opencals = false;
        this.resetForm("ConsolidatedSheet");
      }
      else {
        return this.ShowWarningMessage("Please Add Start and End Date in Filter for Downloading Consolidated Sheet");
      }
    },

    async MedusindBulkCandidatesDownload() {
      var downloads = await this.MedusindBulkData({
        fromDate: this.consolidated_sheet_fromDate,
        toDate: this.consolidated_sheet_toDate,
        userId: this.user.userId,
        testId: this.routetestId,
        clientId: this.user.clientId,
      });
      this.downloadinfo = downloads.object.data.url;
      window.open(this.downloadinfo);
      this.opencals = false;
      window.$("#MedusindBulkPopUp").close();
      document.getElementById("closeModal").click();
    },

    resetForm(mode) {
      /*update*/
      this.statustest = [];
      this.grp = [];
      this.consolidated_sheet_date = "";
      this.users = [];

      /*update*/
      if (mode === "MedusindData") {
        document.getElementById("close").click();
        window.$("#MedusindBulkPopUp").close();
      }
      else if (mode === "MedusindModal") {
        document.getElementById("close").click();
      }
      else if (mode === "ConsolidatedSheet" && this.routeclientId === "1025") {
        document.getElementById("close").click();
        window.$("#ConsolidatedSheet").close();
      } else if (mode === "ConsolidatedSheet") {
        document.getElementById("close").click();
        window.$("#ConsolidatedSheet").modal("hide");
      }
      else if (mode === "ExcludedCandidate") {
        window.$("#ExcludedCandidateActions").modal("hide");
      }
    },

    async groupLists() {
      var group;
      if (this.clientId == 1001) {
        group = await this.adminregionList({
          userId: this.routeclientId,
        });
        this.groups = group.object?.data.regions;
      } else {
        group = await this.UserRegionData({
          userId: this.user.userId,
        });
        this.groups = group.object.data;
      }
      this.cloneGroupList = [...this.groups];
    },
    async fromEmail() {
      var fromemail = await this.invitefromemail({
        userId: this.user.userId,
      });
      this.fromuser = fromemail.object.data;
    },

    //filter

    filterclose() {
      document.getElementById("filterDropdown").click();
    },

    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          var x = [];
          this.cloneCompletedStatusList.forEach((el) => {
            if (
              el.value.toLowerCase().startsWith(this.searchfilter.toLowerCase())
            ) {
              x.push(el);
            }
          });
          this.completedStatusList = x;
        } else {
          this.groups = this.cloneGroupList.filter(element => {
            return element.regionName
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          });
        }
      } else {
        this.completedStatusList = this.cloneCompletedStatusList;
        this.groups = this.cloneGroupList;
      }
    },

    async addFilter(value, e) {
      if (e.target.checked) {
        if (this.filterTab == 1) {
          if (!this.selectCandidateStatus.includes(value)) {
            this.selectCandidateStatus.push(value);
            this.filters.status = this.cloneCompletedStatusList.filter(status => {
              return this.selectCandidateStatus.includes(status.key)
            }).map(status => status.key);
          }
        } else {
          this.filters.region.push(value);
        }
        this.applyfilter =
          this.selectCandidateStatus.length + this.filters.region.length;
      } else {
        if (this.filterTab == 1) {
          if (this.selectCandidateStatus.length > 0) {
            this.selectCandidateStatus.splice(
              this.selectCandidateStatus.indexOf(value), 1
            );
            this.filters.status.splice(
              Object.freeze(
                JSON.parse(JSON.stringify(this.filters.status))
              ).indexOf(value),
              1
            );
          } else {
            this.filters.status = this.completedStatusList;
          }
        } else {
          this.filters.region.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.region))
            ).indexOf(value),
            1
          );
        }
        this.applyfilter =
          this.selectCandidateStatus.length + this.filters.region.length;
        if (this.applyfilter == 0) {
          await this.viewCandinateList(this.candidateResult);
        }
      }
    },

    async applyFilters() {
      this.currentPage = 1;
      this.clickCallback(this.currentPage);
      await this.viewCandinateList(this.candidateResult);
      localStorage.setItem("ActiveTestViewCandidatesfilters", JSON.stringify(this.filters));
      document.getElementById("filterDropdown").click();
    },

    async clearFilters(value) {
      this.selectCandidateStatus = [];
      this.filters.region = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.showcandidateList = true;
      this.offset = 1;
      this.Filterresult();
      await this.viewCandinateList(this.candidateResult);
      if (value) document.getElementById("filterDropdown").click();
      localStorage.removeItem('ActiveTestViewCandidatesfilters')
    },

    //search

    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },

    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
      this.filters.name = "";
      await this.viewCandinateList(this.candidateResult);
    },

    async filteredList() {
      // this.input = this.input.trim();
      this.searchDisable = true;
      if (this.input != "") {
        this.name = [];
        this.result = [];
        this.currentPage = 1;
        this.clickCallback(this.currentPage);

        var list = await this.viewCandidateV2({
          testId: this.routetestId,
          OffSet: 1,
          Limit: this.limit,
          Search: encodeURIComponent(this.input),
          regionId: this.filters.region.toString(),
          status: this.filters.status.toString(),
          fromDate: this.fromDate,
          toDate: this.toDate,
          isParent: localStorage.getItem("isParent")
        });
        this.searchDisable = false;
        if (list.status) {
          var x = list.object?.data.testCandidates;
          this.candidateList = list.object.data.testCandidates;
          this.completedCandidates = list.object.data.completedCandidates;
          this.inProgressCandidates = list.object.data.inProgressCandidates;
          this.pendingCandidates = list.object.data.pendingCandidates;
          this.reviewCandidates = list.object.data.reviewCandidates;

          x.forEach((element) => {
            if (this.name.indexOf(element.firstName) < 0) {
              this.name.push(element.firstName);
            }
          });
          this.result = this.name?.filter((element) =>
            element?.toLowerCase().startsWith(this.input?.toLowerCase())
          );
          this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
        } else {
          this.candidateList = [];
          this.showcandidateList = false;
        }
      } else {
        this.result = [];
        this.filters.name = "";
        await this.viewCandinateList(this.candidateResult);
      }
      if (this.input.trim() != "") {
        list.Search = this.input;
      }
    },
    requestbuttondisable() {
      return this.extendByDays > 0 && this.extendByDays < 365;
    },

    // async searchregionname(val) {
    //   this.input = val.trim();
    //   this.offset = 1;
    //   this.search = val;
    //   this.result = [];
    //   this.currentPage = 1;
    //   await this.viewCandinateList();
    // },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color: #7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },

    async removeCandidate(candidateId) {
      var datas = await this.deleteCandidate(candidateId);
      if (datas.status) {
        var determine_page_no = (this.pendingCandidates - 1) % this.limit;    // 1(static value) has been subtracted as only 1 candidate is deleted using this method
        if (determine_page_no === 0) {
          if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.offset = (this.currentPage - 1) * this.limit + 1;
          }
        }
        await this.viewCandinateList(this.candidateResult);
      }
    },
    opencal() {
      document.getElementById("calendar_icon").click();
    },
    opendatePicker() {
      this.opencals = true;
      document.getElementById("consolidated_sheet_calendar_icon").click();
    },
    openmobilesearch() {
      this.show = true;
      this.result = [];
      this.input = "";
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },

    async closemobilesearch() {
      this.show = false;
      document.querySelector(".Searching_input_box_mobile_view").classList.remove("open");
      this.result = [];
      this.input = "";
      this.filters.name = "";
      await this.viewCandinateList(this.candidateResult);
    },

    async SortBy(col, dir) {
      this.filterStatus = !this.filterStatus;
      this.currentPage = 1;
      this.sortCol = col;
      this.sortDir = dir;
      await this.viewCandinateList(this.candidateResult);
    },
    selectCandidate(val) {
      if (val === 'generic') {
        if (this.isAllSelected === true) {
          if (this.candidateIds.length === 0) {
            this.candidateIds = this.candidateIds.concat(this.testCandidates.map(x => x.candidateId));
          } else {
            var addRemainingCandidates = this.testCandidates.filter(
              obj => !this.candidateIds.includes(obj.candidateId)
            ).map(x => x.candidateId);
            this.candidateIds = this.candidateIds.concat(addRemainingCandidates);
          }
        } else {
          this.candidateIds = [];
        }
      } else {
        if (this.candidateIds.length === this.testCandidates.length) {
          this.isAllSelected = true;
        } else {
          this.isAllSelected = false;
        }
      }
    },
    doBulkAction(val) {
      this.bulkActionType = val;
      window.$("#BulkCandidateActions").modal("show");
    },
    closeBulkActionModal() {
      window.$("#BulkCandidateActions").modal("hide");
    },
    /*
    async doBulkAction(val) {
      this.bulkActionType = val;
      if(this.bulkActionType === '') {
        this.candidateIds = [];
        return;
      } else if(this.bulkActionType === 'Bulk delete') {
        // do bulk delete
        if(this.candidateIds.length === 0) {
          return this.ShowWarningMessage("Please select candidates before deleting candidates");
        }
        var deleteCandidates = await this.bulkDeleteCandidate({
          candidateIds: this.candidateIds 
        });
        if(deleteCandidates.status) {
          var determine_page_no = (this.pendingCandidates - this.candidateIds.length) % this.limit;
          if(determine_page_no === 0) {
            if(this.currentPage > 1) {
              this.currentPage = this.currentPage - 1;
              this.offset = (this.currentPage - 1) * this.limit + 1;
            }
          }
          await this.viewCandinateList(this.candidateResult);
        }
      } else {
        if(this.candidateIds.length === 0 ) {
          return this.ShowWarningMessage("Please select candidates before resending invites");
        }
        await this.bulkResendInvite({
          candidateIds: this.candidateIds
        });
      }
      this.isAllSelected = false;
      this.candidateIds = [];
      this.bulkActionType = '';
    },
    */
    async resendBulkInvites() {
      this.candidateIds = this.candidateIds.sort((a, b) => a - b);
      await this.bulkResendInvite({
        candidateIds: this.candidateIds
      });
      window.$("#BulkCandidateActions").modal("hide");
      this.candidateIds = [];
      this.isAllSelected = false;
    },

    async RabbitMQBulkInvite() {
      this.candidateIds = this.candidateIds.sort((a, b) => a - b);
      await this.PostBulkInviteCandidate({
        candidateIds: this.candidateIds
      });
      window.$("#BulkCandidateActions").modal("hide");
      this.candidateIds = [];
      this.isAllSelected = false;
    },


    async deleteBulkCandidates() {
      var deleteCandidates = await this.bulkDeleteCandidate({
        candidateIds: this.candidateIds
      });
      window.$("#BulkCandidateActions").modal("hide");
      this.candidateIds = [];
      this.isAllSelected = false;
      if (deleteCandidates.status) {
        var determine_page_no = (this.pendingCandidates - this.candidateIds.length) % this.limit;
        if (determine_page_no === 0) {
          if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.offset = (this.currentPage - 1) * this.limit + 1;
          }
        }
        await this.viewCandinateList(this.candidateResult);
      }
    }
  }
};
</script>

<style scoped>
.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

#layer {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: -2%;
  left: 1%;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
}

.active_inc_dec_button {
  background-color: #e8e4ff !important;
  color: gray !important;
  padding: 4px 15px;
  margin-bottom: 5px;
}

.btn_view_Report {
  color: #7b68ee;
  cursor: pointer;
}

.table thead th {
  text-transform: capitalize;
  color: #000;
}

@media (max-width: 480px) {
  .font-size-MV {
    font-size: 13px;
  }
}

.disable-text-selection {
  user-select: none;
}

.table thead th {
  text-transform: capitalize;
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.font-weight {
  font-weight: 700;
}

.active_form_button2 {
  background-color: #fff !important;
  color: #7b68ee !important;
}

.btn-active-TestView {
  padding: 4px 15px;
  margin-bottom: 5px;
}

.notialert-kj-view {
  border-radius: 50%;
  padding: 0.5rem;
  border: 1px solid #7c68ee;
  background-color: #f1f0ff;
  color: #7c68ee;
  top: 0;
  position: absolute;
  width: 20px;
  right: -20px;
  font-size: 11px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cal-alert {
  border-radius: 50%;
  padding: 0.4rem;
  /* border: 1px solid #7c68ee; */
  background-color: #ffdd00;
  left: 90%;
  /* color: #7c68ee; */
  top: 10%;
  position: absolute;
}

.center-elements {
  display: flex;
  justify-content: center;
}

.bulk-actions-style:hover {
  cursor: pointer;
  text-decoration: underline;
}

.disable-tour-selection {
  pointer-events: none;
}
</style>