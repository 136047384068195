<template>
    <div class="page-content">
        <h4>
            Plans
            <span class="float-end">
                <span class="mx-2" style="display: flex; align-items: center; justify-content: center;">
                    <div style="color: #333333; font-size:17px; display: block; margin-right: 10%;">Monthly</div>
                    <div class="form-check form-switch">
                        <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked"
                            checked v-model="planType">
                    </div>
                    <div style="color: #333333; font-size:17px; display: block;margin-left: 4%; ">Yearly</div>
                </span>
               <span style="color:#7c68ee; font-size: small;"> Save up to 15% with yearly billing</span>
            </span>
        </h4>
        <div class="cards-container">
        <div v-for="(plan, index) in filteredAbridgedPlans" :key="plan">
            <div 
                :class="[
                    'card', 
                    'plan-card', 
                    'mt-5', 
                    (plan.planCode === 'GW_MN_INR') || (plan.planCode === 'GW_YR_INR') ? 'active-plan-card' : ''
                ]"
            >
            <!--  :class="[
                    'card', 
                    'plan-card', 
                    'mt-5', 
                    this.currentPlan === plan.planCode ? 'active-plan-card' : ''
                ]" -->

                <div>
                    <!-- <div class="pophead" v-if="this.currentPlan !== plan.planCode && index === 1">
                        Most Popular
                    </div> -->
                    <div class="pophead" v-if="(plan.planCode === 'GW_MN_INR') || (plan.planCode === 'GW_YR_INR') ">
                        Most Popular
                    </div>
                    <div class="plan-header pt-2 pb-2 mt-4">
                        {{ plan.displayName }} Plan
                    </div>
                    <div class="mt-3">
                        <div class="plan-price" v-if="plan.price > 0">
                            {{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}
                            <span class="plan-type" v-if="this.filterType === 'Monthly'"> / month </span>
                            <span class="plan-type" v-else> / year </span>
                        </div>
                        <div class="plan-price" v-else>
                            Custom
                        </div>
                    </div>
                    <div class="mt-3 plan-subheading mx-3">
                        {{ plan.description }}
                    </div>
                    <div class="mt-5" style="text-align: center;">
                        <button 
                            class="plan-button" 
                            @click="paymentPage(plan.planCode)"
                            v-if="plan.price > 0 && this.currentPlan !== plan.planCode"
                        >
                            Select Plan
                        </button>
                        <button 
                            class="plan-button" 
                            v-else-if="this.currentPlan === plan.planCode" 
                            disabled
                        >
                            Active Plan
                        </button>
                        <button @click="openCalendly" class="plan-button" v-else> Contact us </button>
                    </div>
                    <div class="mt-5 mx-3" style="font-weight: 600; color: #333333;" v-if="index > 0">
                        All previous features, Plus:
                    </div>
                    <div class="mt-5 mx-3" style="font-weight: 600; color: #333333;" v-else>
                        Plan Includes:
                    </div>
                    <div class="mt-3 mx-3 plan-points">
                        <div class="row d-flex align-items-center my-3" v-for="points in plan.features" :key="points">
                            <div class="col-auto">
                                <img src="../../../public/assets/Images/green-tick.png" style="width: 20px;" />
                            </div>
                            <div class="col">
                                {{ points.unit }} {{ points.name }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 mb-3" style="color: #5e45eb; text-align: center; cursor: pointer;"  @click="navigateToId('pricing-table')">
                        Know More
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="mt-6" style="text-align: center;" id="pricing-table">
            <h3 class="mt-6" style="font-weight: 600;">Detailed Plan Breakdown: Tailored Options for Every Budget
                Requirement</h3>
            <h5 style="color: #333333;" class="mt-2">We tailor our plans to cater to your specific talent assessment
                needs, ensuring transparency and clarity.</h5>
        </div>

        <div class="table-container mt-4" >
            <div class="plan table-card mt-3 first-card" style="align-content:center"> 
                <div class="mx-2" style="display: flex; align-items: center; justify-content: center;">
                    <div style="color: #333333; font-size:20px; display: block; margin-right: 4%;">Monthly</div>
                    <div class="form-check form-switch">
                        <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked"
                            checked v-model="planType" style="height: 30px; width:60px">
                    </div>
                    <div style="color: #333333; font-size:20px; display: block;margin-left: 4%; ">Yearly</div>
                </div>
               <div style="color:#7c68ee;" class="mt-3"> Save up to 15% with yearly billing</div>
            </div>
            <div class="plan table-card mt-3" v-for="plan in filteredPlans" :key="plan">
                <div class="table-plan mt-3">{{ plan.displayName }}</div>
                <div class="mt-3" v-if="plan.price > 0">
                    <div class="table-price">{{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}</div>
                    <div class="table-type" v-if="this.filterType == 'Monthly'"> / month</div>
                    <div class="table-type" v-else> / year</div>
                </div>
                <div class="mt-3" v-else>
                    <div class="table-price">Custom</div>
                    <div class="table-type"> pricing </div>
                </div>
                <div class="table-button-container" v-if="plan.price > 0 && this.currentPlan != plan.planCode">
                    <div class="table-button mt-3 d-none d-lg-flex" @click="paymentPage(plan.planCode)">Select Plan
                    </div>
                    <div class="table-text mt-3 d-flex d-lg-none" @click="paymentPage(plan.planCode)">Select</div>
                </div>
                <div class="table-button-container" v-else-if="plan.price > 0 && this.currentPlan == plan.planCode">
                    <div class="table-button mt-3 d-none d-lg-flex">Active Plan</div>
                    <div class="table-text mt-3 d-flex d-lg-none">Active Plan</div>
                </div>
                <div class="table-button-container" v-else>
                    <div class="table-button mt-3 d-none d-lg-flex" @click="openCalendly">Contact us</div>
                    <div class="table-text mt-3 d-flex d-lg-none" @click="openCalendly">Contact us</div>
                </div>
            </div>
        </div>

        <div class="custom-collapse" v-for="modules in PlanFeatures" :key="modules.moduleName">
            <button class="collapse-toggle" @click="toggleCollapse()">{{ modules.moduleName }}</button>
            <div class="collapse-content my-3" v-for="features in modules.features" :key="features">
                <div class="collapse-card-width first-card-width">{{ features.name }}</div>
                <!-- <div v-for="plans in features.plans" :key="plans">
                    <div class="collapse-card-width" v-if="plans.value > 0">
                        {{ plans.value }}
                    </div>
                    <div class="collapse-card-width" v-else>
                        <div class="collapse-card-width" v-if="plans.hasFeature">
                            <img src="../../../public/assets/Images/green-tick.png" style="width: 20px;" />
                        </div>
                    </div>
                </div> -->
                    <div class="collapse-card-width" v-for="plans in features.plans" :key="plans">
                        <span v-if="plans.value > 0">
                            {{ plans.value }}
                        </span>
                        <span v-else-if="plans.value == 0">
                            Custom
                        </span>
                        <span v-else>
                            <img src="../../../public/assets/Images/green-tick.png" style="width: 20px;" v-if="plans.hasFeature" />
                            <img src="../../../public/assets/Images/cross.png" style="width: 20px;" v-else />
                        </span>
                        
                       
                    </div>
                    
            </div>
            <!-- <div class="collapse-content my-3">
                <div class="collapse-card-width first-card-width">40% Width</div>
                <div class="collapse-card-width"><img src="../../../public/assets/Images/green-tick.png"
                        style="width: 20px;" /></div>
                <div class="collapse-card-width"><img src="../../../public/assets/Images/green-tick.png"
                        style="width: 20px;" /></div>
                <div class="collapse-card-width"><img src="../../../public/assets/Images/green-tick.png"
                        style="width: 20px;" /></div>
                <div class="collapse-card-width"><img src="../../../public/assets/Images/green-tick.png"
                        style="width: 20px;" /></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// import { encryptData, decryptData } from '@/platformFeatures/security'
import eventBus from "../../store/eventBus";
export default {
    data() {
        return {
            PlanFeatures: [],
            allFeatures: [],
            PlanDetails: [],
            planType: false,
            show: false,
            data: [],
            billing: JSON.parse(localStorage.getItem('billingData')),
            abridgedPlanData: []
        };
    },
    async created() {
        eventBus.emit('hide-button');
        this.currentPlan = this.billing.object.data.plan.planCode;

        var plan = await this.GetAbridgedPlanDetails();
        this.abridgedPlanData = plan.object.data;

        await this.planContent();
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        filterType() {
            return this.planType ? 'Yearly' : 'Monthly';
        },
        filteredAbridgedPlans() {
            return this.abridgedPlanData.filter((plan) => plan.type === this.filterType);
        },

        filteredPlans() {
            return this.PlanDetails.filter((plan) => plan.type === this.filterType);
        },
    },
    methods: {
        ...mapActions(['GetAbridgedPlanDetails', 'GetPlanCardDetails', 'getPaymentPage']),
        ...mapMutations(["setLoading"]),

        async planContent() {
            var planData = await this.GetPlanCardDetails({
                currency: ""
            });
            this.PlanFeatures = planData.object.data.features;
            this.PlanDetails = planData.object.data.allPlans;
            this.show = true;
        },
        async paymentPage(code) {
            const data = {
                subscription_id: this.billing.object.data.subscription.subScriptionId,
                plan: {
                    plan_code: code,
                },
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            };
            const response = await this.getPaymentPage(data);
            var url = await response.object.data.hostedpage.url;
            window.open(url, '_self');
        },
        fetchCurrency(data) {
            if (data == 'INR') {
                return '₹';
            } else {
                return '$';
            }
        },
        toggleCollapse() {
            const collapseElement = document.querySelector('.custom-collapse');
            collapseElement.classList.toggle('active');
        },
        openCalendly(){
            window.open("https://calendly.com/nkamble/pmaps-quick-support-new-requirements", '_blank');
        },
        navigateToId(id) {
            const element = document.getElementById(id);
            if (element) {
                const offsetTop = element.offsetTop - 100;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }
        },

    },
    mounted() {
        document.addEventListener('DOMContentLoaded', function () {
            const toggleButton = document.querySelector('.collapse-toggle');
            const collapseContent = document.querySelector('.collapse-content');

            toggleButton.addEventListener('click', function () {
                collapseContent.parentElement.classList.toggle('active');
            });
        });
    }
}
</script>

<style scoped>
.page-content {
    display: flex;
    flex-direction: column;
}

.cards-container {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
}

@media (max-width: 768px) {
    .cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
}
}

.card {
    flex: 1;
    transition: all 0.3s ease;
}

.plan-card {
    min-width: 300px;
    min-height: 700px;
    background-color: white;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    border: 2px solid #efefef;
}

.active-plan-card {
    background-color: #ffffff;
    transform: scale(1.05);
    z-index: 10;
    border: 2px solid #5e45eb;
}

.plan-header {
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    color: #333333;
    background-color: #ffffff;
}

.active-plan-card .pophead {
    font-size: small;
    background-color: #5e45eb;
    color: #ffffff;
    text-align: center;
    width: 100%;
}

.active-plan-card .plan-header {
    font-weight: 600;
    margin-top: 10px;
}

.plan-price {
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
    color: #333333;
}

.plan-type {
    text-align: center;
    font-size: medium;
    font-weight: 500;
    color: #959595;
}

.plan-subheading {
    text-align: center;
    font-size: 15px;
}

.plan-button {
    width: auto;
    height: auto;
    font-size: large;
    border-radius: 7px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #ffffff;
    background-color: #5e45eb;
    color: #ffffff;
}



.table-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.table-card {
    background-color: white;
    position: relative;
    overflow: hidden;
    border: 2px solid #efefef;
    text-align: center;
    min-height: 220px;
}

.first-card {
    flex: 0 0 40%;
}

.table-card:not(.first-card) {
    flex: 0 0 15%;
}

.table-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.table-button {
    border: 1px solid #ffffff;
    background-color: #5e45eb;
    color: #ffffff;
    border-radius: 7px;
    font-size: 15px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.table-text {
    display: none;
}

.table-plan {
    font-weight: 600;
    font-size: large;
}

.table-price {
    font-weight: 600;
    font-size: larger;
}

.table-type {
    color: #333333;
}

@media (max-width: 768px) {
    .first-card {
        display: none;
    }

    .table-card:not(.first-card) {
        flex: 0 0 25%;
    }

    .table-plan,
    .table-price,
    .table-type,
    .table-button,
    .table-text {
        font-size: smaller;
    }

    .table-text {
        display: flex;
    }

    .table-button {
        display: none;
    }
}

@media (max-width: 576px) {
    .first-card {
        display: none;
    }

    .table-card:not(.first-card) {
        flex: 0 0 25%;
    }

    .table-plan,
    .table-price,
    .table-type,
    .table-button,
    .table-text {
        font-size: smallest;
    }

    .table-card {
        min-height: 175px;
    }
}

.custom-collapse {
    width: 100%;
    border: 1px solid #ddd;
    background-color: #f8f9fa;
}

.collapse-toggle {
    width: 100%;
    height: 50px;
    background-color: #5e45eb;
    color: #ffffff;
    border: none;
    font-size: 20px;
    cursor: pointer;
    text-align: left;
    padding-left: 50px;
    line-height: 40px;
}
@media (max-width: 576px) {
    .collapse-toggle {
        text-align: center;
        font-size: 16px;

    } 
}

.collapse-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    height: 80px;
    font-size: 18px;
}

.collapse-card-width {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    flex: 1;
}

.first-card-width {
    flex: 2.7;
}

.custom-collapse.active .collapse-content {
    display: flex;
}

@media (max-width: 768px) {
    .collapse-content {
        flex-wrap: wrap;
    }

    .first-card-width {
        flex: 0 0 100%;
        margin-bottom: 10px;
    }

    .collapse-card-width:not(.first-card-width) {
        flex: 0 0 25%;
    }
}
</style>