<template>
  <div class="page-content">
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{
                name: 'ActiveTestViewCandidatesNew', params: {
                  clientId: encode(this.clientId),
                  isParent: encode(isParent), //update
                },
                query: {
                  search: encode(this.routesearch),
                  offset: encode(this.routeoffset),
                  current: encode(this.routecurrent),
                },
              }" class="p-0 Group_top_three_dot_dropdown-active btn-back-arrow-kj" type="button"
                style="line-height: 2.6">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 fw-bolder">Report</h4>
          <h6 class="text-muted">

            {{ this.CognitivBasicInfo?.testName }}<i class="fa-solid fa-angle-right mx-2"></i>{{ this.CognitivBasicInfo?.name }}
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn d-block mx-3" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <!-- <button class="btn p-0 Group_top_three_dot_dropdown" type="button" @click="savePdf()">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
              </button> -->
              <a :href="reportUrl" target="_blank">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </a>
              <!-- <router-link :to="{
                name: 'ActiveTestReportCognitivePDF',
                query: {
                  candidateId: encode(this.candidateId),
                  testId: encode(this.testId),
                  name: encode(this.name),
                  clientId: encode(this.clientId),
                  isParent: encode(this.isParent),
                  id: encode(this.routesearch),
                  offset: encode(this.routecurrent),
                  current: encode(this.routecurrent)
                },
              }">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </router-link> -->
            </div>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
                        <div class="dropdown">
                          <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa-solid fa-ellipsis-vertical fa-lg" style="color: #7c7c7c;"></i>               
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a :href="compeleteReportUrl" target="_blank">
                              <button type="button" class="btn w-100 text-start">
                                Download Consolidated Report
                              </button>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
        </div>
      </div>
    </div>

    <div class="row Report_card_section">
      <div class="col-md-12">
        <div class="card active-card">
          <!--Header Component-->
          <div class="card mb-3">
            <ActiveTestHeaderVue :active="this.actived" :candidateId="this.candidateId" :testId="this.testId"
              :isParent="this.isParent" :clientId="this.clientId" :id="this.routesearch" :current="this.routecurrent"
              :offset="this.routeoffset" />
          </div>

          <!--Header Component-->
          <div class="card" 
          :class="[this.showFeatureContent == false ? 'disable-text-selection' : '']"
:style="{ filter: this.showFeatureContent == false ? 'blur(8px)' : '' }"
          >


            <div class="Overall_Cognitive_section my-4">
              <div class="Overall_Cognitive_header">
                Overall Cognitive Fitment
              </div>

              <div class="Overall_Cognitive_detail mt-4">
                <div class="Overall_Cognitive_detail_Definition pb-3">
                  <span class="Recommended_heading_text"> Definition </span>
                  <p class="Overall_Cognitive_detail_Definition_content">
                    {{
                      this.mainCoginitiveData.definition
                      ? this.mainCoginitiveData.definition
                      : "NA"
                    }}
                  </p>
                </div>
                <div class="Overall_Cognitive_detail_Definition">
                  <div class="row">
                    <div class="col-xl-10 col-lg-9 col-md-12 mt-4">
                      <span class="Recommended_heading_text">
                        Score Description
                      </span>
                      <div v-if="this.mainCoginitiveData?.comment?.length > 0" class="mt-4">
                        <p v-html="this.mainCoginitiveData?.comment" class="Overall_Cognitive_detail_Definition_content"></p>
                      </div>
                      <div v-else>
                        <p class="Overall_Cognitive_detail_Definition_content">
                          {{ "NA" }}
                        </p>
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-12 text-center mt-4">
                      <!-- <img
                      src="../../public/dashboard-assets/images/M7/Overall_Cognitive.png"
                      width="130"
                      class="ms-4"
                      alt=""
                    /> -->

                      <CustomChartVue :score="this.score1" v-if="this.show1" :main="'score'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading">
                <h4>
                  Cognitive Summary
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class=" cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Refers to the summary of candidate's score on all cognitive competencies" />
                </h4>
              </div>

              <!-- :class="[(subCoginitiveData.length % 2 === 1 && (index === 0 || index % 2 === 1)) ? 'border-end' : ((subCoginitiveData.length % 2 === 0 && index === 0) || (subCoginitiveData.length / 2 == index) ? 'border-end' : 'border-start')]" -->
              <!-- <div class="Score_Rank_chart_area">
                <div class="row justify-content-center mx-3">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-12 my-3 text-center" v-for="demo, index in subCoginitiveData"
                    :key="index" :class="[(index < subCoginitiveData.length - 1 && index % 3 != 2) ? 'border-end' : '']">
                    <label class="Score_Rank_label_color">{{ demo.competencyName }}
                      <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20"
                        class=" cursor-pointer ms-2" alt="" /> </label><br />
                    <CustomChartVue :score="this.score[index]" v-if="this.show[index]" :main="demo.competencyName" />
                    <hr v-if="(index + 1) % 3 !== 0 && index !== subCoginitiveData.length - 1" />
    
                  </div>
                </div>
              </div> -->

              <div class="Score_Rank_chart_area">
                <div class="row justify-content-center mx-3">
                  <template v-for="(demo, index) in subCoginitiveData" :key="index">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 my-3 text-center"
                      :class="[(index < subCoginitiveData.length - 1 && index % 3 !== 2) ? 'border-end' : '', (subCoginitiveData.length % 3 == 2 && index >= subCoginitiveData.length - 2) ? 'col-xl-6' : '']">
                      <label class="Score_Rank_label_color">{{ demo.competencyName }}
                        <img src="../../public/dashboard-assets/images/M7/Actions.png" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="demo.definition" ref="tooltipElement" width="20"
                          class="cursor-pointer ms-2" alt="" />
                      </label><br />
                      <CustomChartVue :score="this.score[index]" v-if="this.show[index]" :main="demo.competencyName" />
                    </div>
                    <!-- Add the <hr> line before each row change -->
                    <hr v-if="(index + 1) % 3 === 0 && index !== subCoginitiveData.length - 1" class="my-3" />
                  </template>
                </div>
              </div>






            </div>

            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading mb-2">
                <h4>
                  Cognitive Detailed report
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class=" cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Includes the definitions, scores and descriptions of the candidate on all cognitive competencies. " />
                </h4>
              </div>
              <div class="Overall_Cognitive_section mb-5" v-for="(item, index) in this.subCoginitiveData" :key="index">
                <div class="Overall_Cognitive_header">
                  {{ item.competencyName }}
                </div>

                <div class="Overall_Cognitive_detail mt-4">
                  <div class="Overall_Cognitive_detail_Definition pb-3">
                    <span class="Recommended_heading_text"> Definition </span>
                    <p class="Overall_Cognitive_detail_Definition_content">
                      {{ item.definition }}
                    </p>
                  </div>
                  <div class="Overall_Cognitive_detail_Definition">
                    <div class="row">
                      <div class="col-xl-10 col-lg-9 col-md-12 mt-4">
                        <span class="Recommended_heading_text">
                          Score Description
                        </span>
                        <!-- <p class="Overall_Cognitive_detail_Definition_content">
                          {{ item.comment }}
                        </p> -->
                        <div v-if="item.comment?.length > 0" class="mt-4">
                          <p class="Overall_Cognitive_detail_Definition_content"
                            v-html="item.comment">
                          </p>
                        </div>
                        <div v-else class="mt-4">
                          {{ "NA" }}
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-3 col-md-12 text-center mt-4">
                        <CustomChartVue :score="this.score[index]" v-if="this.show[index]"
                          :main="item.competencyName + item.competencyId" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Report_basic_info_area my-4">
            <div class="Report_basic_info_heading">
              <h4>DISCLAIMER</h4>
            </div>
            <div class="mt-3">
              <!-- <p> -->
                <!-- PMaps is involved in psychometric assessments and is capable of,
                on a best effort basis designing a test as per its client’s
                requirements and making a suggestion or giving an opinion on the
                suitability of a candidate for a particular job role based on the
                evaluation and interpretation of the candidate’s test results. The
                same applies to the report on the candidate’s psychometric
                profile, the report is an opinion on the candidate’s personality.
                PMaps makes no warranty or representation with respect to the
                accuracy of its opinion, suggestion or the profile report. Client
                will use PMaps opinion and suggestion as an input in its
                recruitment process, but PMaps will not be held liable for any
                decision that client takes based on the suggestions, opinions or
                the profile report given by PMaps. Client indemnifies PMaps from
                any liability that can arise on account of utilizing PMaps
                services and holds PMaps harmless without limitation, against any
                losses, claims, costs, damages or liabilities suffered by PMaps
                arising out of or in connection with providing its services to
                client. This indemnification extends to PMaps, its affiliates,
                respective directors, officers and employees. -->
                <!-- {{ CognitivBasicInfo.disclaimer }}
              </p> -->
              <span v-html="CognitivBasicInfo.disclaimer"></span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>


  <div v-if="showpdf">
    <ActiveTestReportCognitivePDF :testId="this.testId" :candidateId="this.candidateId" :name="this.name" :isPdf="true" />
    <!-- v-show="false" ref="DownloadComp" -->
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import ActiveTestHeaderVue from "./ActiveTestHeader.vue";
import CustomChartVue from "./CustomChart.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import store from "../store";
import ActiveTestReportCognitivePDF from "./ActiveTestReportCognitivePDF.vue";
import jsPDF from "jspdf";
import Base64Images from "../utils/base64Image.js";
// import { validatePlanFeatures } from '../platformFeatures/featureUtils';

export default {
  name: "ActiveTestReportCognitive",
  components: {
    ActiveTestHeaderVue,
    CustomChartVue,
    ActiveTestReportCognitivePDF,
  },
  data() {
    return {
      isShowing: false,
      actived: "Cognitive",
      mainCoginitiveData: [],
      subCoginitiveData: [],
      CognitivBasicInfo: "",
      candidateId: window.atob(this.$route.params.candidateId), //10098
      testId: window.atob(this.$route.params.testId), //3532
      name: "",//window.atob(this.$route.query.name),
      clientId: window.atob(this.$route.query.clientId),
      isParent: window.atob(this.$route.query.isParent),
      routesearch: window.atob(this.$route.query.id),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      score1: {},
      show1: false,
      show: [],
      score: [],
      showpdf: false,
      reportUrl: "",
      compeleteReportUrl : "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async created() {
    // this.showFeatureContent  = validatePlanFeatures(this.user.extId, 'User Dashboards & Reports' , 30 , 'General candidate reports');

    this.reportUrl = window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=Cognitive";
    this.compeleteReportUrl= window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=consolidated";
    this.getCognitiveReport();
    this.getcognitivInfo();
  },
  mounted() {
    store.state.loader.isLoading = true;
    setTimeout(() => {
      Array.from(
        document.querySelectorAll('img[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }, 1000);
  },
  methods: {
    ...mapActions(["cognitiveReport", "getBasicInfo"]),

    async getCognitiveReport() {
      var data = await this.cognitiveReport(this.candidateId);
      if (data?.status) {
        store.state.loader.isLoading = false;
        this.mainCoginitiveData = data.object.data;
        this.subCoginitiveData = this.mainCoginitiveData.competencies;
        //         var obj = {
        //     "competencyId": 20,
        //     "competencyName": "Atte To Detail",
        //     "definition": "Attention to Detail assess an individual's ability to recognize errors and differences in information. This competency ascertains that the individual's skill to complete tasks without any negligence or omission.",
        //     "fitment": "Low",
        //     "score": 3.33,
        //     "comment": "Descriptor3",
        //     "colorCode": "#D10000",
        //     "ranges": [
        //         {
        //             "rangeName": "Low",
        //             "minVal": 0,
        //             "maxVal": 4,
        //             "colorCode": "#FC70AE"
        //         },
        //         {
        //             "rangeName": "Average",
        //             "minVal": 4,
        //             "maxVal": 7,
        //             "colorCode": "#FEC800"
        //         },
        //         {
        //             "rangeName": "High",
        //             "minVal": 7,
        //             "maxVal": 10,
        //             "colorCode": "#7D69EE"
        //         }
        //     ]
        // }
        //         console.log(this.subCoginitiveData);
        //         this.subCoginitiveData.push(obj);



        //testing

        // this.mainCoginitiveData.competencies.forEach((element, index) => {

        //   if (index != 0 && index != 1) {
        //     this.subCoginitiveData.push(element);
        //   }


        // })



        this.subCoginitiveData.forEach((element, index) => {

          this.show[index] = false;
          var score = {
            marksScored: element.score,
            outOfMarks: element?.ranges[element?.ranges.length - 1].maxVal,
            result: element.fitment,
            ranges: element.ranges,
          };
          this.score[index] = score;
          this.show[index] = true;
          /*parameter score chart */
        });
        var score = {
          marksScored: this.mainCoginitiveData.score,
          outOfMarks:
            this.mainCoginitiveData?.ranges[
              this.mainCoginitiveData?.ranges.length - 1
            ].maxVal,
          result: this.mainCoginitiveData.fitment,
          ranges: this.mainCoginitiveData.ranges,
        };
        this.score1 = score;
        this.show1 = true;
      }

    },
    encode(item) {
      return window.btoa(item);
    },
    async getcognitivInfo() {
      var CognitivInfo = await this.getBasicInfo(this.candidateId);
      if (CognitivInfo?.status) {
        this.CognitivBasicInfo = CognitivInfo.object.data;
        this.clientCompany = this.CognitivBasicInfo.clientCompanyLogo;
        this.Companylogo = this.CognitivBasicInfo.companyLogo;
        this.namePdf = this.CognitivBasicInfo.name;
        this.testNamePDF = this.CognitivBasicInfo.testName;
      } else {
        this.CognitivBasicInfo = null;
      }
    },
    async savePdf() {
      store.state.loader.isLoading = true;
      this.showpdf = true;
      // const doc = new jsPDF("p", "mm", "a4" ); // Create a new instance of jsPDF with A4 dimensions
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        // autoPaging: "text"
      }); // Create a new instance of jsPDF with A4 dimensions
      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      const Companylogo = this.Companylogo;
      const clientCompany = this.clientCompany;
      setTimeout(() => {
        const element = document.getElementById("cognitive");
        const container = document.createElement("div");
        // Append the container to the element
        element.appendChild(container);

        doc.html(element, {
          margin: [25, 0, 25, 0],
          x: 0,
          y: 0,
          autoPaging: "text",
          callback: function (pdf) {
            // footer start
            const footerContainer = document.createElement("div");
            // Append the footer container to the element
            element.appendChild(footerContainer);

            const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages in the PDF document


            //Ankit code
            //  const headerMarginTop = 10; // Set the top margin for the header
            //const footerMarginBottom = 10;
            for (let i = 1; i <= pageCount; i++) {


              doc.setPage(i);
              // header start 
              if (i === 1) {
                var imageUrl = Base64Images.Background;
                // Add the background image
                doc.addImage(imageUrl, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image
                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                // console.log(img1)
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height
                );
              } else {

                // header 2
                doc.setFillColor(232, 247, 250); // Set the fill color to red (change the RGB values as per your desired color)
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F"); // Draw a filled rectangle as the header background

                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image

                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                doc.setFontSize(10);

                // Set the header text margin


                // Specify the content of the header text
                const headerText = `${testName}`;

                // Calculate the x-position to center the header text
                const textWidth =
                  (doc.getStringUnitWidth(headerText) *
                    doc.internal.getFontSize()) /
                  doc.internal.scaleFactor;
                const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;

                // Add the header text at the centered position
                doc.text(headerText, x, 10);

                // logo-2

                const img2 = document.createElement("img");
                img2.setAttribute(
                  "src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height);
              }
              // footer start
              doc.setFontSize(15);
              doc.setTextColor(41, 46, 52, 1);
              const img3 = document.createElement("img");
              img3.setAttribute(
                "src",
                Base64Images.Footer
              );

              const image3Width = doc.internal.pageSize.width; // Width of the image (equal to the page width)
              const image3Height = 20; // Height of the image
              const imageX3 = 0; // X position of the image (start from the left)
              const imageY3 = doc.internal.pageSize.height - image3Height;
              doc.addImage(img3, "JPEG", imageX3, imageY3, image3Width, image3Height);

              const footerText = "";
              const pageNumber = "Cognitive " + " | " + i;
              const textWidth = doc.getTextWidth(footerText); // Calculate the width of the footer text
              const xPos = doc.internal.pageSize.width - textWidth - 40; // 10 is the margin from the right side
              // Add footer text aligned to the right
              doc.text(footerText, xPos, doc.internal.pageSize.height - 10);
              // Add page number aligned to the right
              doc.text(pageNumber, xPos, doc.internal.pageSize.height - 5);
            }
            // end footer
            const pdfDataUri = pdf.output("datauristring");
            const link = document.createElement("a");
            link.href = pdfDataUri;
            link.download = `Cognitive_${namePdf}.pdf`;

            store.state.loader.isLoading = false;
            link.click();
          },
        });

        setTimeout(() => {
          this.showpdf = false;
        }, 2 * 1000);
      }, 1 * 60 * 1000);

    },

  },
};
</script>
<style>
.disable-text-selection {
  user-select: none;
}
</style>