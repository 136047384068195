<template>
  <div class="page-content">
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="mb-4 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">

          <router-link :to="{
            name: 'ActiveTests',
            query: {
              id: this.routesearch ? encode(this.routesearch) : '',
              offset: this.routeoffset ? encode(this.routeoffset) : '',
              current: this.routecurrent ? encode(this.routecurrent) : '',
              filter: true,
            },
          }" 
          class="p-0" type="button">
            <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
          </router-link>
        </div>
        <div class="Group_top_three_dot">
          <h4 class="mb-1 font-size-MV">
            Invite Candidates
          </h4>
          <h6 class="text-muted">
            {{ this.routetestName }}[{{ this.routetestId }}]
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="position-relative z-index-999 me-2">

        </div>

      </div>

    </div>


    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-2">
        <div class="card">
          <div class="progresses mx-4 mt-4 ">
            <div class="d-flex flex-column">
              <div>
                <div style="text-align:-webkit-center; cursor:default;" class="mb-3">
                  <span class="steps" v-if="this.senderDetailsSubmit != true">1</span>
                  <span class="steps step-success" @click="switchtoSenderDetails" style="cursor:pointer" v-else><i
                      class="fa fa-check"></i></span>
                  <span class="stepper-text px-3">Sender Details</span>
                </div>
              </div>
              <!-- <div><span class="line"></span></div> -->
              <div>
                <div style="text-align: -webkit-center;" class="mb-3">
                  <span class="steps" v-if="this.candidateDetailsSubmit != true">2</span>
                  <span class="steps step-success" @click="switchtoCandidateDetails" style="cursor:pointer" v-else><i
                      class="fa fa-check"></i></span>
                  <span class="stepper-text px-3">Candidate Details</span>
                </div>
              </div>
            </div>
            <div>
              <div style="text-align: -webkit-center;" class="mb-3">
                <span @click="switchtoSelectTemplate" style="cursor:pointer">
                  <span class="steps">3</span>
                  <span class="stepper-text px-3">Select Template</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>









      <div class="col-12 col-sm-12 col-md-8 col-lg-10">
        <div class="card">
          <div class="mt-4 mx-4" v-if="showSenderDetails == true">
            <span style="font-size: larger;">Sender Details</span>
            <div class="row mt-5">
              <div class="col-md-4 mb-2 col-lg-4">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <!-- <Multiselect v-model="this.inviteFrom" mode="single" :close-on-select="true" :options="this.fromuser"
                      :searchable="true" :group-select="true" @select="fromemailverificationstatus">
                    </Multiselect> -->

                    <select name="" v-model="this.inviteFrom" class="form-select input_area select_ca" id=""
                      @change="fromemailverificationstatus">
                      <option v-for="user in this.fromuser" :key="user" :value="user">
                        {{ user }}
                      </option>
                    </select>
                    <span class="input-name input-group-kj">From <span class="text-danger">*</span></span>
                  </label>
                </div>
              </div>

              <div class="col-md-4 mb-2 col-lg-4">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <!-- <Multiselect v-model="this.fromGroup" mode="single" :close-on-select="true" :options="this.groupname"
                      :searchable="true" :group-select="true">
                    </Multiselect> -->

                    <select v-model="this.fromGroup" class="form-select input_area select_ca">
                      <option v-for="group in this.groupname" :key="group.regionId" :value="group">
                        {{ group.regionName }}
                      </option>
                    </select>


                    <span class="input-name input-group-kj">Select Preferred Groups <span
                        class="text-danger">*</span></span>
                  </label>
                </div>
              </div>
              <div class="col-md-4 mb-2 col-lg-4">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <!-- <Multiselect v-model="this.fromPreferenceType" mode="single" :close-on-select="true"
                      :options="this.inviteType" :searchable="true" :group-select="true">
                    </Multiselect> -->
                    <select name="" v-model="this.fromPreferenceType" class="form-select input_area select_ca" id="">
                      <option v-for="invite in this.inviteType" :key="invite" :value="invite">
                        {{ invite }}
                      </option>
                    </select>


                    <span class="input-name input-group-kj">Choose your invite preferences <span
                        class="text-danger">*</span></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div style="color: #ff4545;" v-if="emailVerify == false"><i class="fa-solid fa-circle-info"></i><span
                  class="mx-1">Note: Selected Email is not verified with our AWS Server.<span
                    style="color: rgb(56, 106, 255); font-size: large; text-decoration: underline; cursor: pointer;"
                    @click="fromemailverification">Please Verify</span></span> </div>
              <button type="button" class="btn btn-primary float-end my-2" @click="storeSenderDetails"
                :disabled="!this.fromPreferenceType && !this.fromGroup && !this.inviteFrom">
                Save & Next
                <!-- {{ senderDetailsSubmit ? 'Edit Details' : 'Save & Next' }} -->
              </button>
            </div>
          </div>
          <div class="mt-4" v-if="this.showCandidateDetails == true">
            <div class="mx-4">
              <span style="font-size: larger;">
                <!-- <i class="fa-solid fa-arrow-left" style="color: #7b68ee;"></i> -->
                <!-- <i class="fa-solid fa-circle-chevron-left fa-xl" style="color: #d9d9d9;"></i> -->
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    Add Candidates
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-4">
                    <button type="button" class=" float-end modail_submit_button  bulkButton mx-2"
                      :disabled="isFileInputDisabled" @click="openFileInput()">
                      <input id="fileInput" class="file-upload-input" type="file" v-on:click="$event.target.value = ''"
                        v-on:change="handleFileUpload($event)"
                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                      <i class="fa-solid fa-upload" style="color: #7b68ee;"></i>
                      Upload {{ this.fromPreferenceType }} Template Sheet
                    </button>
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-4">
                    <button type="button" class=" float-end modail_submit_button  bulkButton mx-2"
                      @click="DownloadCandidateUploadSheet()">
                      <i class="fa-solid fa-download" style="color: #7b68ee;"></i>
                      Download {{ this.fromPreferenceType }} Template Sheet
                    </button>
                  </div>
                </div>

              </span>
              <!-- <div class="float-end">
              </div> -->
              <div>
                <ul class="
            nav nav-pills
            mb-3
            partners_top_menu_section_hp
            p-2
            mt-4
            user_tabing_nav_section
          " id="pills-tab" role="tablist" v-if="this.invalidInviteData.length > 0 && this.CandidateDetails.length > 0">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link btn px-3 " :class="{ 'active': this.verifiedDataTab === 'valid' }"
                      id="completed-candidates-tab" data-bs-toggle="pill" data-bs-target="#completed-candidates"
                      type="button" role="tab" aria-controls="completed-candidates" aria-selected="true"
                      @click="changetab('valid')" :disabled="this.editedItem != ''">
                      Valid ({{ this.CandidateDetails.length }})
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link btn px-3" :class="{ 'active': this.verifiedDataTab === 'invalid' }"
                      id="pending-candidates-tab" data-bs-toggle="pill" data-bs-target="#pending-candidates" type="button"
                      role="tab" aria-controls="pending-candidates" aria-selected="false" @click="changetab('invalid')"
                      :disabled="this.editedItem != ''">
                      Invalid ({{ this.invalidInviteData.length }})
                    </button>
                  </li>
                </ul>
              </div>
              <div class="text-muted mt-4 mb-3" style="font-weight: 600; font-size: 14px;"
                v-if="this.CandidateDetails.length > 0">
                Total Candidates {{ this.CandidateDetails.length }}
              </div>
              <div class="card p-0" id="" style="border-radius: 15px;" v-if="this.CandidateDetails.length > 0">
                <div class="table-responsive" v-if="verifiedDataTab == 'valid'">
                  <table class="table text-center">
                    <thead style="background-color: #F1F0FF !important;">
                      <tr>
                        <th>Sr No</th>
                        <th v-for="(item, index) in tablePreference" :key="index"> {{ item }}</th>
                        <th><span @click="verifiedDataTab == 'valid' ? removeAll() : removeAllInvalid()"
                            style="font-weight: 600; cursor: pointer;"> <i class="fa-solid fa-trash-can mx-2"></i>Remove
                            All</span></th>
                      </tr>
                    </thead>
                    <tbody v-for="(details, index) in  CandidateDetails"
                      :key="index + 1">
                      <tr v-if="index + 1 !== editingIndex">
                        <td>{{ index + 1 }}</td>
                        <td>{{ details }}</td>
                        <td>
                          <span @click="editItem(index + 1)"><i class="fa-solid fa-pen-to-square fa-lg mx-3"
                              style="color: #7b68ee; cursor: pointer;"></i></span>
                          <span @click="removeItem(index + 1)"> <i class="fa-solid fa-circle-xmark  fa-lg"
                              style="color: #7b68ee; cursor: pointer;"></i></span>
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ index + 1 }}</td>
                        <td><input v-model="editedItem" @keyup.enter="saveEdit(index + 1)" @keyup.esc="cancelEdit"
                            style="width: 300px; height: 40px; border-color: #7b68ee;"></td>
                        <td>
                          <span class="btn btn-primary mx-3" @click="saveEdit(index + 1)">Save</span>
                          <span class="btn btn-primary" @click="cancelEdit">Cancel </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-responsive" v-else>
                  <table class="table text-center">
                    <thead style="background-color: #F1F0FF !important;">
                      <tr>
                        <th>Sr No</th>
                        <th v-for="(item, index) in tablePreference" :key="index"> {{ item }}</th>
                        <th><span @click="verifiedDataTab == 'valid' ? removeAll() : removeAllInvalid()"
                            style="font-weight: 600; cursor: pointer;"> <i class="fa-solid fa-trash-can mx-2"></i>Remove
                            All</span></th>
                      </tr>
                    </thead>
                    <tbody v-for="(details, index) in  invalidInviteData"
                      :key="index + 1">
                      <tr v-if="index + 1 !== editingIndex">
                        <td>{{ index + 1 }}</td>
                        <td>{{ details }}</td>
                        <td>
                          <span @click="editItem(index + 1)"><i class="fa-solid fa-pen-to-square fa-lg mx-3"
                              style="color: #7b68ee; cursor: pointer;"></i></span>
                          <span @click="removeItem(index + 1)"> <i class="fa-solid fa-circle-xmark  fa-lg"
                              style="color: #7b68ee; cursor: pointer;"></i></span>
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ index + 1 }}</td>
                        <td><input v-model="editedItem" @keyup.enter="saveEdit(index + 1)" @keyup.esc="cancelEdit"
                            style="width: 300px; height: 40px; border-color: #7b68ee;"></td>
                        <td>
                          <span class="btn btn-primary mx-3" @click="saveEdit(index + 1)">Save</span>
                          <span class="btn btn-primary" @click="cancelEdit">Cancel </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mt-2" v-for="(item, index) in tablePreference" :key="index">
                <span v-if="this.CandidateDetails.length <= 0">
                  <div class="row">
                    <div class="col-12 col-lg-10 col-sm-12 col-md-8">
                      <textarea class="mt-2" v-model="newItem" :placeholder="`Add ${item} Manually`"
                        style="width: 100%; height: 150px; border-color: #7b68ee; background-color: white; border-radius: 5px;"
                        @keyup.enter="addItem()"></textarea>
                    </div>
                    <div class="col-12 col-lg-2 col-sm-12 col-md-4" style="align-self: center;">
                      <span class="btn btn-primary" @click="addItem" style="width :100%;"><span
                          style="font-weight: 600; margin-left: 5%;">Add</span><i
                          class="fa-solid fa-plus fa-lg mx-2"></i></span>
                    </div>
                  </div>
                  <div style="color: #7b68ee;" class="mt-3 mb-2"><i class="fa-solid fa-circle-info"></i><span
                      class="mx-1">Note: Duplicate Candidate {{ this.tablePreference[0] }} will be automatically adjusted
                      by the system.</span>
                  </div>
                </span>
                <div class="row" v-if="this.CandidateDetails.length > 0">
                  <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-3">
                    <div style="position: relative; display: inline-block; width: 100%; ">
                      <input v-model="newItem" :placeholder="`Add ${item} Manually`"
                        style="width: calc(100% - 40px); height: 40px; border-color: #7b68ee; padding-right: 40px;"
                        @keyup.enter="addItem()">
                      <button @click="addItem"
                        style="position: absolute; top: 0; right: 0; height: 40px; width: 50px; border: none; background-color: #7b68ee; border-radius: 0 5px 5px 0; cursor: pointer; color: #F1F0FF;">
                        <i class="fa-solid fa-plus fa-lg mx-2"></i> </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.CandidateDetails.length > 0">
                <span class="row mx-2">
                  <span class="col-12 col-lg-6 col-md-12 col-sm-12">
                    <span style="color: #7b68ee;"><i class="fa-solid fa-circle-info"></i><span class="mx-1"
                        style="font-size: small;">Note: Duplicate Candidate {{ this.tablePreference[0] }} will be
                        automatically adjusted by the
                        system.</span> </span>
                  </span>
                  <span class="col-12 col-lg-3 col-md-6 col-sm-12">
                    <button class="btn secondary-button1" @click="switchtoSenderDetails"
                      style="text-align: -webkit-center; width:100%">Previous Step</button>
                  </span>
                  <span class="col-12 col-lg-3 col-md-6 col-sm-12">
                    <button type="button" class="btn btn-primary" @click="storeCandidateDetails" style="width:100%">
                      <!-- {{ this.candidateDetailsSubmit ? 'Edit Candidate Details' : 'Save & Next' }} -->
                      Save & Next
                    </button>
                  </span>
                </span>
              </div>
            </div>
          </div>









          <div v-if="this.showTemplateSelection == true">
            <div class="mx-4 my-4">
              <span style="font-size: larger; ">
                <!-- <i class="fa-solid fa-circle-chevron-left fa-xl" style="color: #d9d9d9;"></i> -->
                Template View
              </span>
              <!-- <h4>
                SHOW TEMPLATE
              </h4> -->
              <!-- <ul class="
            nav nav-pills
            mb-3
            partners_top_menu_section_hp
            p-2
            mt-4
            user_tabing_nav_section
          " id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link btn px-3 active" id="completed-candidates-tab" data-bs-toggle="pill"
                      data-bs-target="#completed-candidates" type="button" role="tab" aria-controls="completed-candidates"
                      aria-selected="true">
                      Email
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link btn px-3" id="pending-candidates-tab" data-bs-toggle="pill"
                      data-bs-target="#pending-candidates" type="button" role="tab" aria-controls="pending-candidates"
                      aria-selected="false">
                      SMS
                    </button>
                  </li>
                </ul> -->
              <div v-if="this.GetInviteType[0] == 1" class="float-end" style="color: #7b68ee; cursor:pointer;"
                @click="editTemplateData()">
                <span v-if="editTemplateStatus == false"> <i class="fa-solid fa-pen-to-square "></i> Edit Template
                </span>
                <!-- <span v-else><i class="fa-solid fa-circle-check"></i> Save </span> -->
              </div>
            </div>
            <div v-if="this.GetInviteType[0] == 1" class="mx-4 my-4">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-7" v-if="editTemplateStatus == true">
                  <div class="form-group mb-4 mt-3">
                    <label class="input-hero">
                      <!-- <span class="form-control" style="height:40px">{{
                          this.emailSubjectLine }}</span> -->
                      <input type="text" class="form-control" v-model="this.emailSubjectLineString" />
                      <span class="input-name">
                        Subject
                      </span>
                    </label>
                  </div>
                  <div class="form-group my-4">
                    <!-- <label class="input-hero" v-if="editTemplateStatus == false">
                        <span style="height:40px" v-html="this.emailContentData"></span>
                        <span class="input-name">
                          Body
                        </span>
                      </label> -->
                    <span class="QuillEditor-kj">
                      <QuillEditor theme="snow" v-model:content="this.emailContentDataString" contentType="html"
                        :toolbar="toolbarOptions" @blur="saveemailtemplate" @textChange="invitedisable"
                        style="height: 350px; overflow-y: auto;" />
                    </span>
                    <div class=" mt-4 ">
                      <span class="secondary-button1 py-1 px-2"
                        style="color: #7b68ee; cursor:pointer; border-radius: 25px; width:80px"
                        @click="editTemplateData()">
                        Save
                        <i class="fa-solid fa-circle-check fa-lg"></i>
                      </span>
                      <span class="mx-2" style="color: #7b68ee; cursor:pointer;" @click="cancelEditTemplate()">
                        Cancel</span>
                    </div>
                  </div>
                </div>
                <div
                  :class="{ 'col-12 col-sm-12 col-md-6 col-lg-5': editTemplateStatus, 'col-12 col-sm-12 col-md-12 col-lg-12': !editTemplateStatus }">
                  <!-- <div style="text-align: -webkit-center; font-weight: 600;" class="mt-3">Preview</div> -->
                  <div class="card mt-2"
                    style="box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);border-radius: 15px;">
                    <!-- style="  box-shadow: 0px 0px 5px 0px;"  -->
                    <h5 class="mt-3" style=" 
                            width:100%;     
                            background: transparent;
                            padding-left:3%;
                            padding-right:3%;
                           ">{{ this.emailSubjectLineString != null ? this.emailSubjectLineString :
                             this.emailSubjectLine }}</h5>
                    <div class="mt-4" style="padding-left: 3%; padding-right: 3%;">
                      <div class="row">
                        <div class="col-auto">
                          <img src="../../public/dashboard-assets/images/emailUser.png"
                            style="width: 45px; height: 45px;" />
                        </div>
                        <div class="col">
                          <span style="display:block; "><span style="font-weight: 600;">{{ this.inviteFrom }}</span>
                            <span class="float-end" style="font-size:13px; color: #7a7a7a;">{{ currentTime }}</span>
                          </span>
                          <span style="display:block; font-size:13px; color: #7a7a7a;">to me</span>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" v-html="this.formattedContent"
                      style="height: 350px; width: 100%; background: transparent; padding-left: 5%; padding-right: 5%; background-color: none;overflow-y: auto;">
                    </div>



                    <!-- <div class="preview-content"></div> -->

                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 mx-4 my-4" style="text-align: -webkit-center;">
              <div class="card mb-4" style="min-height: 420px; 
                            width:220px;     
                            background: transparent;
                            border-style: solid;
                            border-width: 7px 7px 7px 7px;
                            border-color: rgb(101, 101, 101);
                            background-color: #ffffff;
                            border-radius: 25px;
                            " v-if="this.GetInviteType[0] == 2">
                <div class="headerCenter card" style="min-height: 100px; 
                                                      width: 208px;                            
                                                      border-color: #2B97FD;
                                                      background-color: #2B97FD;
                                                      border-radius: 18px 18px 0px 0px;
">
                  <!-- 1E1E1E -->

                  <div class="mt-3" style="text-align: -webkit-center;"><img
                      src="../../public/dashboard-assets/images/emailUser.png" style="width: 45px; height: 45px;" />
                    <div class="mt-2" style="font-size: 12px; color: white;">
                      {{ this.smsHeader }}
                      <i class="fa-solid fa-angle-right fa-xs"></i>
                    </div>
                  </div>
                </div>
                <span class="mt-3" style="font-size: 8px; text-align: center; color: #a8a8a8;">Today, {{ currentTime
                }}</span>
                <div class="card mt-1 px-2 py-2 mb-4" style="font-size: 13px; 
                            color: rgb(0, 0, 0); 
                            text-align: left; 
                            background-color: #CFCFCF ; 
                            border-radius: 5px; 
                            border-color:#CFCFCF ;
                            margin-left: 7%;
                            margin-right: 15%;">
                  {{ this.formattedContentSms }}
                </div>
                <div style="color: #efefef;" class="mt-7">
                  <div class="row mx-1">
                    <div class="col-12 col-lg-2 col-md-1 col-sm-1">
                      <div class="steps step-success fa-xs mb-2"
                        style="height: 25px; width: 25px; background-color:#2B97FD ;"> <i class="fa-solid fa-plus"></i>
                      </div>
                    </div>
                    <div class="col-12 col-lg-10 col-md-11 col-sm-11" style="">
                      <span class="card mb-2"
                        style="border-radius: 10px; background-color: #CFCFCF; width: 100%; height:25px; border-color: #CFCFCF; text-align: left; padding-left: 5%; font-size: x-small; padding-top: 3%; color: #2d2d2d;">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span class="row  mb-4 mt-3 mx-4">
              <span class="col-12 col-lg-6 col-md-12 col-sm-12">
                <div style="color: #7b68ee;" class="pt-2" v-if="this.GetInviteType[0] == 2"><i
                    class="fa-solid fa-circle-info"></i>
                  <span>
                    Note: SMS template can't be edited.</span>
                </div>
              </span>
              <span class="col-12 col-lg-3 col-md-6 col-sm-12">
                <button class="btn secondary-button1" @click="switchtoCandidateDetails"
                  style="text-align: -webkit-center; width:100%">Previous Step</button>
              </span>
              <span class="col-12 col-lg-3 col-md-6 col-sm-12">
                <button type="button" class="btn btn-primary" @click="inviteCandidateData()" style="width:100%"
                  :disabled="this.editTemplateStatus == true">
                  <!-- {{ this.candidateDetailsSubmit ? 'Edit Candidate Details' : 'Save & Next' }} -->
                  Invite Candidates
                </button>
              </span>
            </span>
            <!-- <button type="button" class="btn btn-primary float-end text-end " @click="inviteCandidateData()" :disabled="this.editTemplateStatus==true">
                Invite Candidates
              </button> -->

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { superadmin } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
// import Multiselect from "@vueform/multiselect";
import { QuillEditor } from "@vueup/vue-quill";
import readXlsxFile from 'read-excel-file';
import moment from "moment";


export default {
  name: "InviteCandidatePage",
  components: {
    // Multiselect,
    QuillEditor
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    formattedContent() {
      const styledUserData = `<span style="color: #7b68ee;">${this.userData}</span>`;
      return this.emailContentDataString != null ?
        this.emailContentData.replace('{{ContentBody}}', this.emailContentDataString) :
        this.emailContentData.replace('{{ContentBody}}', styledUserData);
      //return this.emailContentData.replace('{{ContentBody}}', styledUserData);
    },

    formattedContentSms() {
      return this.smsContent.replace('{{msgCompany}}', this.companyName);
    }

  },
  data() {
    return {
      companyName: "",
      secondStepper: false,
      fromuser: [],
      inviteType: [],
      groupname: [],
      inviteFrom: [],
      fromGroup: [],
      fromPreferenceType: [],
      senderDetailsSubmit: false,
      candidateDetailsSubmit: false,
      showSenderDetails: true,
      showCandidateDetails: false,
      showTemplateSelection: false,
      CandidateDetails: [],
      newItem: '',
      editingIndex: null,
      editedItem: '',
      tablePreference: [],
      downloadUrl: '',
      invalidInviteData: [],
      validInviteData: [],
      isFileInputDisabled: false,
      verifiedDataTab: 'valid',
      smsContent: '',
      smsHeader: '',
      routetestId: '',
      routeclientId: '',
      routetestName: '',
      routesearch: '',
      routeoffset: '',
      routecurrent: '',
      regionList: [],
      selectedRegionId: [],
      GetInviteType: [],
      emailSubjectLine: "",
      emailContentData: "",
      emailSubjectLineString: "",
      emailContentDataString: "",
      editTemplateStatus: false,
      userData: "[Enter Your Message Here]",
      emailRegex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,3})+$/,
      mobileRegex: /^\d{10}$/,
      currentTime: '',
      emailVerify: true,
      invalidStatus: true
    };
  },

  async created() {
    this.routetestId = window.atob(this.$route.params.id);
    this.routeclientId = window.atob(this.$route.params.clientId);
    this.routetestName = window.atob(this.$route.params.testName);
    this.routesearch = window.atob(this.$route.query.search);
    this.routeoffset = window.atob(this.$route.query.offset);
    this.routecurrent = window.atob(this.$route.query.current);

    var userInfo = await this.invitefromemail({
      userId: this.user.userId
    });
    this.fromuser = userInfo.object.data;

    var inviteUserType = await this.GetInviteModes({
      clientId: this.routeclientId
    });
    var type = inviteUserType.object.data;
    type.forEach((element) => {
      if (element.isActive) this.inviteType.push(element.name);
    });

    if (this.user.clientId == 1001) {
      var region = await this.adminregionList({
        userId: this.routeclientId,
      });
      this.regionList = region.object.data.regions;
    } else {
      var regionData = await this.UserRegionData({
        userId: this.user.userId,
      });
      this.regionList = regionData.object.data;
    }

    this.regionList.forEach((element) => {
      if (element.isActive) this.groupname.push(element);
    });
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },

  methods: {
    ...mapActions(["invitefromemail", "Companyheader", "ShowSucessMessage", "emailverifystatus", "emailverification", "adminregionList", "GetInviteModes", "ShowWarningMessage", "GetInviteSmsTemplate", "postInviteCandidate", "GetInviteEmailTemplatePreview", "putInviteEmailTemplate", "UserRegionData"]),

    ...mapMutations(["setLoading"]),

    superadmin,
    async fromemailverificationstatus() {
      const emailvstatus = await this.emailverifystatus({
        sesRegion: "m",
        value: this.inviteFrom,
      });
      if (emailvstatus.object.data[0].status == "Success") {
        this.emailVerify = true;
      } else {
        this.emailVerify = false;
      }
    },
    async fromemailverification() {
      await this.emailverification({
        sesRegion: "m",
        clientId: this.user.clientId,
        emails: [this.inviteFrom],
      });

      this.$router.push({
        name: 'ActiveTests',
        query: {
          id: this.routesearch ? this.encode(this.routetestId) : '',
          offset: this.routeoffset ? this.encode(this.routeoffset) : '',
          current: this.routecurrent ? this.encode(this.routecurrent) : '',
        },
      });
    },

    updateTime() {
      this.currentTime = moment().format('h:mm A');
    },
    async handleFileUpload(event) {
      if (event) {
        if (event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.ShowWarningMessage("Please select a valid file format");
          return;
        }

        var excelData = await readXlsxFile(event.target.files[0]);
        excelData.shift();

        if (Object.keys(excelData).length <= 1) {
          this.ShowWarningMessage('Empty excel sheet uploaded');
          return;
        }

        var data = this.fromPreferenceType.toUpperCase();

        if (data.includes("EMAIL")) {
          excelData.forEach(excelRow => {
            const data = excelRow[1]?.toString() || '';
            // const extraNumbers = Math.max(0, data.length - 10);
            // const substr = data.substring(extraNumbers);
            // if (isValidLength) {
            //   const extraNumbers = emailValue.length - 10;
            //   const substr = emailValue.substring(extraNumbers);
            if (this.emailRegex.test(data)) {
              if (!this.CandidateDetails.includes(data)) {
                this.CandidateDetails.push(data);
              }
            } else {
              this.invalidInviteData.push(data);
            }
            // }
          });
        }
        else {
          excelData.forEach(excelRow => {
            const data = excelRow[1]?.toString() || '';
            // const extraNumbers = Math.max(0, data.length - 10);
            // const substr = data.substring(extraNumbers);
            if (this.mobileRegex.test(data)) {
              if (!this.CandidateDetails.includes(data)) {
                this.CandidateDetails.push(data);
              }
            } else {
              this.invalidInviteData.push(data);
            }
          });
        }
        this.isFileInputDisabled = false;
      }
    },

    async inviteCandidateData() {
      // var data = this.fromPreferenceType.toUpperCase();

      var invite = await this.postInviteCandidate({
        candidateType: "Invite",
        clientId: this.user.clientId,
        userId: this.user.userId,
        contactDetails: this.CandidateDetails,
        fromEmail: this.inviteFrom,
        inviteMode: this.GetInviteType[0],
        regionId: this.selectedRegionId[0],
        testId: this.routetestId
      });
      // if(data.includes("EMAIL")){
      //   await this.UpdateInviteCandidate();
      // }
      // query: {
      //         id: this.routesearch ? encode(this.routetestId) : '',
      //         offset: this.routeoffset ? encode(this.routeoffset) : '',
      //         current: this.routecurrent ? encode(this.routecurrent) : '',
      //       },
      if (invite.status) {
        this.$router.push({
          name: 'ActiveTests',
          query: {
            id: this.routesearch ? this.encode(this.routetestId) : '',
            offset: this.routeoffset ? this.encode(this.routeoffset) : '',
            current: this.routecurrent ? this.encode(this.routecurrent) : '',
            filter: true
          },

        });
      }
    },
    DownloadCandidateUploadSheet() {
      var data = this.fromPreferenceType.toUpperCase();
      if (data.includes("SMS")) {
        this.downloadUrl = 'https://testdelivery.s3.ap-northeast-1.amazonaws.com/BulkUploadTemplate/Candidate/CandidateBulkMobileUploadSheet.xlsx';
      }
      if (data.includes("EMAIL")) {
        this.downloadUrl = 'https://testdelivery.s3.ap-northeast-1.amazonaws.com/BulkUploadTemplate/Candidate/CandidateBulkEmailUploadSheet.xlsx';
      }

      window.open(this.downloadUrl);
    },

    storeSenderDetails() {
      this.selectedRegionId = [];
      var inviteUser = [];
      var data = this.fromPreferenceType.toUpperCase();
      if (data.includes("SMS")) {
        inviteUser.push("2");
      }
      else {
        inviteUser.push("1");
      }

      if (this.GetInviteType[0] !== inviteUser[0]) {
        this.tablePreference = [];
        this.CandidateDetails = [];
        this.invalidInviteData = [];
        this.GetInviteType = [];
        this.candidateDetailsSubmit = false;
        this.candidateChoosenPreference();
      }

      if (this.inviteFrom.length !== 0 && this.fromGroup.length !== 0 && this.fromPreferenceType.length !== 0) {
        this.senderDetailsSubmit = true;
        this.showSenderDetails = false;
        this.showCandidateDetails = true;
        this.regionList.forEach((element) => {
          if (this.groupname) this.selectedRegionId.push(element);
        });
        this.selectedRegionId = this.groupname.filter(x => x.regionId === this.fromGroup.regionId).map(x => x.regionId);
      }
      else {
        return this.ShowWarningMessage("Please provide all details");
      }
    },
    async storeCandidateDetails() {

      if (this.CandidateDetails.length <= 500) {
        var data = this.fromPreferenceType.toUpperCase();

        // if (this.candidateDetailsSubmit == true) {
        //   this.tablePreference = [];
        //   this.CandidateDetails = [];
        //   this.invalidInviteData = [];
        // }

        if (this.CandidateDetails.length !== 0 && this.invalidInviteData.length === 0) {
          this.candidateDetailsSubmit = true;
          this.showCandidateDetails = false;
          this.showTemplateSelection = true;
        }
        else {
          this.changetab('invalid');
          return this.ShowWarningMessage("Please remove invalid details");
        }
        if (data.includes("SMS")) {
          this.smsTemplate();
        }
        else {
          // this.emailTemplate();
          this.previewTemp();

        }
      }
      else {
        this.ShowWarningMessage("Please add 500 candidates only");
      }
    },

    editItem(index) {
      this.editingIndex = index;
      if (this.verifiedDataTab == 'valid') {
        this.editedItem = this.CandidateDetails[index - 1];
      }
      else {
        this.editedItem = this.invalidInviteData[index - 1];
      }
    },

    // addItem() {
    //   var data = this.fromPreferenceType.toUpperCase();
    //   // const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,3})+$/;
    //   // const mobileRegex = /^\d{10}$/;
    //   const entries = this.newItem.split(/[\s,]+/).map(entry => entry.trim()).filter(Boolean);
    //   if (entries.length > 0) {
    //     const uniqueEntries = [...new Set(entries)];
    //     const MobileRegex = data.includes("SMS");
    //     const regexToUse = MobileRegex ? this.mobileRegex : this.emailRegex;
    //     const filteredEntries = uniqueEntries.filter(name => !this.CandidateDetails.includes(name) && regexToUse.test(name));
    //     if (filteredEntries.length > 0) {
    //       this.CandidateDetails = [...this.CandidateDetails, ...filteredEntries];
    //       this.newItem = '';
    //     }
    //     else {
    //       this.newItem = '';
    //     }
    //   }
    // }
    addItem() {
      var data = this.fromPreferenceType.toUpperCase();
      const entries = this.newItem.split(/[\s,]+/).map(entry => entry.trim()).filter(Boolean);
      if (entries.length > 0) {
        const uniqueEntries = [...new Set(entries)];
        const MobileRegex = data.includes("SMS");
        const regexToUse = MobileRegex ? this.mobileRegex : this.emailRegex;

        const filteredEntries = uniqueEntries.filter(name => {
          if (regexToUse.test(name)) {
            if (!this.CandidateDetails.includes(name)) {
              this.CandidateDetails.push(name);
            }
            this.invalidStatus = false;
            this.newItem = '';
            return false;
          } else {
            this.invalidInviteData.push(name);
            this.newItem = '';
            return true;
          }
        });

        if (filteredEntries.length > 0) {
          this.CandidateDetails = [...this.CandidateDetails, ...filteredEntries.filter(entry => regexToUse.test(entry))];
        }

        if (this.invalidStatus) {
          // this.invalidInviteData = [];
          return this.ShowWarningMessage("Please enter valid details ");
        }

      }

    }

    ,

    saveEdit(index) {
      var data = this.fromPreferenceType.toUpperCase();
      // const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,3})+$/;
      // const mobileRegex = /^\d{10}$/;
      const MobileRegex = data.includes("SMS");
      const regexToUse = MobileRegex ? this.mobileRegex : this.emailRegex;
      if (regexToUse.test(this.editedItem)) {
        if (this.verifiedDataTab == 'valid') {
          this.CandidateDetails[index - 1] = this.editedItem;
        } else {
          this.CandidateDetails.push(this.editedItem);
          this.invalidInviteData.splice(index - 1, 1);
          this.ShowSucessMessage("Candidate moved to valid data successfully");
        }
        this.cancelEdit();
      } else {
        this.ShowWarningMessage("Enter Valid Data");
      }
      if (this.invalidInviteData.length <= 0) {
        this.changetab("valid");
      }
    }
    ,
    async previewTemp() {
      var data = await this.GetInviteEmailTemplatePreview({
        testId: this.routetestId,
      });
      this.emailSubjectLine = data.object.data.subjectline;
      this.emailContentData = data.object.data.contentData;
      this.emailSubjectLineString = data.object.data.subjectLineString;
      this.emailContentDataString = data.object.data.contentDataString;

      // var x = temp.object.data.contentData;
      // console.log(temp);
      // console.log(x);
      // var p = document.querySelector(".preview-content");
      // p.innerHTML = x;
    },

    cancelEdit() {
      this.editingIndex = null;
      this.editedItem = '';
    },
    cancelEditTemplate() {
      this.editTemplateStatus = !this.editTemplateStatus;
    },
    editTemplateData() {

      if (this.editTemplateStatus == true) {
        this.UpdateInviteCandidate();
      }

      if (this.emailSubjectLine.length == 0) {
        this.previewTemp();
      }
      this.editTemplateStatus = !this.editTemplateStatus;

    },
    candidateChoosenPreference() {
      var data = this.fromPreferenceType.toUpperCase();
      if (data.includes("SMS")) {
        this.tablePreference.push("Mobile Number");
        this.GetInviteType.push("2");
      }
      if (data.includes("EMAIL")) {
        this.tablePreference.push("Email Address");
        this.GetInviteType.push("1");

      }
    },

    removeItem(index) {
      if (this.verifiedDataTab == "valid") {
        this.CandidateDetails.splice(index - 1, 1);
      }
      else {
        this.invalidInviteData.splice(index - 1, 1);
      }

      if (this.invalidInviteData.length <= 0) {
        this.changetab("valid");
      }
      this.cancelEdit();
    },

    removeAll() {
      this.CandidateDetails = [];
      this.invalidInviteData = [];
      this.cancelEdit();
    },

    removeAllInvalid() {
      this.invalidInviteData = [];
      this.cancelEdit();
      this.changetab("valid");
    },


    switchtoSenderDetails() {
      this.showSenderDetails = true;
      this.showCandidateDetails = false;
      this.showTemplateSelection = false;
    },

    switchtoCandidateDetails() {
      this.showSenderDetails = false;
      this.showCandidateDetails = true;
      this.showTemplateSelection = false;
    },

    switchtoSelectTemplate() {
      if (this.senderDetailsSubmit == true && this.candidateDetailsSubmit == true && this.CandidateDetails.length > 0) {
        this.showSenderDetails = false;
        this.showCandidateDetails = false;
        this.showTemplateSelection = true;
      }
    },

    openFileInput() {
      this.isFileInputDisabled = true;
      this.$refs.fileInput.click();
    },

    changetab(data) {
      this.verifiedDataTab = data;
    },

    async smsTemplate() {
      var data = await this.GetInviteSmsTemplate({
        clientId: this.user.clientId,
        messageType: 'CandidateInvite'
      });
      this.smsContent = data.object.data.messageContent;
      this.smsHeader = data.object.data.messageHeader;

      var company = await this.Companyheader({ clientId: this.user.clientId });
      this.companyName = company.object.data.companyName;


    },

    // async emailTemplate() {
    //   var data = await this.GetInviteEmailTemplate({
    //     testId: this.routetestId
    //   });
    //   this.emailSubjectLine = data.object.data.subjectline;
    //   this.emailContentData = data.object.data.contentData;
    // },

    encode(item) {
      return window.btoa(item);
    },

    UpdateInviteCandidate() {
      this.putInviteEmailTemplate({
        contentData: this.emailContentDataString,
        subjectLine: this.emailSubjectLineString,
        testId: this.routetestId
      });

    },
  },
};
</script>
  
<style scoped>
.col-sm-12 {
  margin-bottom: 5%;
}

.bulkButton {
  font-size: 13px;
  height: 40px;
  font-weight: 600;
  width: 100%;
}

.table-responsive {
  height: 100px;
  overflow-y: auto;
  display: block;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #f2f0ff;
  z-index: 2;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.QuillEditor-kj {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  /* margin-top: 45px; */
  border: 2px solid #d1d5db;
  border-radius: 10px;
}

.progresses {
  display: inline-block;
  align-items: center;
}

.line {
  width: 6px;
  height: 100px;
  background: #dbdbdb;
}

.line.line-success {
  background: #7b68ee;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 250px;
  height: 40px;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.steps {
  display: inline-flex;
  background-color: #dbdbdb;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}

.stepper-text {
  font-size: 13.2px;
  color: #7b818d;
  display: -webkit-inline-box;
  /* font-weight: 600; */
}

@media (max-width: 1200px) {
  .bulkButton {
    font-size: 8px;
    height: 30px;
    font-weight: 600;
  }
}

@media (max-width:400px) {

  .table-responsive tbody td {
    font-size: small;
  }

  .line {
    width: 50px;
    height: 6px;
    background: #dbdbdb;
  }

  .steps {
    display: flex;
    background-color: #dbdbdb;
    color: #fff;
    font-size: 10px;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

  }

  .stepper-text {
    font-size: 10px;
    color: #7b818d;
    /* font-weight: 600; */
  }
}

.card {
  height: 100%;
  border-radius: 25px;
}

.steps.step-success {
  background-color: #7b68ee;

}

.secondary-button1 {
  /* border-radius: 50px !important; */

  border: 2px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  /* margin: 0px 6px 0 0; */
  /* font-size:15px; */
}

.headerCenter {
  align-self: center;
}</style>
  