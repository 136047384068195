import {decryptData } from '@/platformFeatures/security';
import router from "../router";
import { getAuthUserData } from "@/utils/auth";
// import * as api from "@/api/api";



export function getEncryptedData(key) {
    let secretKey = key;
    let encryptedData = localStorage.getItem("featuresData");
    if (encryptedData) {
        let decryptedData = decryptData(encryptedData, secretKey);
        // if (Array.isArray(decryptedData) && decryptedData.length > 0) {
        //     let data = decryptedData;
        //     return data;
        // }
        return decryptedData;
    }
}

// export function validatePlanFeatures(key, id, name) {
//     let featureData = getEncryptedData(key);
//     let userAuth = getAuthUserData(); 
//     if (featureData && userAuth.isEnterprise==false) {
//         if (!featureData.some(item => item.featureId === id && item.featureName === name)) {
//             return false;
//         }
//         else{
//             return true;
//         }
//     }
//     else{
//         return true;
//     }
// }
export function validatePlanFeatures(key, moduleName, id, name) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData();
    if (featureData && userAuth.isEnterprise == false) {
        let module = featureData.modules.find(module => module.moduleName === moduleName);
        if (module) {
            if (module.features.some(item => item.featureId === id && item.featureName === name)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
}

export function validateTeamMembers(key, moduleName) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData();
    if (featureData && userAuth.isEnterprise == false) {
        let module = featureData.modules.find(module => module.moduleName === moduleName);
        if (module) {
            let feature = module.features.find(item => item.featureId === 26);
            return feature.value;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

export function AccessPlanFeatures(key, id, name) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData();
    if (featureData && userAuth.isEnterprise == false) {
        if (!featureData.some(item => item.featureId === id && item.featureName === name)) {
            router.push("/settings/plans");
        }
        else {
            return true;
        }
    }
    return true;
}


// export async function AccessPlans(userAuth) {
//     console.log("Aaya");

//     if (userAuth.isEnterprise == false) {
//         console.log("If loop");

//         const billingInfo = await api.GetBillingDetails({ clientId: userAuth.clientId });
//         console.log("billing Data",billingInfo);

//         localStorage.setItem("billingData", JSON.stringify(billingInfo.data));
        
//         const planCode = billingInfo.data.object.data.plan.planCode;
//         console.log("Plan Data",planCode);

//         const secretKey = userAuth.extId;
//         const updatedPlanData = this.$store.dispatch('fetchFeatures', planCode);
//         console.log("Updated Data",updatedPlanData);

//         const plandata = updatedPlanData.data.object.data;
//         console.log("Aaya aaya",plandata);
//         if (updatedPlanData.status === 200) {
//             const encryptedData = encryptData(plandata, secretKey);
//             localStorage.setItem("plan", planCode);
//             localStorage.setItem("featuresData", encryptedData);
//         }
//     }
//     else {
//         localStorage.removeItem("featuresData");
//         localStorage.removeItem("billingData");
//         localStorage.removeItem("plan");
//     }

// }