<template>
    <div v-if="!executeStatus" class="overlay">
        <div class="overlay-content">
            <img src="/dashboard-assets/images/descriptivePDFLoader.gif" alt="NOIMG" class="loader-image" />
            <div class="loading-text">
                Please Wait While We Are Generating PDF
            </div>
        </div>
    </div>
    <!-- Assessment Test Structure Start -->
    <div class="p-4" id="test-contruct-table">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Assessment Test Structure
        </div>
        <div>
            <div style="border: 1px solid #999999 ; border-radius: 25px;">
                <div class="table-responsive mb-4 p-3" style="text-align-last:center;">
                    <table class="table" style="font-size: 26px !important;">
                        <thead>
                            <tr>
                                <th style="font-size: 26px !important;">Test Section</th>
                                <th style="font-size: 26px !important;">Questions</th>
                                <th style="font-size: 26px !important;">Time Allocated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in this.testConstruct" :key="item">
                                <td> {{ item.sectionName }}</td>
                                <td>{{ item.noOfQuestion }}</td>
                                <td>
                                    {{ Math.round(convertTimeToMinutes(item.sectionTime)) }} min
                                </td>
                            </tr>
                            <tr style="background-color: aliceblue; font-weight: 600;">
                                <td>
                                    Total
                                </td>
                                <td>{{ totalQuestions }}</td>
                                <td>{{ totalDuration() }} min</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- Assessment Test Structure End -->

    <!-- Trends in Assessment Token Usage Start -->
    <div class="p-4" id="token-usage-graph">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Trends in Assessment Token Usage
        </div>
        <div class="mt-5" style="border: 1px solid #999999 ; border-radius: 25px;">
            <div class="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12 p-4">
                <div ref="lineChartContainer" class="chart-container w-100 mt-3"></div>
            </div>
            <div class="mt-2 p-4 graphSubHeading" v-if="this.hideLines === false">
                <ul>
                    <li class="mt-3" v-for="descriptorObj in descriptorsForMaxUsage" :key="descriptorObj">
                        <span style="font-weight: 600;">Peak Assessment Activity:</span> Between <span
                            style="font-weight: 600;">{{ displayDateTime(descriptorObj.from) }}</span> and <span
                            style="font-weight: 600;">{{ displayDateTime(descriptorObj.to) }}</span>, your team
                        conducted <span style="font-weight: 600;">{{ descriptorObj.assessmentCount }}</span>
                        assessments, marking the highest activity in this period. Review the strategies and campaigns
                        implemented during this time to replicate these successes and sustain high candidate engagement
                        in future periods.

                    </li>
                    <li class="mt-4" v-for="descriptorObj in descriptorsForMinUsage" :key="descriptorObj">
                        <span style="font-weight: 600;">Low Assessment Activity:</span> From <span
                            style="font-weight: 600;">{{ displayDateTime(descriptorObj.from) }}</span> to <span
                            style="font-weight: 600;">{{ displayDateTime(descriptorObj.to) }}</span> , assessments
                        dropped to a low of <span style="font-weight: 600;">{{ descriptorObj.assessmentCount }}</span>.
                        This could be due to less hiring activity or other external factors. Investigate these potential
                        causes and consider adjusting your engagement strategies to maintain a consistent assessment
                        flow.
                    </li>
                    <li class="mt-4">
                        <span style="font-weight: 600;">{{ usageTrendsMessage }}: </span> Your token usage shows varying
                        candidate engagement levels over recent <span style="font-weight: 600;">{{ usageTrendsText
                            }}</span>,
                        with notable peaks on specific dates. Analyze these trends to identify patterns and
                        anticipate future needs. Leveraging this data can help you optimize resource
                        allocation and improve the overall efficiency of your assessment process.
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Trends in Assessment Token Usage End -->

    <!-- Overall Result Analysis Start -->
    <div class="p-4" id="overall-result-analysis-graph" v-if="OverallLength">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Overall Result Analysis
        </div>
        <div class="mt-5 p-4" style="border: 1px solid #999999 ; border-radius: 25px;">
            <div class="mb-5">
                <div class="mb-2 p-4" v-if="this.highestPassPercent != 0 || this.lowestPassPercent != 0"
                    style="font-size: 40px;"> Key Insights:
                </div>
                <div class="mb-2 px-4 graphSubHeading" v-if="this.highestPassPercent != 0">
                    <i class="fa-solid fa-check" style="color: #7C68EE;"></i> <span class="mx-2"> High Pass Rate in
                    </span> <span style="font-weight: 600;">{{ this.highestRegionName }} </span>: <span
                        style="font-weight: 600;"> {{ this.highestPassPercent }} %</span>
                </div>
                <div class="mb-2 px-4 graphSubHeading" v-if="this.highestPassPercent == 0 || regionLength">
                    <i class="fa-solid fa-triangle-exclamation" style="color: #7C68EE;"></i> <span class="mx-2">
                        Improvement Needed in </span> <span style="font-weight: 600;">{{ this.lowestRegionName }}
                    </span>: <span style="font-weight: 600;">{{ this.lowestPassPercent }} % Pass Rate</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="stacked-column-chart" ref="chartOverallContainer" style="width: 100%;"></div>
                </div>
                <div class="col-12 graphSubHeading mt-3">
                    <ul>
                        <li class="mb-3">
                            <span style="font-weight:600;">Overall Performance:</span> In the given period, out of
                            <span style="font-weight: 600;">{{ totalCandidatesCount }}</span> candidates appearing for
                            this assessment, overall
                            <span style="font-weight: 600;">{{ totalPassPercents }}%</span>
                            (<span style="font-weight: 600;">{{ totalPassCounts }}</span> candidates) have cleared the
                            assessment.
                        </li>
                        <li class="mb-3 mt-3" v-if="highestPassCandidate != 0">
                            <span style="font-weight:600;">Top Performing Region:</span> Out of all the regions,
                            <span style="font-weight: 600;">{{ highestRegionName }}</span>
                            (<span style="font-weight: 600;">{{ highestTotalCount }}</span> candidates) has seen the
                            maximum throughput with a high passing count of
                            <span style="font-weight: 600;">{{ highestPassCandidate }}</span> candidates for the
                            selected time period.
                        </li>
                        <li class="mb-3 mt-3" v-if="regionLength">
                            <span style="font-weight:600;">Region with Challenges:</span> Reduced throughput is observed
                            in
                            <span style="font-weight: 600;">{{ lowestRegionName }}</span>
                            (<span style="font-weight: 600;">{{ lowestTotalCount }}</span> candidates) where only
                            <span style="font-weight: 600;">{{ lowestPassCandidate }}</span> candidates have passed the
                            assessment.
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <!-- Overall Result Analysis End -->

    <!-- Heatmap Language Start -->
    <div class="p-4">
        <div id="heatmap-lang-card">
            <div style="font-weight: 600; font-size: 48px;" class="mb-5">Score Analysis
            </div>
            <div style="border: 1px solid #999999 ; border-radius: 25px;">
                <div style="font-size: 40px;" class="mb-3 p-4" v-if="this.highestScore != 0 || this.lowestScore != 0">
                    Key Insights: </div>
                <div class="mb-3 p-4 graphSubHeading" v-if="this.highestScore != 0"> <i class="fa-solid fa-check"
                        style="color: #7C68EE;"></i> <span class="mx-2">High
                        Scores in </span> <span style="font-weight: 600;"> {{
                        highestRegion }} </span>
                    <span style="font-weight: 600;"> ({{ highestSection }} Section)</span>: <span
                        style="font-weight: 600;">{{
                        highestScore
                        }}</span>
                </div>
                <div class="mb-6 px-4 graphSubHeading"> <i class="fa-solid fa-triangle-exclamation"
                        style="color: #7C68EE;"></i> <span class="mx-2">Improvement Needed in</span><span
                        style="font-weight: 600;"> {{
                        lowestRegion }}
                    </span>
                    <span style="font-weight: 600; margin-left: 5px;">({{ lowestSection }} Section)</span>:
                    <span style="font-weight: 600;"> {{ lowestScore
                        }}</span>
                </div>

                <div class="heatmap-chart mt-5" ref="chartContainerSectionLangCompetencies"
                    style="width: 100%; max-width: 1500px;"></div>

                <div class="mt-6 p-4">
                    <ul class="graphSubHeading">
                        <li v-if="this.highestScore != 0" class="mb-4">
                            <span style="font-weight: 600;"> Top Performing Section: </span>The <span
                                style="font-weight: 600;">
                                {{ highestSection }}</span> section in <span style="font-weight: 600;"> {{
                                highestRegion }}</span>
                            had the highest average score of <span style="font-weight: 600;"> {{ highestScore
                                }}</span>. Leverage
                            successful training methods from this section to improve other areas.
                        </li>
                        <li>
                            <span style="font-weight: 600;"> Sections Needing Improvement: </span>The <span
                                style="font-weight: 600;">{{ lowestSection }}</span> section in <span
                                style="font-weight: 600;"> {{
                                lowestRegion }}</span> had the lowest average score of <span
                                style="font-weight: 600;">{{
                                lowestScore }}</span>. Conduct a detailed analysis to identify specific
                            challenges and develop
                            targeted interventions to address them.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="heatmap-psy-card" v-if="this.heatmapPsyStatus" class="mt-7"
            style="border: 1px solid #999999 ; border-radius: 25px;">
            <div style="font-size: 40px;" class="mb-3 p-4" v-if="this.psyHighestScore != 0 || this.psyLowestScore != 0">
                Key Insights:
            </div>
            <div class="mb-3 p-4 graphSubHeading" v-if="this.psyHighestScore != 0"> <i class="fa-solid fa-check"
                    style="color: #7C68EE;"></i><span class="mx-2">High
                    Scores in</span> <span style="font-weight: 600;"> {{
                    psyHighestRegion }}
                </span> <span style="font-weight: 600;">({{ psyHighestSection }} Section)</span> : <span
                    style="font-weight: 600;">
                    {{
                    psyHighestScore
                    }}
                </span> </div>
            <div class="mb-6 px-4 graphSubHeading"> <i class="fa-solid fa-triangle-exclamation"
                    style="color: #7C68EE;"></i><span class="mx-2">Improvement Needed in</span>
                <span style="font-weight: 600;"> {{ psyLowestRegion }}
                </span>
                <span style="font-weight: 600; margin-left: 5px;"> ({{ psyLowestSection }} Section)</span> :
                <span style="font-weight: 600;"> {{
                    psyLowestScore
                    }}</span>
            </div>

            <div class="heatmap-chart mt-5" ref="chartContainerPsyCompetencies" style="width: 1500px; height: 500px;">
            </div>

            <div class="mt-6 p-4">
                <ul class="graphSubHeading">
                    <li v-if="this.psyHighestScore != 0" class="mb-4">

                        <span style="font-weight: 600;"> Top Performing Section: </span>The <span
                            style="font-weight: 600;">
                            {{ psyHighestSection }}</span> section in <span style="font-weight: 600;"> {{
                            psyHighestRegion
                            }}</span>
                        had the highest average score of <span style="font-weight: 600;">{{ psyHighestScore
                            }}</span>.
                        Leverage
                        successful training methods from this section to improve other areas.
                    </li>
                    <li>
                        <span style="font-weight: 600;"> Sections Needing Improvement: </span>The <span
                            style="font-weight: 600;">{{ psyLowestSection }}</span> section in <span
                            style="font-weight: 600;">
                            {{
                            psyLowestRegion }}</span> had the lowest average score of <span style="font-weight: 600;">{{
                            psyLowestScore }}</span>. Conduct a detailed analysis to identify specific
                        challenges and develop
                        targeted interventions to address them.
                    </li>
                </ul>
            </div>
        </div>

    </div>
    <!-- Heatmap Language End -->

    <!-- Score Analysis Benchmark Start -->
    <div class="p-4" id="score-analysis-benchmark-graph">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Score Analysis (with
            Benchmark)
        </div>
        <div class="mt-5" style="border: 1px solid #999999 ; border-radius: 25px;">
            <div class="mt-5">
                <div class="row" style="align-items: center;">
                    <!-- <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6"> -->
                    <div class="col-12">
                        <div ref="cognitiveChart" class="chart-container w-100"></div>
                    </div>
                    <div class="col-12 mt-5">
                        <div ref="behaviouralChart" class="chart-container w-100"></div>
                    </div>
                    <!-- </div> -->
                    <div class="col-12 mt-5 mb-3">
                        <ul class="graphSubHeading" v-for="list in this.descriptors" :key="list">
                            <li>{{ list }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Score Analysis Benchmark End -->

    <!-- Attempt Analysis Start -->
    <div class="p-4" id="attempt-analysis-table" v-if="this.DescriptiveData.attemptAnalysis != null">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Attempt Analysis - {{ completionTypeLabel }}
            Completion
        </div>
        <div class="mt-5"
            style="border: 1px solid #999999 ; border-radius: 25px;">
            <div>
                <table class="table" style="font-size: 24px !important;">
                    <thead>
                        <tr>
                            <th
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Region Name</th>
                            <th
                                style="background-color: aliceblue; font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Overall {{ completionTypeLabel }} Completion (%)</th>
                            <th v-for="section in attemptAnalysisSections" :key="section"
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                {{ section }}(%)</th>
                        </tr>
                    </thead>
                    <tbody style="text-align-last:center;">
                        <tr v-for="region in attemptAnalysisData" :key="region.regionName">
                            <td style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                region.regionName }}</td>
                            <td
                                style="background-color: aliceblue; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                {{
                                Math.round(region.overallCompletion) }}</td>
                            <td v-for="section in attemptAnalysisSections" :key="section"
                                style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                Math.round(region.sectionCompletion[section]) ?? '-' }}</td>
                        </tr>
                    </tbody>
                </table>


            </div>
        </div>
    </div>
    <!-- Attempt Analysis End -->

    <!-- Test Time Spent Analysis Start -->
    <div style="font-weight: 600; font-size: 48px;" class="mb-5 mt-4" id="line-chart-data">Test Time Spent Analysis {{
        selectedLineChartType == 'By Sections' ? ' By Sections' : ' By Regions' }}
        <div class="mt-5" style="border: 1px solid #999999 ; border-radius: 25px; ">
            <div class="p-4">
                <div ref="chartLineContainer"></div>
            </div>
            <div class="mt-4 p-4" style="text-align-last:center;">

                <table class="table" style="font-size: 24px !important;">
                    <thead>
                        <tr>
                            <th
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Sections</th>
                            <th
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Overall (mins)</th>
                            <th
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Pass (mins)</th>
                            <th
                                style="font-size: 24px !important; max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">
                                Fail (mins)</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="section in lineTableData" :key="section.SectionsName">
                            <td style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                section.SectionsName }}</td>
                            <td style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                Math.round(section.OverallValue) }}</td>
                            <td style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                Math.round(section.PassCandidatesValue) }}</td>
                            <td style="max-width: 100px; white-space: normal; overflow: hidden; text-overflow: clip;">{{
                                Math.round(section.FailCandidatesValue) }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!-- Test Time Spent Analysis End -->

    <!-- Proctoring Graph Start -->
    <div class="p-4" id="proctoringGraph" v-if="this.proctorDataStatus">
        <div style="font-weight: 600; font-size: 48px;" class="mb-5">Proctoring Analysis
        </div>
        <div>
            <div style="border: 1px solid #999999 ; border-radius: 25px;">
                <div ref="chartProctorContainer" class="p-4"></div>
            </div>
        </div>
    </div>
    <!-- Proctoring Graph End -->
</template>

<script>
// import { mapGetters } from 'vuex';
// import { mapGetters, mapActions } from 'vuex';
import Highcharts from 'highcharts';
// import HighchartsHeatmap from 'highcharts/modules/heatmap';
import Base64Images from "../../../utils/base64Image.js";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
// HighchartsHeatmap(Highcharts);

export default {
    data() {
        return {
            heatmap: [],
            testConstruct: [],
            tokenUsageData: [],
            heatmapPsyStatus: false,
            overallSectionScore: [],
            overallLangScore: [],
            competencies: [],
            hasPsyCompetency: false,
            hasOtherCompetency: false,
            // graphShow: false,
            countData: 0,
            highestScore: 0,
            highestSection: '',
            highestRegion: '',
            lowestScore: '',
            lowestSection: '',
            lowestRegion: '',
            psyHighestScore: 0,
            psyHighestSection: '',
            psyHighestRegion: '',
            psyLowestScore: 0,
            psyLowestSection: '',
            psyLowestRegion: '',
            assessmentList: [],
            descriptorsForMinUsage: [],
            descriptorsForMaxUsage: [],
            hideLines: true,
            daysCount: 0,
            overallTestData: [],
            totalCandidatesCount: 0,
            totalPassCounts: 0,
            totalPassPercents: 0,
            highestPassPercent: 0,
            highestRegionName: '',
            lowestPassPercent: 0,
            lowestRegionName: '',
            highestPassCandidate: 0,
            lowestPassCandidate: 0,
            highestTotalCount: 0,
            lowestTotalCount: 0,
            regionLength: false,
            testData: [],
            descriptors: [],
            executeStatus: false,
            proctorDataStatus: true,
            OverallLength: false,
            selectedCompletionType: 'avgCompletion',
            attemptAnalysisData: [],
            attemptAnalysisSections: [],
            selectedLineChartType: 'By Sections',
            lineTableData:[],
            DescriptiveData : JSON.parse(localStorage.getItem('DescriptiveData')),
            overallCategories : []


        }
    },
    computed: {
        // ...mapGetters(['getHeatmapData', 'getTestConstruct', 'getTokenUsageData', 'getTokenUsageDaysData', 'getOverallResultAnalysisData', 'getScoreBenchmarkData', 'getProctorData', 'getAttemptAnalysis', 'getLineData']),
        totalQuestions() {
            return this.testConstruct.reduce((sum, item) => sum + item.noOfQuestion, 0);
        },

        usageTrendsMessage() {
            if (this.daysCount < 15) {
                return 'Weekly Token Usage Trends';
            } else if (this.daysCount < 30) {
                return 'BiWeekly Token Usage Trends';
            } else {
                return 'Monthly Token Usage Trends';
            }
        },

        usageTrendsText() {
            if (this.daysCount < 15) {
                return 'weeks';
            } else if (this.daysCount < 30) {
                return 'biweekly';
            } else {
                return 'month';
            }
        },

        // barChartClass() {
        //     console.log(this.overallCategories.length);
        //     return this.overallCategories.length < 3 ? 'col-12 col-xl-5 col-md-5 col-lg-5 col-sm-12' : 'col-12';
        // },
        descriptorClass() {
            console.log(this.overallCategories.length);
            return this.overallCategories.length < 3 ? 'col-12 col-lg-7 col-md-7 col-sm-12' : 'col-12 mt-5';
        },

        completionTypeLabel() {
            switch (this.selectedCompletionType) {
                case 'avgCompletion':
                    return 'Avg';
                case 'maxCompletion':
                    return 'Max';
                case 'minCompletion':
                    return 'Min';
                default:
                    return '';
            }
        },


    },
    async created() {
        this.selectedCompletionType = window.atob(this.$route.query.attemptStatus);
        this.selectedLineChartType = window.atob(this.$route.query.lineStatus);
    },
    async mounted() {

        this.testConstruct = this.DescriptiveData.testConstruct;

        await this.TokenUsageGraph();

        this.heatmap = this.DescriptiveData.setHeatmap;

        if (this.heatmap != null) {
            if (this.heatmap.psyCompetency != null) {
                this.heatmapPsyStatus = this.heatmap.psyCompetency.length > 0;
            }
        }

        if (this.DescriptiveData.overallResultAnalysis != null) {
            this.OverallLength = this.DescriptiveData.overallResultAnalysis.length > 0;

        }
       
        await this.SectionLanguageCompetencyData();
        await this.ScoreAnalysisBenchmark();


        if (this.heatmapPsyStatus) {
            await this.psyCompetencyData();
        }


        if (this.DescriptiveData.overallResultAnalysis != null) {
            await this.OverallResultAnalysis();
        }

        if (this.DescriptiveData.attemptAnalysis != null) {
            await this.transformedData(this.DescriptiveData.attemptAnalysis);
            await this.sections(this.DescriptiveData.attemptAnalysis);
        }
        if (this.DescriptiveData.lineData != null) {
            await this.createLineChart();
        }
        if (this.DescriptiveData.proctorData != null) {
            this.proctorDataStatus = this.DescriptiveData.proctorData.tests.some(
                (test) =>
                    test.regions.some((region) =>
                        region.sections.some((section) => section.proctoringCount > 0)
                    )
            );
        }
        else {
            this.proctorDataStatus = false;
        }


        if (this.proctorDataStatus) {
            await this.ProctoringData();
        }
        // else {
        //     this.proctorDataStatus = false;
        // }

        await this.downloadPDF();

    },
    methods: {
        // ...mapActions(['resetState']),
       
    // roundToTwoDecimalPlaces(value) {
    //     return parseFloat(value).toFixed(2);
    // },
        async createLineChart() {
            var regions = {};
            var data = this.DescriptiveData.lineData;
            var categories = [];
            var overAllTimeSpendData = [];
            var passTimeSpendData = [];
            var failTimeSpendData = [];
            var sections = {};
            var title = '';
            this.lineTableData = [];
            if (this.selectedLineChartType == 'By Regions') {
                data.tests.forEach(test => {
                    test.regions.forEach(region => {
                        if (!regions[region.regionName]) {
                            regions[region.regionName] = {
                                overAllTimeSpend: 0,
                                passTimeSpend: 0,
                                failTimeSpend: 0,
                                count: 0
                            };
                        }

                        region.sections.forEach(section => {
                            regions[region.regionName].overAllTimeSpend += section.overAllTimeSpend;
                            regions[region.regionName].passTimeSpend += section.passTimeSpend;
                            regions[region.regionName].failTimeSpend += section.failTimeSpend;
                            regions[region.regionName].count += 1;
                        });
                    });
                });
                categories = Object.keys(regions);

                categories.forEach(regionName => {
                    const region = regions[regionName];
                    overAllTimeSpendData.push(region.overAllTimeSpend);
                    passTimeSpendData.push(region.passTimeSpend);
                    failTimeSpendData.push(region.failTimeSpend);
                    
                this.lineTableData.push({
                    SectionsName: regionName,
                    OverallValue: regions.overAllTimeSpend,
                    PassCandidatesValue: regions.passTimeSpend,
                    FailCandidatesValue: regions.failTimeSpend
                });
                });

                title = "Regions";
            }

            if (this.selectedLineChartType == 'By Sections') {
                data.tests.forEach(test => {
                    test.regions.forEach(region => {
                        region.sections.forEach(section => {
                            if (!sections[section.sectionName]) {
                                sections[section.sectionName] = {
                                    overAllTimeSpend: 0,
                                    passTimeSpend: 0,
                                    failTimeSpend: 0,
                                    count: 0
                                };
                            }
                            sections[section.sectionName].overAllTimeSpend += section.overAllTimeSpend;
                            sections[section.sectionName].passTimeSpend += section.passTimeSpend;
                            sections[section.sectionName].failTimeSpend += section.failTimeSpend;
                            sections[section.sectionName].count += 1;
                        });
                    });
                });

                categories = Object.keys(sections);

                categories.forEach(sectionName => {
                    const section = sections[sectionName];
                    overAllTimeSpendData.push(section.overAllTimeSpend);
                    passTimeSpendData.push(section.passTimeSpend);
                    failTimeSpendData.push(section.failTimeSpend);
                    
                this.lineTableData.push({
                    SectionsName: sectionName,
                    OverallValue: section.overAllTimeSpend,
                    PassCandidatesValue: section.passTimeSpend,
                    FailCandidatesValue: section.failTimeSpend
                });
                });
                title = "Sections";

            }

            Highcharts.chart(this.$refs.chartLineContainer, {
                chart: {
                    type: 'line',
                    height: 700,
                },
                title: {
                    text: 'Time Spent by Region',
                    style: {
                        fontWeight: "600",
                        display: 'none',
                    }
                },
                xAxis: {
                    categories,
                    title: {
                        text: title,
                        style: {
                            fontSize: '32px',
                            color: '#333333'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '20px',
                            color: '#333333'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Time Spent (mins)',
                        style: {
                            fontSize: '32px',
                            color: '#333333'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '20px',
                            color: '#333333'
                        }
                    }
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: '#FFFFFF',
                    shadow: false,
                    itemStyle: {
                        fontSize: '24px'
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y} mins <br/> '
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: 'Overall',
                        data: overAllTimeSpendData,
                        color: '#F8B200'
                    },
                    {
                        name: 'Pass Candidates',
                        data: passTimeSpendData,
                        color: '#7C68EE'
                    },
                    {
                        name: 'Fail Candidates',
                        data: failTimeSpendData,
                        color: '#FF90C0'
                    }
                ]
            });
        },

        async transformedData(data) {
            const regions = data.tests[0].regions;
            this.attemptAnalysisData = regions.map(region => {
                const overallCompletion = region.sections.reduce((sum, section) => sum + section[this.selectedCompletionType], 0) / region.sections.length;
                const sectionCompletion = {};
                region.sections.forEach(section => {
                    sectionCompletion[section.sectionName] = section[this.selectedCompletionType];
                });
                return {
                    regionName: region.regionName,
                    overallCompletion,
                    sectionCompletion
                };
            });
        },
        async sections(data) {
            const regions = data.tests[0].regions;
            const sectionNames = new Set();
            regions.forEach(region => {
                region.sections.forEach(section => {
                    sectionNames.add(section.sectionName);
                });
            });
            this.attemptAnalysisSections = Array.from(sectionNames);
        },
        convertTimeToMinutes(timeString) {
            const duration = moment.duration(timeString);
            return duration.asMinutes();
        },
        displayDateTime: (val) => {
            return moment(String(val)).format("DD MMM YYYY");
        },
        totalDuration() {
            return Math.round(this.testConstruct.reduce((sum, item) => sum + this.convertTimeToMinutes(item.sectionTime), 0));
        },
        async ProctoringData() {
    const regions = this.DescriptiveData.proctorData.tests[0].regions;
    const categories = regions.map(region => region.regionName);
    const seriesData = {};

    regions.forEach(region => {
        region.sections.forEach(section => {
            if (!seriesData[section.proctoringFitment]) {
                seriesData[section.proctoringFitment] = [];
            }
            seriesData[section.proctoringFitment].push(section.proctoringCount);
        });
    });

    const colors = ['#7C68EE', '#F8B200', '#FF90C0'];
    const series = Object.keys(seriesData).map((fitment, index) => {
        // const isPassOrFail = fitment.toLowerCase().includes('pass') || fitment.toLowerCase().includes('fail');
        return {
            name: fitment,
            data: seriesData[fitment],
            color: colors[index % colors.length],
            dataLabels: {
                enabled: true,
                style: {
                    color: 'white', 
                    fontSize: '28px'
                }
            }
        };
    });

    Highcharts.chart(this.$refs.chartProctorContainer, {
        chart: {
            type: 'column',
            height: 700
        },
        title: {
            text: 'Proctoring Analysis',
            style: {
                fontWeight: "600",
                display: 'none',
            }
        },
        xAxis: {
            categories: categories,
            title: {
                text: 'Regions',
                style: {
                    fontSize: '32px',
                    fontWeight:'600',
                    color: '#333333'
                }
            },
            labels: {
                style: {
                    fontSize: '20px',
                    color: '#333333'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'No. of candidates',
                style: {
                    fontWeight: "600",
                    fontSize: '32px',
                    color: '#333333'
                }
            },
            labels: {
                style: {
                    fontSize: '20px',
                    color: '#333333'
                }
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    fontSize: '28px',
                    color: (
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            backgroundColor: '#FFFFFF',
            shadow: false,
            itemStyle: {
                fontSize: '24px' 
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '28px'
                    }
                }
            }
        },
        series: series
    });
}

,

        // OverallResultAnalysis() {
        //     this.overallTestData = this.DescriptiveData.overallResultAnalysis[0];
        //     this.overallCategories = this.overallTestData.regionWise.map(region => region.regionName);

        //     const regions = this.overallTestData.regionWise;
        //     this.regionLength = regions.length > 1;

        //     // Description of Graphs Starts //
        //     const totalCount = regions.map(region => region.totalCandidates);

        //     const totalPassCount = regions.map(region =>
        //         region.overAllResult.passedCandidates
        //     );

        //     this.totalCandidatesCount = totalCount.reduce((a, b) => a + b, 0);
        //     this.totalPassCounts = totalPassCount.reduce((a, b) => a + b, 0);


        //     this.totalPassPercents = Math.round(this.totalPassCounts / this.totalCandidatesCount * 100);

        //     let highestRegion = regions[0];
        //     let lowestRegion = regions[0];

        //     for (let i = 1; i < regions.length; i++) {
        //         if (regions[i].overAllResult.passPercent > highestRegion.overAllResult.passPercent) {
        //             highestRegion = regions[i];
        //         }
        //         if (regions[i].overAllResult.passPercent < lowestRegion.overAllResult.passPercent) {
        //             lowestRegion = regions[i];
        //         }
        //     }

        //     this.highestPassPercent = highestRegion.overAllResult.passPercent;
        //     this.highestPassCandidate = highestRegion.overAllResult.passedCandidates;
        //     this.highestTotalCount = highestRegion.totalCandidates;
        //     this.highestRegionName = highestRegion.regionName;


        //     if (regions.length > 1) {
        //         this.lowestPassPercent = lowestRegion.overAllResult.passPercent;
        //         this.lowestPassCandidate = lowestRegion.overAllResult.passedCandidates;
        //         this.lowestRegionName = lowestRegion.regionName;
        //         this.lowestTotalCount = lowestRegion.totalCandidates;
        //     }

        //     // Description of Graphs Ends //


        //     // this.overallCategories = regions.map(region => region.regionName);
        //     const series = [
        //         {
        //             name: 'Passed Candidates',
        //             data: regions.map(region => ({
        //                 y: region.overAllResult.passedCandidates,
        //                 passPercent: region.overAllResult.passPercent
        //             })),
        //             stack: 'overall',
        //             color: '#7C68EE'
        //         },
        //         {
        //             name: 'Failed Candidates',
        //             data: regions.map(region => ({
        //                 y: region.overAllResult.failedCandidates,
        //                 failPercent: region.overAllResult.failPercent
        //             })),
        //             stack: 'overall',
        //             color: '#FF90C0'

        //         }
        //     ];

        //     Highcharts.chart(this.$refs.chartOverallContainer, {
        //         chart: {
        //             type: 'column',
        //             height: 600,
        //         },
        //         title: {
        //             text: 'Overall Fitment Analysis',
        //             style: {
        //                 fontWeight: "600",
        //                 display: 'none',
        //             }
        //         },
        //         xAxis: {
        //             categories: this.overallCategories,
        //             title: {
        //                 text: 'Regions',
        //                 style: {
        //                     fontWeight: "600",
        //                     fontSize: '32px',
        //                     color: '#333333'
        //                 }
        //             },
        //             labels: {
        //                 style: {
        //                     fontSize: '20px',
        //                     color: '#333333'
        //                 }
        //             }
        //         },
        //         yAxis: {
        //             min: 0,
        //             title: {
        //                 text: 'No. of candidates',
        //                 style: {
        //                     fontWeight: "600",
        //                     fontSize: '32px',
        //                     color: '#333333'
        //                 }
        //             },
        //             labels: {
        //                 style: {
        //                     fontSize: '20px',
        //                     color: '#333333'
        //                 }
        //             },
        //             stackLabels: {
        //                 enabled: true,
        //                 style: {
        //                     fontWeight: 'bold',
        //                     fontSize: '28px',
        //                     color: (
        //                         Highcharts.defaultOptions.title.style &&
        //                         Highcharts.defaultOptions.title.style.color
        //                     ) || 'gray'
        //                 }
        //             }
        //         },
        //         legend: {
        //             align: 'center',
        //             verticalAlign: 'bottom',
        //             backgroundColor: '#FFFFFF',
        //             shadow: false,
        //             itemStyle: {
        //                 fontSize: '24px',
        //                 fontWeight: 'bold'
        //             }
        //         },
        //         credits: {
        //             enabled: false
        //         },
        //         tooltip: {
        //             headerFormat: '<b>{point.x}</b><br/>',
        //             pointFormatter: function () {
        //                 if (this.series.name === 'Passed Candidates') {
        //                     return this.series.name + ': ' + this.y + '<br/>Pass Percent: ' + this.passPercent + '%<br/>Total: ' + this.stackTotal;
        //                 } else if (this.series.name === 'Failed Candidates') {
        //                     return this.series.name + ': ' + this.y + '<br/>Fail Percent: ' + this.failPercent + '%<br/>Total: ' + this.stackTotal;
        //                 }
        //                 return '';
        //             }
        //         },
        //         plotOptions: {
        //             column: {
        //                 stacking: 'normal',
        //                 dataLabels: {
        //                     enabled: true,
        //                     style: {
        //                         fontSize: '28px',
        //                         color: '#FFFFFF'
        //                     }
        //                 }
        //             }
        //         },
        //         series: series
        //     });

        // },
        OverallResultAnalysis() {
        this.overallTestData = this.DescriptiveData.overallResultAnalysis[0];
        this.overallCategories = this.overallTestData.regionWise.map(region => region.regionName);

        const regions = this.overallTestData.regionWise;
        this.regionLength = regions.length > 1;

        // Description of Graphs Starts //
        const totalCount = regions.map(region => region.totalCandidates);

        const totalPassCount = regions.map(region => region.overAllResult.passedCandidates);

        this.totalCandidatesCount = totalCount.reduce((a, b) => a + b, 0);
        this.totalPassCounts = totalPassCount.reduce((a, b) => a + b, 0);
        this.totalPassPercents = Math.round((this.totalPassCounts / this.totalCandidatesCount) * 100);

        let highestRegion = regions[0];
        let lowestRegion = regions[0];

        for (let i = 1; i < regions.length; i++) {
            if (regions[i].overAllResult.passPercent > highestRegion.overAllResult.passPercent) {
                highestRegion = regions[i];
            }
            if (regions[i].overAllResult.passPercent < lowestRegion.overAllResult.passPercent) {
                lowestRegion = regions[i];
            }
        }

        this.highestPassPercent = highestRegion.overAllResult.passPercent;
        this.highestPassCandidate = highestRegion.overAllResult.passedCandidates;
        this.highestTotalCount = highestRegion.totalCandidates;
        this.highestRegionName = highestRegion.regionName;

        if (regions.length > 1) {
            this.lowestPassPercent = lowestRegion.overAllResult.passPercent;
            this.lowestPassCandidate = lowestRegion.overAllResult.passedCandidates;
            this.lowestRegionName = lowestRegion.regionName;
            this.lowestTotalCount = lowestRegion.totalCandidates;
        }

        // Description of Graphs Ends //

        const series = [
            {
                name: 'Passed Candidates',
                data: regions.map(region => ({
                    y: region.overAllResult.passedCandidates,
                    passPercent: region.overAllResult.passPercent
                })),
                stack: 'overall',
                color: '#7C68EE'
            },
            {
                name: 'Failed Candidates',
                data: regions.map(region => ({
                    y: region.overAllResult.failedCandidates,
                    failPercent: region.overAllResult.failPercent
                })),
                stack: 'overall',
                color: '#FF90C0'
            }
        ];

        Highcharts.chart(this.$refs.chartOverallContainer, {
            chart: {
                type: 'column',
                height: 600,
            },
            title: {
                text: 'Overall Fitment Analysis',
                style: {
                    fontWeight: "600",
                    display: 'none',
                }
            },
            xAxis: {
                categories: this.overallCategories,
                title: {
                    text: 'Regions',
                    style: {
                        fontWeight: "600",
                        fontSize: '32px',
                        color: '#333333'
                    }
                },
                labels: {
                    style: {
                        fontSize: '20px',
                        color: '#333333'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'No. of candidates',
                    style: {
                        fontWeight: "600",
                        fontSize: '32px',
                        color: '#333333'
                    }
                },
                labels: {
                    style: {
                        fontSize: '20px',
                        color: '#333333'
                    }
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '28px',
                        color: (
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                backgroundColor: '#FFFFFF',
                shadow: false,
                itemStyle: {
                    fontSize: '24px',
                    fontWeight: 'bold'
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormatter: function () {
                    if (this.series.name === 'Passed Candidates') {
                        return this.series.name + ': ' + this.y + '<br/>Pass Percent: ' + this.passPercent + '%<br/>Total: ' + this.stackTotal;
                    } else if (this.series.name === 'Failed Candidates') {
                        return this.series.name + ': ' + this.y + '<br/>Fail Percent: ' + this.failPercent + '%<br/>Total: ' + this.stackTotal;
                    }
                    return '';
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '28px',
                            color: '#FFFFFF'
                        }
                    }
                }
            },
            series: series
        });
    },
        ScoreAnalysisBenchmark() {
            this.testData = this.DescriptiveData.scoreBenchmark.testList[0];
            const traitName = this.testData.psyResults.map(data => data.traitName);
            const BehaviouraMinScore = this.testData.psyResults.map(data => data.avgFailScore);
            const BehaviouraMaxScore = this.testData.psyResults.map(data => data.avgPassScore);
            const CognitiveMinScore = this.testData.results.map(data => data.avgFailScore);
            const CognitiveMaxScore = this.testData.results.map(data => data.avgPassScore);
            const CognitiveBenchmark = this.testData.results.map(data => data.benchmark);
            const CognitiveSectionName = this.testData.results.map(data => data.sectionName);
            this.TestName = this.testData.testName.toUpperCase();
            this.descriptors = this.testData.descriptors;

            if (BehaviouraMaxScore.length > 0 || BehaviouraMinScore.length > 0) {
                // var BehaviouralChartOptions = {
                //     chart: {
                //         type: 'column'
                //     },
                //     title: {
                //         text: 'Behavioural Competency',
                //         style: {
                //                 fontWeight: "600",
                //                 fontSize: '32px',
                //                 color: '#333333'
                //             }
                //     },
                //     xAxis: {
                //         categories: traitName,
                //         title: {
                //             text: 'Sections',
                //             style: {
                //                 fontWeight: "600"
                //             }
                //         },
                //     },
                //     yAxis: {
                //         min: 0,
                //         title: {
                //             text: 'Average % Score',
                //             style: {
                //                 fontWeight: "600"
                //             }
                //         }
                //     },
                //     credits: {
                //         enabled: false
                //     },
                //     series: [{
                //         name: 'Pass',
                //         data: BehaviouraMaxScore,
                //         color: '#7C68EE'
                //     }, {
                //         name: 'Fail',
                //         data: BehaviouraMinScore,
                //         color: '#FF90C0'
                //     }]
                // };
           
                var BehaviouralChartOptions = {
                    chart: {
                        type: 'column',
                        animation: false,
                        height: 600,
                    },
                    title: {
                        text: 'Behavioural Competency',
                        style: {
                            fontWeight: "600",
                            fontSize: '32px',
                            color: '#333333'
                        }
                    },
                    xAxis: {
                        categories: traitName,
                        title: {
                            text: 'Sections',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            },
                            // rotation: traitName.length > 5 ? -45 : 0, 
                            rotation: -45, 
                            align: 'right'
                        },
                        
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '% Score',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            },
                            
                        },
                        
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                useHTML: true, 
                                align: 'center',
                                verticalAlign: 'top',
                                inside: true,
                                formatter: function () {
                                    const y = this.y;
                                    return `
                        <div style="position: relative; text-align: center;">
                            <div style="background-color:#ffffff; color: #333333; padding: 5px; border-radius: 32px; font-size: 20px; border:1px solid #999999;">
                                ${Highcharts.numberFormat(y, 0)}
                            </div>
                        </div>`;
                                }
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#333333'
                        }
                    },
                    series: [{
                        name: 'Pass',
                        data: BehaviouraMaxScore,
                        color: '#7C68EE'
                    }, {
                        name: 'Fail',
                        data: BehaviouraMinScore,
                        color: '#FF90C0'
                    }]
                };

            }

            if (CognitiveMinScore.length > 0 || CognitiveMaxScore.length > 0) {
                var CongitiveChartOptions = {
                    chart: {
                        type: 'column',
                        height: 600,
                    },
                    title: {
                        text: 'Test Sections',
                        style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                    },
                    xAxis: {
                        categories: CognitiveSectionName,
                        title: {
                            text: 'Sections',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            },
                            // rotation: CognitiveSectionName.length > 5 ? -45 : 0, 
                            rotation: -45, 
                            align: 'right'
                        },
                    },
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#333333'
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                useHTML: true, 
                                align: 'center',
                                verticalAlign: 'top',
                                inside: true,
                                formatter: function () {
                                    const y = this.y;
                                    return `
                        <div style="position: relative; text-align: center;">
                            <div style="background-color:#ffffff; color: #333333; padding: 5px; border-radius: 32px; font-size: 20px; border:1px solid #999999;">
                                ${Highcharts.numberFormat(y, 0)}
                            </div>
                        </div>`;
                                }
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '% Score',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            }

                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Pass',
                        data: CognitiveMaxScore,
                        color: '#7C68EE'

                    }, {
                        name: 'Fail',
                        data: CognitiveMinScore,
                        color: '#FF90C0'

                    }, {
                        name: 'Benchmark',
                        type: 'line',
                        data: CognitiveBenchmark,
                        marker: {
                            enabled: true,
                            symbol: 'circle'
                        },
                        color: 'green'
                    }]
                };
            }
            if (BehaviouraMaxScore.length > 0 || BehaviouraMinScore.length > 0) {
                Highcharts.chart(this.$refs.behaviouralChart, BehaviouralChartOptions);
            }
            if (CognitiveMinScore.length > 0 || CognitiveMaxScore.length > 0) {

                Highcharts.chart(this.$refs.cognitiveChart, CongitiveChartOptions);
            }
        },
        TokenUsageGraph() {
            this.daysCount = this.DescriptiveData.tokenUsage;
            const data = this.DescriptiveData.tokenUsage.map(item => {
                const date = new Date(item.to);
                const formattedDate = date.toISOString().split('T')[0];
                return [formattedDate, item.assessmentCount];
            });
            data.forEach(function (point) {
                point[0] = Date.parse(point[0])
            });
            this.assessmentList = data;
            const assessmentCountList = this.DescriptiveData.tokenUsage.map(i => i.assessmentCount);
            this.hideLines = assessmentCountList.every((count) => {
                return count === 0
            });
            const minAssessmentCount = Math.min(...assessmentCountList.slice(1));
            const maxAssessmentCount = Math.max(...assessmentCountList);
            this.descriptorsForMaxUsage = this.DescriptiveData.tokenUsage.filter(element => element.assessmentCount == maxAssessmentCount);
            this.descriptorsForMinUsage = this.DescriptiveData.tokenUsage.slice(1).filter(element => element.assessmentCount == minAssessmentCount);
            
            Highcharts.chart(this.$refs.lineChartContainer, {
    chart: {
        type: 'area',
        animation: false
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    title: {
        text: this.hideLines === true ? 'No data found for selected parameters' : ''
    },
    yAxis: {
        min: 0,
        title: {
            text: 'No. of Candidates',
            style: {
                fontWeight: "600",
                fontSize: '32px',
                color: '#333333'
            }
        },
        labels: {
            style: {
                fontSize: '20px',
                color: '#000000'
            }
        }
    },
    tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: 'RGB(255,255,255)',
        borderColor: '#7B68EE',
        borderRadius: 5,
        style: {
            color: '#333333',
            fontFamily: 'Helvetica, sans-serif',
            fontSize: '18px',
            fontWeight: 'normal',
            textShadow: 'none'
        },
        // formatter: function() {
        //     return '<strong>' + this.series.name + '</strong>' +
        //            '<br/>Group: <strong>' + Highcharts.dateFormat('%d %b %Y', this.x) + '</strong>' +
        //            '<br/>Value: <strong>' + Highcharts.numberFormat(this.y, 0) + '</strong>';
        // }
        formatter: function() {
            return '<strong>' + Highcharts.numberFormat(this.y, 0) + '</strong>';
        }
    },
    xAxis: {
        type: 'datetime',
        title: {
            text: 'Time Interval',
            style: {
                fontWeight: "600",
                fontSize: '32px',
                color: '#333333'
            }
        },
        labels: {
            formatter: function () {
                return Highcharts.dateFormat('%d %b %Y', this.value);
            },
            style: {
                fontSize: '20px',
                color: '#000000'
            }
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            animation: false,
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: null
            },
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, '#7B68EE'],
                    [1, Highcharts.color('#7B68EE').setOpacity(0).get('rgba')]
                ]
            },
            dataLabels: {
                enabled: true,
                allowOverlap: true,
                y: -20,
                // formatter: function () {
                //     return '<strong>' + this.series.name + '</strong>' +
                //         '<br/>Group: <strong>' + Highcharts.dateFormat('%d %b %Y', this.x) + '</strong>' +
                //         '<br/>Value: <strong>' + Highcharts.numberFormat(this.y, 0) + '</strong>';
                // },
                formatter: function () {
                    return '<strong>' + Highcharts.numberFormat(this.y, 0) + '</strong>';
                },
                backgroundColor: 'RGB(255,255,255)',
                borderColor: '#7B68EE',
                borderWidth: 0.5,
                borderRadius: 5,
                style: {
                    color: '#333333',
                    fontFamily: 'Helvetica, sans-serif',
                    fontSize: '24px',
                    fontWeight: 'normal',
                    textShadow: 'none'
                }
            }
        }
    },
    series: [{
        name: 'Candidates(N)',
        data: this.hideLines === true ? [] : this.assessmentList,
        color: "#7B68EE"
    }],
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }
});


        },
        SectionLanguageCompetencyData() {
            const data = this.heatmap;
            var graphTitle = '';
            this.hasOtherCompetency = data.sectionCompetency.length > 0 || data.languageCompetency.length > 0;

            if (data.languageCompetency.length < 0) {
                graphTitle = 'Test Sections';
            }
            else {
                graphTitle = 'Test & Language Sections';
            }
            let xAxisCategories = new Set();
            let yAxisCategories = new Set();
            let seriesData = [];
            if (this.hasOtherCompetency) {
                this.processCompetency = (competencies) => {
                    competencies.forEach(section => {
                        section.regions.forEach(region => {
                            region.competencyScores.forEach(score => {
                                xAxisCategories.add(score.competencyName);
                                yAxisCategories.add(region.regionName);
                                seriesData.push([score.competencyName, region.regionName, score.overallScore]);
                            });
                        });
                    });
                };
            }


            const competencies = [data.sectionCompetency, data.languageCompetency];
            competencies.forEach(competency => {
                if (competency.length > 0) {
                    this.processCompetency(competency);
                }
            });

            xAxisCategories = Array.from(xAxisCategories);
            yAxisCategories = Array.from(yAxisCategories);

            let highScore = -Infinity;
            let highSection = "";
            let highRegion = "";
            let lowScore = Infinity;
            let lowSection = "";
            let lowRegion = "";

            for (let i = 0; i < seriesData.length; i++) {
                let section = seriesData[i][0];
                let region = seriesData[i][1];
                let score = seriesData[i][2];

                if (score > highScore) {
                    highScore = score;
                    highSection = section;
                    highRegion = region;
                }

                if (score < lowScore) {
                    lowScore = score;
                    lowSection = section;
                    lowRegion = region;
                }
            }

            this.highestScore = highScore;
            this.highestSection = highSection;
            this.highestRegion = highRegion;
            this.lowestScore = lowScore;
            this.lowestSection = lowSection;
            this.lowestRegion = lowRegion;

            seriesData = seriesData.map(item => [
                xAxisCategories.indexOf(item[0]),
                yAxisCategories.indexOf(item[1]),
                item[2]
            ]);

            const fontSize = seriesData.length > 12 ? '20px' : '28px';

            if (this.hasOtherCompetency) {
                Highcharts.chart(this.$refs.chartContainerSectionLangCompetencies, {
                    chart: {
                        type: 'heatmap',
                        spacing: [0, 0, 0, 0],
                        width: 1450,
                    },
                    title: {
                        text: graphTitle,
                        style: {
                            fontWeight: "600",
                            fontSize: '32px'
                        }
                    },
                    xAxis: {
                        categories: xAxisCategories,
                        title: {
                            text: 'Sections',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            },
                            // rotation: xAxisCategories.length > 5 ? -45 : 0, 
                            rotation: -45, 
                            align: 'right'
                        }
                    },
                    yAxis: {
                        categories: yAxisCategories,
                        title: {
                            text: 'Regions',
                            style: {
                                fontWeight: "600",
                                fontSize: '32px',
                                color: '#333333',
                                padding: '5px'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '20px',
                                color: '#000000'
                            }
                        }
                    },
                    colorAxis: {
                min: 0,
                minColor: '#FF90C0', // Purple
                maxColor: '#4b2eee',  // Pink
                labels: {
                    style: {
                        fontSize: '24px', 
                        color: '#333333'
                    }
                }
            },
                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 40,
                        symbolHeight: 350,
                        itemStyle: {
                            fontSize: '28px',
                            color: '#333333'
                        },
                        title: {
                            style: {
                                fontSize: '24px',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        borderWidth: 1,
                        data: seriesData,
                        dataLabels: {
                            enabled: true,
                            color: '#FFFFFF',
                            style: {
                                fontSize: fontSize
                            }
                        }
                    }],
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> scored <br><b>' +
                                this.point.value + '</b> in <br><b>' +
                                this.series.yAxis.categories[this.point.y] + '</b>';
                        }
                    }
                });
            }
        },
        psyCompetencyData() {
    const data = this.heatmap;
    this.hasPsyCompetency = data.psyCompetency.length > 0;
    let psyXAxisCategories = new Set();
    let psyYAxisCategories = new Set();
    let psySeriesData = [];

    this.processPsyCompetency = (competencies) => {
        competencies.forEach(section => {
            section.regions.forEach(region => {
                region.competencyScores.forEach(score => {
                    psyXAxisCategories.add(score.competencyName);
                    psyYAxisCategories.add(region.regionName);
                    psySeriesData.push([score.competencyName, region.regionName, score.overallScore]);
                });
            });
        });
    };

    this.processPsyCompetency(data.psyCompetency);

    psyXAxisCategories = Array.from(psyXAxisCategories);
    psyYAxisCategories = Array.from(psyYAxisCategories);

    let psyHighScore = -Infinity;
    let psyHighSection = "";
    let psyHighRegion = "";
    let psyLowScore = Infinity;
    let psyLowSection = "";
    let psyLowRegion = "";

    for (let i = 0; i < psySeriesData.length; i++) {
        let section = psySeriesData[i][0];
        let region = psySeriesData[i][1];
        let score = psySeriesData[i][2];

        if (score > psyHighScore) {
            psyHighScore = score;
            psyHighSection = section;
            psyHighRegion = region;
        }

        if (score < psyLowScore) {
            psyLowScore = score;
            psyLowSection = section;
            psyLowRegion = region;
        }
    }

    this.psyHighestScore = psyHighScore;
    this.psyHighestSection = psyHighSection;
    this.psyHighestRegion = psyHighRegion;
    this.psyLowestScore = psyLowScore;
    this.psyLowestSection = psyLowSection;
    this.psyLowestRegion = psyLowRegion;

    psySeriesData = psySeriesData.map(item => [
        psyXAxisCategories.indexOf(item[0]),
        psyYAxisCategories.indexOf(item[1]),
        item[2]
    ]);

    const fontSize = psySeriesData.length > 12 ? '20px' : '28px';

    if (this.hasPsyCompetency) {
        // const minCellHeight = 75;
        // const chartHeight = psyYAxisCategories.length * minCellHeight;
        Highcharts.chart(this.$refs.chartContainerPsyCompetencies, {
            chart: {
                type: 'heatmap',
                spacing: [0, 0, 0, 0],
                width: 1450,
                // height: chartHeight,

                // height: 400,
            },
            title: {
                text: 'Behavioural Competency',
                style: {
                    fontWeight: "600",
                    fontSize: '32px'
                }
            },
            xAxis: {
                categories: psyXAxisCategories,
                title: {
                    text: 'Sections',
                    style: {
                        fontWeight: "600",
                        fontSize: '32px',
                        color: '#333333'
                    },
                },
                labels: {
                    style: {
                        fontSize: '20px',
                        color: '#333333'
                    },
                        // rotation: psyXAxisCategories.length > 5 ? -45 : 0, 
                        rotation: -45, 
                        align: 'right'
                }
            },
            yAxis: {
                categories: psyYAxisCategories,
                title: {
                    text: 'Regions',
                    style: {
                        fontWeight: "600",
                        fontSize: '32px',
                        color: '#333333'
                    },
                },
                labels: {
                    style: {
                        fontSize: '20px',
                        color: '#333333'
                    }
                }
            },
            colorAxis: {
                min: 0,
                minColor: '#FF90C0', // Purple
                maxColor: '#4b2eee',  // Pink
                labels: {
                    style: {
                        fontSize: '24px', 
                        color: '#333333'
                    }
                }
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 40,
                symbolHeight: 350,
                itemStyle: {
                    fontSize: '28px',
                    color: '#333333'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                borderWidth: 1,
                data: psySeriesData,
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    style: {
                        fontSize: fontSize
                    }
                }
            }],
            tooltip: {
                formatter: function () {
                    return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> scored <br><b>' +
                        this.point.value + '</b> in <br><b>' +
                        this.series.yAxis.categories[this.point.y] + '</b>';
                }
            }
        });
    }
}
,



//  async downloadPDF() {
//     // var routeTestId = window.atob(this.$route.query.id);
//     var routeTestName = window.atob(this.$route.query.name);
//     var routeTo = window.atob(this.$route.query.to);
//     var routeFrom = window.atob(this.$route.query.from);
//     var getClientName = localStorage.getItem('activeAccount');

//     // if (routeTestName.length > 60) {
//     //     routeTestName = routeTestName.substring(0, 60) + '...';
//     // }

//     var displayDate = moment(String(routeFrom)).format("DD MMM YYYY") + " - " + moment(String(routeTo)).format("DD MMM YYYY");
//     const ids = ['test-contruct-table', 'token-usage-graph', 'overall-result-analysis-graph', 'heatmap-lang-card', 'heatmap-psy-card', 'score-analysis-benchmark-graph', 'attempt-analysis-table', 'line-chart-data', 'proctoringGraph'];
//     let pdfData = [];

//     for (let id of ids) {
//         const element = document.getElementById(id);
//         if (element) {
//             const canvas = await html2canvas(element, {
//                 scale: 2,
//                 width: element.offsetWidth,
//                 height: element.offsetHeight
//             });
//             const imgData = canvas.toDataURL('image/png');

//             pdfData.push({
//                 image: imgData,
//                 width: 500,
//                 margin: [0, 0, 0, 10]
//             });
//         }
//     }

//     const coverPage = [
//         {
//             image: Base64Images.ReportCoverPage,
//             width: 595.28, // Full width of A4 page
//             height: 841.89, // Full height of A4 page
//             margin: [0, 0, 0, 0], // Remove margin
//             absolutePosition: { x: 0, y: 0 } // Position at the top-left corner
//         },
//         {
//             text: 'Descriptive Analysis Report',
//             style: 'header',
//             absolutePosition: { x: 60, y: 160 }
//         },
//         // {
//         //     text: this.ClientName,
//         //     style: 'clientName',
//         //     absolutePosition: { x: 60, y: 210 }
//         // },
//         {
//             text: routeTestName,
//             style: 'testName',
//             absolutePosition: { x: 60, y: 210 }
//         },
//         // {
//         //     text: '['+displayDate+']',
//         //     style: 'dateHeader',
//         //     absolutePosition: { x: 60, y: 350 }
//         // },
//     ];

//     const endPage = [
//         {
//             text: 'Thank you for using PMaps!',
//             style: 'paragraph',
//         },
//         {
//             text: 'For more information, visit our website.',
//             style: 'paragraph'
//         },
//         {
//             text: 'Contact us at support@pmaps.in',
//             style: 'paragraph'
//         }
//     ];

//     const header = function (currentPage, pageCount) {
//         if (currentPage === 1 || currentPage === pageCount) return {};
//         return {
//             columns: [
//                 {
//                     text: `${getClientName}  [${displayDate}]`,
//                     alignment: 'left',
//                     fontSize: 10,
//                     color: '#333333'
//                 },
//                 {
//                     image: Base64Images.PMapsLogo2,
//                     width: 75,
//                     alignment: 'right'
//                 }
//             ],
//             margin: [40, 20]
//         };
//     };

//     const footer = function (currentPage, pageCount) {
//         if (currentPage === 1 || currentPage === pageCount) return {};
//         return {
//             columns: [
//                 {
//                     text: `Descriptive Analysis Report`,
//                     alignment: 'left',
//                     fontSize: 10,
//                     color: '#333333'
//                 },
//                 {
//                     text: (currentPage - 1).toString() + ' of ' + (pageCount - 2).toString(),
//                     alignment: 'right',
//                     fontSize: 10,
//                     color: '#333333'
//                 }
//             ],
//             margin: [40, 20]
//         };
//     };

//     const docDefinition = {
//         pageSize: 'A4',
//         pageMargins: [40, 60, 40, 60],
//         header: header,
//         footer: footer,
//         content: [
//             { 
//                 stack: coverPage, 
//                 pageBreak: 'after',
//                 pageMargins: [0, 0, 0, 0] 
//             },
//             ...pdfData,
//             { 
//                 stack: endPage, 
//                 pageBreak: 'before' 
//             }
//         ],
//         styles: {
//             header: {
//                 fontSize: 28,
//                 bold: true,
//                 color: '#7C68EE',
//                 margin: [0, 0],
//                 alignment: "center"
//             },
//             clientName: {
//                 fontSize: 20,
//                 color: '#333333', 
//                 margin: [0, 0]
//             },
//             testName:{
//                 fontSize: 16,
//                 color: '#333333', 
//                 margin: [0, 0] ,
//                 alignment: "center"

//             },
//             dateHeader: {
//                 fontSize: 16,
//                 color: '#333333', 
//                 margin: [0, 0]
//             },
//             paragraph: {
//                 fontSize: 14, 
//                 color: '#333333', 
//                 alignment: 'center',
//                 margin: [0, 0]
//             }
//         },
//         compress: true
//     };

//     pdfMake.createPdf(docDefinition).download('Descriptive_Analysis_Report.pdf', function() {
//         localStorage.removeItem('DescriptiveData');
//         window.close();
//     });

//     // this.$router.push({
//     //     name: 'DashboardTestWise',
//     //     query: {
//     //         id: this.encode(window.atob(this.$route.query.id)),
//     //         name: this.encode(window.atob(this.$route.query.name)),
//     //         to: this.encode(window.atob(this.$route.query.to)),
//     //         from: this.encode(window.atob(this.$route.query.from)),
//     //     },
//     // });
// }

async downloadPDF() {
    const routeTestName = window.atob(this.$route.query.name);
    const routeTo = window.atob(this.$route.query.to);
    const routeFrom = window.atob(this.$route.query.from);
    const getClientName = localStorage.getItem('activeAccount');
    const displayDate = moment(String(routeFrom)).format("DD MMM YYYY") + " - " + moment(String(routeTo)).format("DD MMM YYYY");
    const ids = ['test-contruct-table', 'token-usage-graph', 'overall-result-analysis-graph', 'heatmap-lang-card', 'heatmap-psy-card', 'score-analysis-benchmark-graph', 'attempt-analysis-table', 'line-chart-data', 'proctoringGraph'];

    await this.$nextTick(); 

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    await delay(1000); 

    const pdfData = await Promise.all(ids.map(async id => {
        const element = document.getElementById(id);
        if (element) {
            const canvas = await html2canvas(element, {
                scale: 1, 
                useCORS: true,
                logging: true 
            });
            const imgData = canvas.toDataURL('image/png', 0.3); 
            return {
                image: imgData,
                width: 500,
                margin: [0, 0, 0, 10]
            };
        }
        return null;
    }));

    const coverPage = [
        {
            image: Base64Images.ReportCoverPage,
            width: 595.28,
            height: 841.89,
            margin: [0, 0, 0, 0],
            absolutePosition: { x: 0, y: 0 }
        },
        {
            text: 'Descriptive Analysis Report',
            style: 'header',
            absolutePosition: { x: 60, y: 160 }
        },
        {
            text: routeTestName,
            style: 'testName',
            absolutePosition: { x: 60, y: 210 }
        }
    ];

    const endPage = [
        {
            text: 'Thank you for using PMaps!',
            style: 'paragraph',
        },
        {
            text: 'For more information, visit our website.',
            style: 'paragraph'
        },
        {
            text: 'Contact us at nkamble@pmaps.in',
            style: 'paragraph'
        }
    ];

    const header = function (currentPage, pageCount) {
        if (currentPage === 1 || currentPage === pageCount) return {};
        return {
            columns: [
                {
                    text: `${getClientName}  [${displayDate}]`,
                    alignment: 'left',
                    fontSize: 10,
                    color: '#333333'
                },
                {
                    image: Base64Images.PMapsLogo2,
                    width: 75,
                    alignment: 'right'
                }
            ],
            margin: [40, 20]
        };
    };

    const footer = function (currentPage, pageCount) {
        if (currentPage === 1 || currentPage === pageCount) return {};
        return {
            columns: [
                {
                    text: `Descriptive Analysis Report`,
                    alignment: 'left',
                    fontSize: 10,
                    color: '#333333'
                },
                {
                    text: (currentPage - 1).toString() + ' of ' + (pageCount - 2).toString(),
                    alignment: 'right',
                    fontSize: 10,
                    color: '#333333'
                }
            ],
            margin: [40, 20]
        };
    };

    const docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 60],
        header: header,
        footer: footer,
        content: [
            { 
                stack: coverPage, 
                pageBreak: 'after',
                pageMargins: [0, 0, 0, 0] 
            },
            ...pdfData.filter(data => data !== null),
            { 
                stack: endPage, 
                pageBreak: 'before' 
            }
        ],
        styles: {
            header: {
                fontSize: 28,
                bold: true,
                color: '#7C68EE',
                margin: [0, 0],
                alignment: "center"
            },
            clientName: {
                fontSize: 20,
                color: '#333333', 
                margin: [0, 0]
            },
            testName:{
                fontSize: 16,
                color: '#333333', 
                margin: [0, 0],
                alignment: "center"
            },
            dateHeader: {
                fontSize: 16,
                color: '#333333', 
                margin: [0, 0]
            },
            paragraph: {
                fontSize: 14, 
                color: '#333333', 
                alignment: 'center',
                margin: [0, 0]
            }
        },
        compress: true
    };

    pdfMake.createPdf(docDefinition).download('Descriptive_Analysis_Report.pdf', function() {
        localStorage.removeItem('DescriptiveData');
        window.close();
    });
}


,
        encode(item) {
            return window.btoa(item);
        },

    },
    // beforeUnmount() {
    //     this.resetState();
    // },
};

</script>

<style scoped>

body {
    overflow: hidden !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    text-align: center;
}

.loader-image {
    width: 50vw; 
    max-width: 400px; 
    height: auto; 
}

.loading-text {
    font-size: 2vw; 
    max-width: 80vw; 
    margin-top: 20px;
}

@media (max-width: 600px) {
    .loading-text {
        font-size: 3vw; 
    }
}


#test-contruct-table {
    width: 1500px;
    min-height: 900px;
    max-height: 2000px;
}

#token-usage-graph {
    width: 1500px;
    min-height: 700px;
    max-height: 2000px;
}

#proctoringGraph {
    width: 1500px;
    min-height: 600px;
    max-height: 2000px;
}

#line-chart-data {
    width: 1500px;
    min-height: 1500px;
    max-height: 4000px;
}

/* style="width: 100%; height: 400px;" */
#attempt-analysis-table {
    width: 1500px;
    min-height: 600px;
    max-height: 2500px;
}

#overall-result-analysis-graph {
    width: 1500px;
    min-height: 1500px;
    max-height: 2500px;
}

#score-analysis-benchmark-graph {
    width: 1500px;
    min-height: 2000px;
    max-height: 2500px;
}

#heatmap-lang-card {
    width: 1500px;
    min-height: 1500px;
    max-height: 2000px;
}


#heatmap-psy-card {
    width: 1500px;
    min-height: 1300px;
    max-height: 2000px;
}

.heatmap-chart {
    width: 1000px;
    height: 500px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
}

.graphSubHeading {
    font-size: 32px;
}

/* .no-scroll {
} */
</style>
