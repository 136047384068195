<template>
    <div class="page-content" style="position: relative;">
        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="my-2 d-flex align-items-center">
                <div class="top_heading_CreatePartner_btn d-block me-2">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <router-link :to="{
                                name: 'DashboardOverview',
                                query: {
                                    to: this.encode(this.toDate),
                                    from: this.encode(this.fromDate)
                                },
                            }" class="p-0" type="button">
                                <img src="../../../../public/dashboard-assets/images/M2/BackButton.png" />
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="px-2 pt-2">
                    <h4 class="mb-1 font-size-MV">
                        <span>
                            Descriptive Analytics
                        </span>
                        <span class="text-muted" style="font-size:medium;"> - [{{
                            this.displayDate }}] </span>
                    </h4>
                    <div>
                        <h6 class="text-muted" data-toggle="tooltip" :title="'TestId: ' + routetestId">
                            {{ this.routetestName }}
                        </h6>
                        <!-- <span class="text-muted"> - (TestId: {{
                                this.routetestId }})</span> -->
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-end">
                <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#choose-Date">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                <img src="../../../../public/dashboard-assets/images/M2/date-icon.png"
                                    @click="opencal" />
                                <input type="text" name="daterange" hidden="true" id="calendar_icon" class="fade" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="top_heading_CreatePartner_btn d-block mx-3" v-if="superadmin() && (this.testSummaries.length > 0 || this.scoreBenchmarkStatus || this.heatMapStatus || this.attemptAnalysisStatus || this.lineDataStatus || this.proctorDataStatus)">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0 Group_top_three_dot_dropdown" type="button" @click="DownloadReport()"
                                v-if="this.PropStatus">
                                <img src="../../../../public/dashboard-assets/images/M2/download-icon.png"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    title="Download Descriptive Analysis Report" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <span class="mx-2" v-if="this.testSummaries.length > 0 || this.heatMapStatus ||
                this.attemptAnalysisStatus || this.userAnalysisTableStatus || this.lineDataStatus || this.proctorDataStatus ||
                this.scoreBenchmarkStatus || this.assessmentFlows.length > 0">Quick Links</span>
            <div class="mx-1 mt-2">

                <span class="custom-label mx-2 mb-3" @click="navigateToId('test-construct')" v-if="this.testSummaries.length > 0 || this.heatMapStatus ||
                    this.attemptAnalysisStatus || this.userAnalysisTableStatus || this.lineDataStatus || this.proctorDataStatus ||
                    this.scoreBenchmarkStatus || this.assessmentFlows.length > 0">
                    Test Contruct
                </span>
                <span class="custom-label mx-2 mb-3" @click="navigateToId('token-usage')"
                    v-if="this.assessmentFlows.length > 0">
                    Tokens Usage
                </span>
                <span class="custom-label mx-2 mb-3" @click="navigateToId('overall-fitment-analysis')"
                    v-if="this.testSummaries.length > 0">
                    Overall Fitment Analysis
                </span>

                <!-- <span class="custom-label mx-2 mb-3" @click="navigateToId('region-wise-analysis')"
                    v-if="this.testSummaries.length > 0">
                    Region Wise Usage Analysis
                </span> -->

                <span class="custom-label mx-2 mb-3" @click="navigateToId('score-analysis')" v-if="heatMapStatus">
                    Score Analysis
                </span>
                <span class="custom-label mx-2 mb-3" @click="navigateToId('score-analysis-benchmark')"
                    v-if="scoreBenchmarkStatus">
                    Score Analysis (with Benchmark)
                </span>
                <!-- 
                <span class="custom-label mx-2 mb-3" @click="navigateToId('user-analysis')"
                    v-if="userAnalysisTableStatus">
                    User Analysis
                </span> -->

                <span class="custom-label mx-2 mb-3" @click="navigateToId('attempt-analysis')"
                    v-if="attemptAnalysisStatus">
                    Attempt Analysis
                </span>

                <span class="custom-label mx-2 mb-3" @click="navigateToId('time-analysis')" v-if="lineDataStatus">
                    Time Analysis
                </span>
                <span class="custom-label mx-2 mb-3" @click="navigateToId('proctoring-analysis')"
                    v-if="this.proctorDataStatus">
                    Proctoring Analysis
                </span>


            </div>
        </div>
        <div id="pdfContent">
            <TestConstruct id="test-construct" :assessmentData="testSection" />
            <div v-if="cardLoading" class="row">
                <div class="Overview_dashboard_map_section my-4">
                    <div class="card p-0 is-loading" style="width:100%; height: 400px !important;">
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="this.PropStatus">
                    <TestTokenUsageLineChart id="token-usage" :assessmentData="assessmentFlows"
                        :daysCount="NumberOfDays" />
                    <!-- <TestRegionWiseGraph id="region-wise-analysis" :assessmentData="testSummaries"
                    v-if="testSummaries.length > 0" /> -->
                    <StackedColumnChartFitment id="overall-fitment-analysis" :assessmentData="testSummaries"
                        v-if="testSummaries.length > 0" />
                    <HeatmapChart id="score-analysis" :assessmentData="scoreAnalysisData" v-if="heatMapStatus" />
                    <ScoreAnalysisRegionwise id="score-analysis-benchmark" :assessmentData="scoreBenchmark"
                        v-if="scoreBenchmarkStatus" />
                    <!-- <TableUserAnalysis id="user-analysis" :assessmentData="userAnalysisTable"
                    v-if="userAnalysisTableStatus" /> -->
                    <TableTestAttemptAnalysis id="attempt-analysis" :assessmentData="attemptTableData"
                        v-if="attemptAnalysisStatus" @update-completion-type="handleUpdateCompletionType" />

                    <LineChartTestWise id="time-analysis" :assessmentData="lineData" v-if="lineDataStatus"
                        @update-type="handleLineChartType" />

                    <StackedColumnChartProctoring id="proctoring-analysis" :assessmentData="proctorData"
                        v-if="this.proctorDataStatus" />

                </div>
                <!-- <img src="../../../../public/assets/Images/logo.png" alt="" /> -->
            </div>
        </div>
        <!-- <div>
    <div id="pdfContent">
      <div id="chart1" style="width: 500px; height: 300px; background-color: lightblue;">
        <h2>Chart 1</h2>
      </div>
      <div id="chart2" style="width: 500px; height: 300px; background-color: lightgreen;">
        <h2>Chart 2</h2>
      </div>
    </div>
  </div> -->
        <div class="fab-button" @click="scrollTop()"><i class="fa-solid fa-arrow-up" style="color: white;"></i></div>

    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { superadmin } from "../../../utils/helper";
import Highcharts from 'highcharts';
import HeatmapChart from './HeatmapChart.vue';
import StackedColumnChartProctoring from './StackedColumnChartProctoring.vue';
import LineChartTestWise from './LineChartTestWise.vue';
import TableTestAttemptAnalysis from './TableTestAttemptAnalysis.vue';
import TestConstruct from './TestConstruct.vue';
// import TableUserAnalysis from './TableUserAnalysis.vue';
import StackedColumnChartFitment from './StackedColumnChartFitment.vue';
import ScoreAnalysisRegionwise from './ScoreAnalysisRegionwise.vue';
// import TestRegionWiseGraph from './TestRegionWiseGraph';
import TestTokenUsageLineChart from './TestTokenUsageLineChart';
// import eventBus from '../../../store/eventBus';
import store from "../../../store";
import Base64Images from "../../../utils/base64Image.js";
import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
// import eventBus from "../../../store/eventBus.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default {
    name: "TestWise",
    components: {
        HeatmapChart,
        StackedColumnChartProctoring,
        LineChartTestWise,
        TableTestAttemptAnalysis,
        StackedColumnChartFitment,
        ScoreAnalysisRegionwise,
        // TestRegionWiseGraph,
        TestTokenUsageLineChart,
        TestConstruct
        // TableUserAnalysis
    },
    data() {
        return {
            fromDate: '',
            toDate: '',
            displayDate: '',
            lastLoggedInDate: "",
            pageNumber: 1,
            testSummaries: [],
            reportUrl: '',
            counter: 0,
            assessmentFlows: [],
            isParent: 'false',
            hidePdfDownload: false,
            routetestId: 0,
            routetestName: '',
            PropStatus: false,
            scoreAnalysisData: [],
            attemptTableData: [],
            lineData: [],
            proctorData: [],
            scoreBenchmark: [],
            testSection: [],
            heatMapStatus: false,
            attemptAnalysisStatus: false,
            lineDataStatus: false,
            proctorDataStatus: false,
            scoreBenchmarkStatus: false,
            userAnalysisTable: [],
            userAnalysisTableStatus: false,
            NumberOfDays: 0,
            selectedCompletionType:'avgCompletion',
            selectedLineChartType:'By Sections',
            // exportTestSummaries:{}
            // routeclientId:0
        };
    },
    async created() {
        window.scrollTo(0, 0);



        if (this.$route.query.to == undefined || this.$route.query.from == undefined) {
            this.$router.push({
                name: "DashboardGroup"
            });
            return;
        }

        var routeTo = window.atob(this.$route.query.to);
        var routeFrom = window.atob(this.$route.query.from);
        this.routetestId   = window.atob(this.$route.query.id);
        this.routetestName = window.atob(this.$route.query.name);

        if (routeTo != undefined && routeFrom != undefined) {
            this.fromDate = await routeFrom;
            this.toDate = await routeTo;
        }

        this.displayDate = moment(String(this.fromDate)).format("YYYY MMM DD") + " - " + moment(String(this.toDate)).format("YYYY MMM DD");

        this.TestConstructData();
        await this.GraphDataResponse();

        // this.routeclientId = window.atob(this.$route.query.clientId);
    },

    computed: {
        ...mapState({
            authenticated: (state) => state.auth.authenticate,
            user: (state) => state.auth.user,
            Date: (state) => state.auth.user.lastLoggedInDate,
            cardLoading: (state) => state.loader.cardLoading,

        }),
    },
    methods: {
        ...mapActions([
            "AssessmentFlowDesignSummary",
            "activetestsection",
            "GetTestClientUserAnalysis",
            "ShowWarningMessage",
            "GetScoreAnalysisHeatmapData",
            "GetTestAttemptTableData",
            "GetTestTimeAnalysisLineData",
            "GetProctoringAnalysisStackData",
            "GetScoreAnalysisData",

            //Vuex Set for PDF Start
            // "setHeatmapData",
            // "setTestConstruct",
            // "setTokenUsageData",
            // "setTokenUsageDaysData",
            // "setOverallResultAnalysisData",
            // "setScoreBenchmarkData",
            // "setProctorData",
            // "setAttemptAnalysis",
            // "setLineData",
            //Vuex Set for PDF End
            
            'getHeatmapData', 'getTestConstruct', 'getTokenUsageData', 'getTokenUsageDaysData', 'getOverallResultAnalysisData', 'getScoreBenchmarkData', 'getProctorData', 'getAttemptAnalysis', 'getLineData'
        ]),
        superadmin,
        dateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 00:00:00");
        },
        todateformat: (val) => {
            return moment(String(val)).format("YYYY-MM-DD 23:59:59");
        },
        hideWelcome() {
            window.$("div").removeClass("modal-backdrop");
            window.$("#WelcomePopupModal").modal("hide")
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
        handleUpdateCompletionType(newCompletionType) {
            this.selectedCompletionType = newCompletionType;
        },
        handleLineChartType(chartType){
            this.selectedLineChartType = chartType;
        },
        navigateToId(id) {
            const element = document.getElementById(id);
            if (element) {
                const offsetTop = element.offsetTop - 100;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }
        },
        // generatePDF() {
        //     const chartContainer = document.getElementById('pdfContent');
        //     const element = document.createElement('div');

        //     const chartSpans = chartContainer.querySelectorAll('span');

        //     chartSpans.forEach((chartSpan) => {
        //         const sectionWrapper = document.createElement('div');
        //         sectionWrapper.classList.add('pdf-section');
        //         sectionWrapper.appendChild(chartSpan.cloneNode(true));
        //         element.appendChild(sectionWrapper);
        //         sectionWrapper.style.pageBreakAfter = 'always';
        //     });

        //     const options = {
        //         filename: 'your_file_name.pdf',
        //         image: { type: 'svg', quality: 1 },
        //         html2canvas: { scale: 2 },
        //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        //     };

        //     html2pdf().set(options).from(element).save();
        // },
        //const ids = ['score-analysis-chart', 'attempt-analysis-chart', 'time-analysis-chart','proctoring-analysis-chart']; 

        //pdfContent

        async downloadPDF() {
            const ids = ['test-construct-chart', 'pdfLineChart', 'pdfRegionWise', 'pdfStackColumn', 'score-analysis-chart', 'pdfScoreAnalysisBenchmark', 'attempt-analysis-chart', 'time-analysis-chart', 'proctoring-analysis-chart'];
            let pdfData = [];

            for (let id of ids) {
                const element = document.getElementById(id);
                if (element) {
                    const canvas = await html2canvas(element, { scale: 2 });
                    const imgData = canvas.toDataURL('image/png');

                    pdfData.push({
                        image: imgData,
                        width: 500, // Adjust to fit your PDF layout
                        margin: [0, 0, 0, 10] // Margin between images
                    });
                }
            }

            const coverPage = [
                {
                    image: Base64Images.Footer,
                    width: 500,
                    margin: [0, 20, 0, 20]
                },
                {
                    text: 'PMaps Assessment Report',
                    style: 'header'
                },
                {
                    text: 'Subtitle of the report',
                    style: 'subheader'
                },
                {
                    text: 'This is an introductory paragraph for the cover page.',
                    style: 'paragraph'
                },
                {
                    image: Base64Images.Footer,
                    width: 500,
                    margin: [0, 20, 0, 0]
                }
            ];

            const endPage = [
                {
                    text: 'Thank you for using PMaps!',
                    style: 'header',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: 'For more information, visit our website.',
                    style: 'paragraph'
                },
                {
                    text: 'Contact us at support@pmaps.com',
                    style: 'paragraph'
                }
            ];

            const header = function (currentPage, pageCount) {
                // Do not display header on the cover and end pages
                if (currentPage === 1 || currentPage === pageCount) return {};
                return {
                    columns: [
                        {
                            text: 'PMaps Assessment',
                            alignment: 'left',
                            fontSize: 12,
                            color: '#000000'
                        },
                        {
                            image: Base64Images.PMapsLogo2,
                            width: 75,
                            alignment: 'right'
                        }
                    ],
                    margin: [40, 20]
                };
            };

            const footer = function (currentPage, pageCount) {
                // Do not display footer on the cover and end pages
                if (currentPage === 1 || currentPage === pageCount) return {};
                return {
                    columns: [
                        {
                            text: 'Usage Analysis Report',
                            alignment: 'left',
                            fontSize: 12,
                            color: '#000000'
                        },
                        {
                            text: (currentPage - 1).toString() + ' of ' + (pageCount - 2).toString(), // Adjusted page number and count
                            alignment: 'right',
                            fontSize: 12,
                            color: '#000000'
                        }
                    ],
                    margin: [40, 10]
                };
            };

            const docDefinition = {
                pageSize: 'A4',
                pageMargins: [40, 60, 40, 60],
                header: header,
                footer: footer,
                content: [
                    { stack: coverPage, pageBreak: 'after' },
                    ...pdfData,
                    { stack: endPage, pageBreak: 'before' }
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 20]
                    },
                    subheader: {
                        fontSize: 15,
                        alignment: 'center',
                        margin: [0, 10]
                    },
                    paragraph: {
                        fontSize: 12,
                        alignment: 'center',
                        margin: [0, 5]
                    }
                },
                compress: true
            };

            pdfMake.createPdf(docDefinition).download('report.pdf');
        }
        ,

        opencal() {
            document.getElementById("calendar_icon").click();
            // this.showw = true;
        },
        encode(item) {
            return window.btoa(item);
        },
        async DownloadReport() {

            var setTestConstruct = null;
            var setTokenUsageData = null;
            var setTokenUsageDaysData = null;
            var setOverallResultAnalysisData = null;
            var setScoreBenchmarkData = null;
            var setHeatmapData = null;
            var setAttemptAnalysis = null;
            var setLineData = null;
            var setProctorData = null;



            // this.setTestConstruct(this.testSection);
            // this.setTokenUsageData(this.assessmentFlows);
            // this.setTokenUsageDaysData(this.NumberOfDays);

            setTestConstruct = this.testSection;
            setTokenUsageData = this.assessmentFlows;
            setTokenUsageDaysData = this.NumberOfDays;


            if (this.testSummaries.length > 0) {
                setOverallResultAnalysisData = this.testSummaries;
                // this.setOverallResultAnalysisData(this.testSummaries);
            }
            if (this.scoreBenchmarkStatus) {
                setScoreBenchmarkData = this.scoreBenchmark;
                // this.setScoreBenchmarkData(this.scoreBenchmark);
            }
            if (this.heatMapStatus) {
                setHeatmapData = this.scoreAnalysisData;
                // this.setHeatmapData(this.scoreAnalysisData);
            }
            if (this.attemptAnalysisStatus) {
                setAttemptAnalysis = this.attemptTableData;
                // this.setAttemptAnalysis(this.attemptTableData);
            }
            if (this.lineDataStatus) {
                setLineData = this.lineData;
                // this.setLineData(this.lineData);
            }
            if (this.proctorDataStatus) {
                setProctorData = this.proctorData;
                // this.setProctorData(this.proctorData);
            }

            localStorage.setItem('DescriptiveData', JSON.stringify({
                testConstruct: setTestConstruct,
                tokenUsage: setTokenUsageData,
                tokenUsageDays: setTokenUsageDaysData,
                overallResultAnalysis: setOverallResultAnalysisData,
                scoreBenchmark: setScoreBenchmarkData,
                setHeatmap: setHeatmapData,
                attemptAnalysis: setAttemptAnalysis,
                lineData: setLineData,
                proctorData: setProctorData,
            }));

            const url = this.$router.resolve({
                name: 'TestWisePDF',
                query: {
                    to: this.encode(this.toDate),
                    from: this.encode(this.fromDate),
                    id: this.encode(this.routetestId),
                    name: this.encode(this.routetestName),
                    attemptStatus: this.encode(this.selectedCompletionType),
                    lineStatus: this.encode(this.selectedLineChartType)
                }
            }).href;

            window.open(url, '_blank');
        }
        ,
        async GraphDataResponse() {
            this.PropStatus = false;
            store.state.loader.cardLoading = true;

            this.ScoreAnalysisData();
            this.AttemptAnalysisTable();
            this.TimeAnalysisLine();
            this.ProctoringStackGraph();
            this.ScoreAnalysisBenchmark();
            //  this.TestClientUserAnalysisTablse();
            await this.GraphData();

            store.state.loader.cardLoading = false;
            this.PropStatus = true;
        },

        async TestConstructData() {

            var testsection = await this.activetestsection({
                testId: this.routetestId,
            });
            this.testSection = testsection.object.data;
        },
        async GraphData() {
            // this is a common method to be called from the daterange filter and also from the mounted lifecycle hook
            var graphData = await this.AssessmentFlowDesignSummary({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            const startDate = new Date(this.fromDate);
            const endDate = new Date(this.toDate);
            const timeDifference = endDate - startDate;
            const daysDifference = timeDifference / (1000 * 3600 * 24);

            this.NumberOfDays = Math.round(daysDifference);
            this.testSummaries = graphData.object.data.testUseSummaries;
            this.assessmentFlows = graphData.object.data.assessmentFlows;
        },

        async ScoreAnalysisData() {
            const data = await this.GetScoreAnalysisHeatmapData({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            this.scoreAnalysisData = data.object.data;
            this.heatMapStatus = this.scoreAnalysisData.languageCompetency.length > 0 || this.scoreAnalysisData.psyCompetency.length > 0 || this.scoreAnalysisData.sectionCompetency.length > 0
        },

        async AttemptAnalysisTable() {
            var data = await this.GetTestAttemptTableData({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
                // FromDate: '2024-03-01',
                // ToDate: '2024-03-31',
            });
            this.attemptTableData = data.object.data;
            this.attemptAnalysisStatus = this.attemptTableData.tests.length > 0;

        },

        async TimeAnalysisLine() {
            const lineData = await this.GetTestTimeAnalysisLineData({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            this.lineData = lineData.object.data;
            this.lineDataStatus = lineData.object.data.tests.length > 0;
        },


        async ProctoringStackGraph() {
            const proctorData = await this.GetProctoringAnalysisStackData({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            this.proctorData = proctorData.object.data;

            this.proctorDataStatus = this.proctorData.tests.some(
                (test) =>
                    test.regions.some((region) =>
                        region.sections.some((section) => section.proctoringCount > 0)
                    )
            );
        },

        async ScoreAnalysisBenchmark() {
            var score = await this.GetScoreAnalysisData({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            this.scoreBenchmark = score.object.data;
            this.scoreBenchmarkStatus = score.object.data.testList.length > 0;
        },

        async TestClientUserAnalysisTable() {

            var data = await this.GetTestClientUserAnalysis({
                clientId: this.user.clientId,
                TestId: this.routetestId,
                // this.routetestId
                RegionId: 0,
                FromDate: this.fromDate,
                ToDate: this.toDate
            });
            this.userAnalysisTable = data.object.data;
            this.userAnalysisTableStatus = this.userAnalysisTable.inviteUseSumarries.length > 0;
        },


        showOrHidePdfDownload(value) {
            this.hidePdfDownload = value;
        }
    },
    async mounted() {
        this.isParent = localStorage.getItem('isParent');
        // this.fromDate = '2024-04-01 00:00:00', 

        window.$('input[name="daterange"]').daterangepicker(
            {
                maxDate: new Date(),
                locale: { cancelLabel: "Clear" },
                opens: "center",
            },
            async (start, end) => {
                if (start && end) {
                    var x = start.format("YYYY MMM DD");
                    var y = end.format("YYYY MMM DD");
                    this.fromDate = this.dateformat(x);
                    this.toDate = this.todateformat(y);
                    // this.showw = false;
                }
            }
        );
        window
            .$('input[name="daterange"]')
            .on("cancel.daterangepicker", async (ev, picker) => {
                picker.startDate._d = new Date();
                picker.endDate._d = new Date();
                this.fromDate = this.dateformat(
                    new Date(new Date().setMonth(new Date().getMonth() - 1))
                ),
                    this.toDate = this.todateformat(new Date(new Date())),
                    window.$('input[name="daterange"]').val("");
                var x = moment(String(this.fromDate)).format("DD MMM YYYY");
                var y = moment(String(this.toDate)).format("DD MMM YYYY");
                this.displayDate = x + " - " + y;
                await this.GraphDataResponse();
            });
        window
            .$('input[name="daterange"]')
            .on("apply.daterangepicker", async (ev, picker) => {
                var x = moment(String(picker.startDate._d)).format("YYYY MMM DD");
                var y = moment(String(picker.endDate._d)).format("YYYY MMM DD");
                var start = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00")
                var end = moment(String(picker.endDate._d)).format("YYYY-MM-DD 00:00:00")
                if (
                    Math.ceil(
                        Math.abs(new Date(start) - new Date(end)) /
                        (1000 * 60 * 60 * 24)
                    ) <= 30
                ) {
                    this.displayDate = x + " - " + y;
                    var encodeObject = {
                        ClientId: this.user.clientId,
                        TestId: 0,
                        RegionId: 0,
                        From: this.fromDate,
                        To: this.toDate
                    }
                    var base64EncodedString = btoa(JSON.stringify(encodeObject));
                    this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;
                    await this.GraphDataResponse();
                    this.counter++;

                } else {
                    return this.ShowWarningMessage("Please select range within 1 months");
                }
            });

        // await this.GraphData();

        var encodeObject = {
            ClientId: this.user.clientId,
            TestId: 0,
            RegionId: 0,
            From: this.fromDate,
            To: this.toDate
        }
        var base64EncodedString = btoa(JSON.stringify(encodeObject));
        this.reportUrl = window.app_base_url + "/descriptiveDashboard/DownloadDashboard?token=" + base64EncodedString;

        //highchartsExporting(Highcharts);
        Highcharts.setOptions({
            exporting: {
                enabled: false,
                // chartOptions: {
                //     print: {
                //         dpi: 300
                //     },
                //     exportFormat: 'svg',
                //     style: {
                //         fontFamily: 'DM Sans, sans-serif'
                //     },
                //     exporting: {
                //         enabled: false,

                //     }
                // }
            },
            chart: {
                style: {
                    fontFamily: 'DM Sans, sans-serif'
                }
            }
        });
    },
};
</script>
<style scoped>
#pdfContent>div {
    margin-bottom: 20px;
}

.custom-label {
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid #aaaaaa;
    border-radius: 25px;
    font-size: 15px;
    color: #333333;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.custom-label:hover {
    transform: translateY(-5px);
    border: 2px solid #7C68EE;
    background-color: #7C68EE;
    color: #ffffff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.section.active {
    flex-grow: 1;
    padding: 25px;
    margin-top: 60px;
}

.fab-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    background-color: #7C68EE;
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
}

.fab-button:hover {
    background-color: #3700b3;
}

.card.is-loading {

    background: #E8E4FF;
    background: linear-gradient(110deg, #ece8fa 8%, #f5f5f5 18%, #ece8fa 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
</style>