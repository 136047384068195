<template>
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="my-2 d-flex align-items-center">
                <div class="top_heading_CreatePartner_btn d-block me-2">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <router-link :to="{
                                name: 'ActiveTestViewCandidatesNew',
                                params: {
                                    testId: encode(this.routeTestId),
                                    clientId: encode(this.routeClientId),
                                    isParent: encode(this.routeIsParent),
                                },
                                query: {
                                    search: encode(this.routeSearch),
                                    offset: encode(this.routeOffset),
                                    current: encode(this.routeCurrent),
                                },
                            }" class="p-0" type="button">
                                <img src="../../../../public/dashboard-assets/images/M2/BackButton.png" />
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="px-2 pt-2">
                    <h4 class="mb-1 font-size-MV">
                        <span>
                            Invite Logs
                        </span>
                        <span class="text-muted" style="font-size:medium;"> - [{{
                            this.displayDate }}] </span>
                    </h4>
                    <div>
                        <h6 class="text-muted mt-1" data-toggle="tooltip" :title="'TestId: ' + routeTestId">
                            {{ this.routeTestName }}
                        </h6>
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-between align-items-end mt-2">

                <div class="mx-3">
                    <button class="secondary-button1" type="button" @click="this.GetInviteLog()">
                        <i class="fa-solid fa-arrows-rotate"></i> Refresh
                    </button>

                </div>
            </div>
        </div>
        <div class="mt-4 mx-1 card">
            <div class="table-responsive mb-4" style="width: 100%; min-height: 20px; max-height: 500px;">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Invite Id</th>
                            <th>Candidate Details</th>
                            <th>Invite Type</th>
                            <th>Invite Category</th>
                            <th>Invite At</th>
                            <th>Invite By</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in this.inviteLog" :key="item">
                            <td :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">{{ item.inviteId }} </td>
                            <td
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ item.contactDetails }}</td>
                            <td
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ item.communicationType }}</td>
                            <td
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ item.status }}
                            </td>
                            <td
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ dateConvert(item.invitedInitiateDate) }}</td>
                            <td
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ item.fromEmail }}
                            </td>
                            <td v-if="item.remark != null"
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                {{ item.remark }}</td>
                            <td v-else
                                :style="{ backgroundColor: item.remark === 'Cooling period is active.' ? '#ffdfdf' : ( item.isProcessed || item.candidateId != null ? '' : '#ffdfdf') }">
                                <div class="demo-preview" style="cursor: default;">
                                    <div class="badge mb-2" style=" color:#FF9900; border: #FF9900 solid 2px;">
                                        Pending
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            routeTestId: window.atob(this.$route.query.testId),
            routeClientId: window.atob(this.$route.query.clientId),
            routeIsParent: window.atob(this.$route.query.isParent),
            routeSearch: window.atob(this.$route.query.search),
            routeOffset: window.atob(this.$route.query.offset),
            routeCurrent: window.atob(this.$route.query.current),
            routeTestName: window.atob(this.$route.query.name),
            inviteLog: [],
            displayDate: ''
        }
    },
    async created() {
        await this.GetInviteLog();
    },

    methods: {
        ...mapActions([
            "GetTestwiseInviteLogs"
        ]),
        encode(item) {
            return window.btoa(item);
        },
        dateConvert: (value) => {
            return moment(String(value)).format("DD/MM/YYYY HH:mm A");
        },
        headerDateConvert: (value) => {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        async GetInviteLog() {
            var date = new Date();
            var endDate = moment(String(date)).add(1, 'days').format("MM/DD/YYYY");
            var startDate = moment(endDate, "MM/DD/YYYY").subtract(6, 'months').format("MM/DD/YYYY");

            this.displayDate = `${this.headerDateConvert(startDate)} - ${this.headerDateConvert(endDate)} `;

            var data = await this.GetTestwiseInviteLogs({
                testId: this.routeTestId,
                StartDate: startDate,
                EndDate: endDate,
            });
            this.inviteLog = data.object.data;
        },
    },
}
</script>

<style scoped>
.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
}

.table-header {
    text-align: center;
    margin-bottom: 10px;
}

.table-title {
    font-size: 18px;
    font-weight: bold;
}

.badge {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 10px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #4d4d4d;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

}

.demo-preview .badge {
    margin-right: 10px;
}

.secondary-button1 {
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    font-weight: 600;
    border-radius: 21px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
}
</style>