import router from "../router";

export function calculateRemainingDays(expiryDate) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let expiry = new Date(expiryDate);
    expiry.setHours(0, 0, 0, 0);
    let diff = expiry.getTime() - currentDate.getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
}

export function validateWhenExpiryDateIsNull(userData) {
    if (userData != null) {
        let days = calculateRemainingDays(userData);
        if (days <= 0) {
            router.push("/settings/plans");
        }
    }
}
